import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import UserSearchViewDetails from "../approver-accounts-user-details/popups/user-search-view-details";
import UserSiteViewDetails from "../approver-accounts-user-details/popups/user-site-view-details";
import {
  displayAccounts,
  displayUserDetails,
  approverAccountsSiteInit,
  getApproverUserCountInit,
  userSearchInit,
  userSearchReset,
  accountsDataInit,
  accountsDataReset,
} from "../../../store/actions";
import "./approver-accounts-section.css";
import AddAccount from "../../../assets/add_account.png";
import UserProfileHighlighted from "../../../assets/user_profile_highlighted.png";
import UserProfile from "../../../assets/user_profile.png";
import SiteLocation from "../../../assets/site_location.png";
import SiteLocationHighlighted from "../../../assets/site_location_highlighted.png";
import EditIcon from "../../../assets/edit.png";
import DeleteIcon from "../../../assets/delete.png";

import {
  initProps,
  handleUserSearch,
  handleSiteSearch,
  handleShowSiteStyle,
  handleShowUserStyle,
  handleNewAccount,
  searchUser,
  searchSite,
  currentDate,
  monthNames,
  getNth,
  labelStyle,
  handleSiteDetails,
  changeNoUser,
  changeSearchResultShow,
  setShowUserDetails,
  setShowModalEdit,
  setShowModalDelete,
  setIsSearch,
  setShowSiteDetails,
  setSiteModalEdit,
  setSiteModalDelete,
} from "./approver-accounts-section-util";
const ApproverAccountSection = (props) => {
  initProps(props);
  /*************States*************/
  const { dashBoardCount, dashboardLoading } = props.dashboardCount;
  const {
    showSite,
    searchResultsSite,
    showSiteStyle,
    showSiteDetails,
    siteModalEdit,
    siteSearchString,
    siteModalDelete,
  } = props.approverAccountsData.siteState;

  const {
    noUser,
    showUserDetails,
    userSearchString,
    modalEdit,
    modalDelete,
  } = props.approverAccountsData.userState;
  const {
    showSearchResult,
    isSearch,
    showMessage,
    searchStringLength,
    selectedItem,
  } = props.approverAccountsData.commonState;

  const { siteList, userSearchList, userSearchError, loadingSearch } =
    props.approverAccount || {};

  const { languageSet } = props.localization;
  /****************************************/
  useEffect(() => {
    props.requestUserCount();
    props.requestApproverAccountSite();

    return () => {
      props.requestAccountsDataReset();
    };
  }, []);

  useEffect(() => {
    if (userSearchList) {
      changeNoUser(false);
      if (!(searchStringLength < 3)) {
        changeSearchResultShow(true);
      }
    }
    if (userSearchError) {
      if (!(searchStringLength < 3)) changeNoUser(true);
    }
  }, [userSearchList, userSearchError]);

  const searchCall = (data) => {
    props.requestUserSearch(data);
  };

  const [searchSiteText, setSearchSiteText] = useState("");
  const searchCallSite = (data) => {
    props.requestApproverAccountSite();
    setSearchSiteText(data);
  };

  useEffect(() => {
    searchSite(searchSiteText);
  }, [siteList]);

  return (
    <div
      className={
        dashboardLoading
          ? "parent-accounts-container container-opacity"
          : "parent-accounts-container"
      }
    >
      {dashboardLoading && (
        <div className={"load-wrap approver"}>
          <div className="load">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="accounts-header">
        <div className="left-header">
          <label>ACCOUNTS</label>
        </div>
        <div className="button-container">
          <button
            type="button"
            className="btn btn-amber custom-style new-account-style shadow-none"
            onClick={handleNewAccount}
          >
            <div className="new-acnt-style">
              <img className="acnt-img-style" src={AddAccount} />
              {languageSet.create_new_account}
            </div>
          </button>
        </div>
      </div>
      <div
        className={
          isSearch ? "tab-container search-item shadow" : "tab-container shadow"
        }
      >
        <div className="tab-holder-style">
          <div
            className="btn-group"
            role="group"
            aria-label="Button group with nested dropdown"
          >
            <div>
              <button
                type="button"
                className={
                  !showSiteStyle
                    ? "btn btn-primary custom-button-style user-style shadow-none"
                    : "btn btn-primary custom-button-style user-style user-click shadow-none"
                }
                onClickCapture={handleShowUserStyle}
                onClick={!showSiteStyle ? null : handleUserSearch}
              >
                <div className="user-profile-style">
                  <img
                    className="user-img-style"
                    src={showSiteStyle ? UserProfile : UserProfileHighlighted}
                  />
                  User
                </div>
              </button>
            </div>
            <div>
              <button
                type="button"
                className={
                  showSiteStyle
                    ? "btn btn-primary custom-button-style site-style shadow-none"
                    : "btn btn-primary custom-button-style site-style site-click shadow-none"
                }
                onClickCapture={handleShowSiteStyle}
                onClick={showSiteStyle ? null : handleSiteSearch}
              >
                <div className="site-location-style">
                  <img
                    className="location-img-style"
                    src={
                      !showSiteStyle ? SiteLocation : SiteLocationHighlighted
                    }
                  />
                  Site
                </div>
              </button>
            </div>
          </div>
        </div>
        {showSite && (
          <div className="search-container-style">
            <div className="row">
              <div className="col">
                <div className="md-form mt-0">
                  <input
                    className="form-control input-custom custom-style admin-dashboard shadow"
                    placeholder="Search for sites"
                    onFocus={() => {
                      setIsSearch(true);
                    }}
                    onChange={searchSite}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {!showSite && (
          <div className="search-container-style">
            <div className="row">
              <div className="col">
                <div className="md-form mt-0">
                  <input
                    className="form-control input-custom custom-style admin-dashboard shadow"
                    placeholder="Search for user accounts"
                    onFocus={() => {
                      setIsSearch(true);
                    }}
                    onChange={searchUser}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {showMessage && (
          <div className="result-container-style">
            <label>Please enter 3 characters to search</label>
          </div>
        )}
        {loadingSearch && (
          <div
            className="spinner-border text-primary loader-position"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {noUser && !showMessage && !loadingSearch && (
          <div className="result-container-style">
            <label>No Data</label>
          </div>
        )}
        {!loadingSearch && !noUser && showSearchResult && (
          <div className="result-container-style list shadow">
            {userSearchList.map((item, index) => (
              <div
                key={index}
                className="search-result-container clickable-row"
                onClick={() => {
                  setShowUserDetails(true, item);
                }}
              >
                <div className="search-result-user-name">
                  <label>{item.username}</label>
                </div>
                <div className="search-result-icons-container">
                  <img
                    className="edit-image-style"
                    src={EditIcon}
                    onClick={(e) => {
                      setShowModalEdit(true, item);
                      if (e.stopPropagation) e.stopPropagation();
                    }}
                  />
                  <img
                    className="delete-image-style"
                    src={DeleteIcon}
                    onClick={(e) => {
                      setShowModalDelete(true, item);

                      if (e.stopPropagation) e.stopPropagation();
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {!noUser && searchResultsSite && (
          <div className="result-container-style list shadow">
            {searchResultsSite.map((item, index) => (
              <div
                key={index}
                className="search-result-container clickable-row"
                onClick={() => handleSiteDetails(item)}
              >
                <div className="search-result-user-name">
                  <label>{item.label}</label>
                </div>
                <div className="search-result-icons-container">
                  <img
                    className="edit-image-style"
                    src={EditIcon}
                    onClick={(e) => {
                      setSiteModalEdit(true, item);
                      if (e.stopPropagation) e.stopPropagation();
                    }}
                  />
                  <img
                    className="delete-image-style"
                    src={DeleteIcon}
                    onClick={(e) => {
                      setSiteModalDelete(true, item);
                      if (e.stopPropagation) e.stopPropagation();
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {!isSearch && (
        <div className="count-parent-container">
          <div className="count-container">
            <label className="site-count">
              Sites{" "}
              <label style={labelStyle}>
                {dashBoardCount && dashBoardCount.site_count}
              </label>
            </label>
            <label className="app-count">
              {dashBoardCount && dashBoardCount.approver_count === 1
                ? languageSet.approver
                : languageSet.approvers}
              <label style={labelStyle}>
                {dashBoardCount && dashBoardCount.approver_count}
              </label>
            </label>
            <label className="admin-count">
              {dashBoardCount && dashBoardCount.admin_count === 1
                ? languageSet.admin
                : languageSet.admins}
              <label style={labelStyle}>
                {dashBoardCount && dashBoardCount.admin_count}
              </label>
            </label>
            <label className="fueler-count">
              {dashBoardCount && dashBoardCount.fueler_count === 1
                ? languageSet.fueler
                : languageSet.fuelers}
              <label style={labelStyle}>
                {dashBoardCount && dashBoardCount.fueler_count}
              </label>
            </label>
            <label className="icsc-count">
              ICSC
              <label style={labelStyle}>
                {dashBoardCount && dashBoardCount.ICSC_count}
              </label>
            </label>
          </div>
        </div>
      )}
      {!isSearch && (
        <div className="date-parent-container">
          <div className="date-inner-container">
            <label>
              Last Updated: {currentDate.getDate()}
              {getNth()} {monthNames[currentDate.getMonth()]}'{" "}
              {currentDate
                .getYear()
                .toString()
                .substring(1, 3)}
            </label>
          </div>
        </div>
      )}

      <UserSearchViewDetails
        showUserDetails={showUserDetails}
        setShowUserDetails={setShowUserDetails}
        item={selectedItem}
        siteList={siteList}
        searchCall={searchCall}
        userSearchString={userSearchString}
        modalEdit={modalEdit}
        showModalEdit={setShowModalEdit}
        modalDelete={modalDelete}
        showModalDelete={setShowModalDelete}
      />
      <UserSiteViewDetails
        showSiteDetails={showSiteDetails}
        setShowSiteDetails={setShowSiteDetails}
        setSiteModalEdit={setSiteModalEdit}
        siteModalEdit={siteModalEdit}
        siteModalDelete={siteModalDelete}
        setSiteModalDelete={setSiteModalDelete}
        searchCallSite={searchCallSite}
        siteSearchString={siteSearchString}
        item={selectedItem}
      />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    dashboardCount: state.dashboardCount,
    approverAccount: state.approverAccount,
    approverAccountsData: state.approverAccountsData,
    localization: state.localization,
  };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestApproverAcntsPage: (approverAcnts) =>
      dispatch(displayAccounts(approverAcnts)),
    requestNewAccountSetup: () => dispatch(displayUserDetails()),
    requestApproverAccountSite: (req) =>
      dispatch(approverAccountsSiteInit(req)),
    requestUserCount: () => dispatch(getApproverUserCountInit()),
    requestUserSearch: (req) => dispatch(userSearchInit(req)),
    requestUserSearchReset: (req) => dispatch(userSearchReset(req)),
    requestAccountsDataInit: (req) => dispatch(accountsDataInit(req)),
    requestAccountsDataReset: (req) => dispatch(accountsDataReset(req)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverAccountSection));
