import React, { useEffect } from "react";
import "./user-account-delete-dialog.css";
import { Button, Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import {
  approverUserDeleteInit,
  approverUserDeleteReset,
} from "../../../../store/actions";

const UserAccountDeleteDialog = (props) => {
  const {
    modalDelete,
    showModalDelete,
    item,
    searchCall,
    userSearchString,
  } = props;
  const { deleteUserSuccess, deleteUserError, loadingDeleteUser } =
    props.approverAccount || {};
  const toggle = () => showModalDelete(!modalDelete);
  useEffect(() => {
    if (deleteUserSuccess) {
      props.userDeleteReset();
      searchCall(userSearchString);
      showModalDelete(false);
    }
    if (deleteUserSuccess) {
      props.userDeleteReset();
    }
  }, [deleteUserSuccess, deleteUserError]);

  const handleDelete = () => {
    var req = { userid: item.userid };
    props.userDelete(req);
  };
  return (
    <div>
      <Modal isOpen={modalDelete} toggle={toggle} id="my-delete-modal">
        <ModalBody>
          <br />
          <div className="delete-header">
            <label>Delete Account</label>
          </div>
          <div className="delete-que">
            <label>
              Are you sure you want to delete user '{item && item.username}'?
            </label>
          </div>
          <div id="modal-footer-custom">
            <button
              type="login"
              className="btn btn-primary cancel-btn custom style-custom shadow"
              onClickCapture={toggle}
            >
              CANCEL
            </button>
            <button
              type="login"
              className="btn btn-primary delete-btn style-custom custom shadow"
              onClick={handleDelete}
              disabled={loadingDeleteUser}
            >
              {loadingDeleteUser && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              DELETE
            </button>
          </div>
          <br />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { approverAccount: state.approverAccount };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    userDelete: (req) => dispatch(approverUserDeleteInit(req)),
    userDeleteReset: (req) => dispatch(approverUserDeleteReset(req)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountDeleteDialog);
