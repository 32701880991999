import React from "react";
import "./popup.css";
import { useHistory } from "react-router-dom";

// Function to render privaacy policy pop up
const PrivacyPolicyPopup = () => {
  let history = useHistory();

  const handlePrivacyDecline = () => {
    history.replace("/");
  };

  const handlePrivacyAccept = () => {
    history.replace("/", {
      isTermsAccepted: true,
    });
  };

  return (
    <div className="privacy-policy-popup-container">
      <div className="privacy-policy-popup">
        <div className="privacy-policy-header">
          <h3>Privacy Policy</h3>
        </div>
        <div className="privacy-policy-body">
          <p>
            Personal details and other information relating to you provided to
            any Shell company through this App will only be used in accordance
            with our Privacy Policy. Please read this carefully before
            continuing. By downloading the App you are consenting to use of this
            information in accordance with our Privacy Notice which is
            incorporated into these Terms and Conditions of Use by this
            reference.For more details:<a href="https://www.shell.co.uk/privacy/b2b-notice.html" target="_blank">https://www.shell.co.uk/privacy/b2b-notice.html</a>
            
          </p>
        </div>
        <div className="privacy-policy-footer">
          <button
            className="privacy-policy-decline-button"
            onClick={handlePrivacyDecline}
          >
            Decline
          </button>
          <button
            className="privacy-policy-accept-button"
            onClick={handlePrivacyAccept}
          >
            {" "}
            Accept{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPopup;
