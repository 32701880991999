import * as actionTypes from "../actions";
import * as constantTypes from "../../common/constants";

const initialState = {
  screenToDisplay: constantTypes.APPROVER_REQUEST_DEFAULT_SCREEN,
  applicationitem: {},
};

export const reducerApproverRequest = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_REQUEST_LIST:
      return {
        ...state,
        payload: action.payload,
        approverAction: "",
        screenToDisplay: constantTypes.APPROVER_REQUEST_SCREEN,
      };
    case actionTypes.APPROVER_DETAILS:
      return {
        ...state,
        applicationItem: action.payload,
        approverAction: "",
        screenToDisplay: constantTypes.APPROVER_DETAIL_SCREEN,
      };
    case actionTypes.APPROVER_ACTION_TAKEN:
      return {
        ...state,
        approverAction: action.payload,
        screenToDisplay: constantTypes.APPROVER_ACTION_TAKEN_SCREEN,
      };
    case actionTypes.APPROVER_ACCOUNTS:
      return {
        ...state,
        approverAction: action.payload,
        screenToDisplay: constantTypes.APPROVER_ACCOUNTS_SCREEN,
      };
    case actionTypes.APPROVER_REQUEST_GET_LIST_START:
      return { ...state, payload: action.payload, loading: true };

    case actionTypes.APPROVER_REQUEST_GET_LIST_SUCCESS:
      return {
        ...state,
        questionaireSubmitedList: action.payload.submitted,
        error: "",
        loading: false,
      };

    case actionTypes.APPROVER_REQUEST_GET_LIST_FAIL:
      return { ...state, error: action.payload, loading: false };

    case actionTypes.APPROVER_REQUEST_GET_LIST_STOP:
      return { ...state, payload: action.payload };
    //Approver response submit
    case actionTypes.APPRROVER_RESPONSE_INIT:
      return { ...state, payload: action.payload, responseLoading: true };

    case actionTypes.APPRROVER_RESPONSE_SUCCESS:
      return {
        ...state,
        responseSuccess: action.payload,
        responseLoading: false,
      };

    case actionTypes.APPRROVER_RESPONSE_FAIL:
      return {
        ...state,
        responseError: action.payload,
        responseLoading: false,
      };

    case actionTypes.APPRROVER_RESPONSE_RESET:
      return {
        ...state,
        responseSuccess: false,
        responseError: false,
        responseLoading: false,
        approverAction: {},
      };
    case actionTypes.BROADCAST_MESSAGE_CREATION_SCREEN:
      return {
        ...state,
        payload: action.payload,
        screenToDisplay: constantTypes.BROADCAST_SETUP_SCREEN,
      };
    case actionTypes.APPROVER_ACCOUNTS_SETUP_SCREEN:
      return {
        ...state,
        payload: action.payload,
        screenToDisplay: constantTypes.ACCOUNTS_SETUP_SCREEN,
      };
    case actionTypes.APPROVER_REPORTS:
      return {
        ...state,
        approverAction: action.payload,
        screenToDisplay: constantTypes.REPORTS_SCREEN,
      };

    case actionTypes.APPROVER_BROADCAST_MESSAGE:
      return {
        ...state,
        approverAction: action.payload,
        screenToDisplay: constantTypes.APPROVER_BROADCAST_SCREEN,
      };
    case actionTypes.REPORTS_SUCCESS_SCREEN:
      return {
        ...state,
        payload: action.payload,
        screenToDisplay: constantTypes.REPORTS_SUCCESS_SCREEN,
      };
    default:
      return state;
  }
};
