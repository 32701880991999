import * as actionTypes from "../../action_types";

export const accountsDataInit = (data) => {
  return {
    payload: data,
    type: actionTypes.ACTIONS_DATA_INIT,
  };
};

export const accountsDataReset = (data) => {
  return {
    payload: data,
    type: actionTypes.ACTIONS_DATA_RESET,
  };
};
