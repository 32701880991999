import * as actionTypes from "./action_types";

export const setInitialState = (data) => {
  return {
    payload: data,
    type: actionTypes.SNACK_INIT,
  };
};

export const showErrorMessage = (data) => {
  return {
    payload: data,
    type: actionTypes.SNACK_ERROR_MESSAGE,
  };
};

export const showWarningMessage = (data) => {
  return {
    payload: data,
    type: actionTypes.SNACK_WARNING_MESSAGE,
  };
};

export const showInfoMessage = (data) => {
  return {
    payload: data,
    type: actionTypes.SNACK_INFO_MESSAGE,
  };
};

export const showSuccessMessage = (data) => {
  return {
    payload: data,
    type: actionTypes.SNACK_SUCCESS_MESSAGE,
  };
};
