import * as actionTypes from "./action_types";

export const switchTab = data => {
  return {
    payload: data,
    type: actionTypes.SWITCH_TAB
  };
};

export const switchTabReset = data => {
  return {
    payload: data,
    type: actionTypes.SWITCH_TAB_RESET
  };
};



