import * as actiontypes from "../actions";

const initialState = {
  payload: [{}],
  error: "",
  loading: false,
  notificationListSuccess: [],
  openPopUp: false,
};

export const reducerNotificationList = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.NOTIFICATIONLIST_INIT:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };

    case actiontypes.NOTIFICATIONLIST_SUCCESS:
      return {
        ...state,
        notificationListSuccess: action.payload,
        loading: false,
      };

    case actiontypes.NOTIFICATIONLIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actiontypes.NOTIFICATIONLIST_OPEN:
      return {
        ...state,
        openPopUp: action.payload,
      };
    default:
      return state;
  }
};

const initialStateBadge = {
  notificationBadgeCount: 0,
  notificationCounterror: "",
};

export const reducerNotificationBadge = (state = initialStateBadge, action) => {
  switch (action.type) {
    case actiontypes.NOTIFICATION_WALL_START_TIMER:
      return {
        ...state,
        notificationBadgeCount: action.payload,
      };

    case actiontypes.NOTIFICATION_WALL_STOP_TIMER:
      return {
        ...state,
        notificationBadgeCount: action.payload,
      };

    case actiontypes.NOTIFICATION_WALL_ERROR_TIMER:
      return {
        ...state,
        notificationCounterror: action.payload,
      };

    default:
      return state;
  }
};
