import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { loginRequest } from "../sagas/API";

import {
  USER_NOT_FOUND,
  SECURITY_RESET,
  SIGN_UP,
  ACCOUNT_LOCK,
} from "../../common/constants";

var shaJs = require("sha.js");

export function* login(action) {
  const data = {
    username: action.data.userName.toLowerCase(),
    password: new shaJs.sha256().update(action.data.password).digest("hex"),
  };
  try {
    const response = yield loginRequest(data);
    if (response.status === 404) {
      yield put(actions.loginFail(USER_NOT_FOUND));
    } else if (response.status === 409) {
      yield put(actions.loginReset(SECURITY_RESET));
    } else if (response.status === 410) {
      yield put(actions.loginFail(SIGN_UP));
    } else {
      try {
        const response_data = yield response.json();

        if (response.status === 200) {
          yield put(
            actions.setAuthToken(
              response_data.access_token,
              response_data.refresh_token
            )
          );

          yield put(
            actions.loginSuccess({
              token: response_data.access_token,
              ...action,
            })
          );
        } else if (response.status === 401) {
          yield put(actions.loginFail(response_data.message));
        } else {
          yield put(
            actions.showErrorMessage(
              "error code - " + response.status + "-" + response_data.message
            )
          );

          yield put(actions.loginFail(response_data.message));
        }
      } catch (e) {
        yield put(actions.loginFail(ACCOUNT_LOCK));
      }
    }
  } catch (e) {
    yield put(actions.loginFail(e));
  }
}
