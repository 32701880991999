import React, { useState } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import icon_approved from "../../assets/accept_notification.png";
import icon_rejected from "../../assets/reject_notification.png";
import icon_pending from "../../assets/pending_notification.png";
import icon_broadcast from "../../assets/icon_broadcast.png";
import notification from "../../assets/notification.png";
import NotificationBadge from "react-notification-badge";
import BroadcastMessageAlert from "./broadcast-message-alert";
import ClickedNotification from "../../assets/clicked_notification.png";
import { Effect } from "react-notification-badge";
import "./notification.css";
import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  initNotificationList,
  notificationWallCountTimer,
  notificationWallStopTimer,
  displayAdminApproved,
  displayAdminRejectPending,
  openNotificationPopOver,
} from "../../store/actions/";
import { ADMIN_ID } from "../../common/constants";

const NotificationPopover = (props) => {
  const node = useRef();
  const {
    notificationListSuccess,
    error,
    loading,
    openPopUp,
  } = props.notificationList;
  const { notificationBadgeCount } = props.notificationBadge;
  const [showBroadcastAlert, setShowBroadcastAlert] = useState(false);
  const [countBadge, setCountBadge] = useState(1);
  const [
    notificationBroadcastDetails,
    setNotificationBroadcastDetails,
  ] = useState("{}");

  const userName = localStorage.getItem("nWDF18M");
  const toggle = () => props.requestOpenPopOver(!openPopUp);
  const { languageSet } = props.localization;
  const handleItemClicked = (notification) => {
    switch (notification.application_response.toLowerCase()) {
      case "rejected":
        if (ADMIN_ID === localStorage.getItem("rWDKTId")) {
          props.requestAdminPendingRejectView(notification);
        } else {
          if (notification.approver_name) {
            props.history.push("/home/approved", {
              notification: notification,
              approvedByApprover: true,
            });
    
          } else {
            props.history.push("/home/pendingRejected", notification);
          }
        }
        props.requestOpenPopOver(false);
        return;
      case "pending":
        if (ADMIN_ID === localStorage.getItem("rWDKTId")) {
          props.requestAdminPendingRejectView(notification);
        } else {
          props.history.push("/home/pendingRejected", notification);
        }
        props.requestOpenPopOver(false);
        return;
      case "approved":
        if (ADMIN_ID === localStorage.getItem("rWDKTId")) {
          props.requestAdminApprovedView({
            notification: notification,
            approvedByApprover: true,
          });
        } else {
          props.history.push("/home/approved", {
            notification: notification,
            approvedByApprover: true,
          });
        }
        props.requestOpenPopOver(false);
        return;
      default:
        props.requestOpenPopOver(false);
        setShowBroadcastAlert(true);
        setNotificationBroadcastDetails(notification);
        return;
    }
  };

  useEffect(() => {
    props.requestNotificationPullBadge();
    document.addEventListener("mousedown", handleClick, false);

    return () => {
      //This will execute every time the component unloads.
      props.requestNotificationPullBadgeStop();
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current && node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    //setPopoverOpen(false);
  };

  useEffect(() => {
    setCountBadge(notificationBadgeCount);
  }, [notificationBadgeCount]);

  useEffect(() => {
    if (openPopUp) props.requestNotificationList({ isCount: false });
  }, [openPopUp]);

  const getNotificationIcon = (applicationResponse) => {
    switch (applicationResponse.toLowerCase()) {
      case "rejected":
        return icon_rejected;
      case "pending":
        return icon_pending;
      case "approved":
        return icon_approved;
      case "":
        return icon_broadcast;
    }
  };

  return (
    <div ref={node}>
      <BroadcastMessageAlert
        showBroadcastAlert={showBroadcastAlert}
        setShowBroadcastAlert={setShowBroadcastAlert}
        notificationBroadcastDetails={notificationBroadcastDetails}
      />
      <div onClick={toggle}>
        <NotificationBadge
          id="PopoverList"
          count={countBadge}
          effect={Effect.SCALE}
        />
      </div>
      <div>
        <img
          id="PopoverList"
          src={openPopUp ? ClickedNotification : notification}
        />
      </div>

      <Popover
        ref={node}
        className="popover"
        placement="bottom"
        isOpen={openPopUp}
        target="PopoverList"
        toggle={toggle}
      >
        <PopoverHeader ref={node}>{languageSet.notifications}</PopoverHeader>
        <PopoverBody>
          {loading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {error && !loading && <label> {error}</label>}
          {!loading && (
            <div className="list-group-flush" ref={node}>
              {notificationListSuccess.length !== 0 &&
                notificationListSuccess.map((notification, index) => (
                  <div
                    key={index}
                    className=" list-group-item flex-container-notify"
                    onClick={() => handleItemClicked(notification)}
                  >
                    <div>
                      <img
                        src={getNotificationIcon(
                          notification.application_response
                        )}
                      />
                    </div>
                    <div className="notification-item">
                      <p>
                        {(notification.application_response &&
                          (notification.created_by === userName ||
                            notification.approver_name !== "")) ||
                        !notification.created_by
                          ? `${languageSet.application_response}: ${notification.application_response}`
                          : `${notification.created_by} ${
                              notification.application_response === "pending"
                                ? languageSet.admin_site_notif_pending
                                : languageSet.admin_site_notif_rejected
                            }`}

                        {!notification.application_response &&
                          `${languageSet.broadcast_message_by}: ${notification.approver_name}`}
                      </p>
                      <p>
                        {notification.application_response &&
                          `${languageSet.flight_reg_number}: ${notification.flight_no}`}
                        {!notification.application_response &&
                          notification.broadcast_message}
                      </p>
                      {notification.approver_comment && (
                        <p>
                          {`${languageSet.approver_comment}: ${notification.approver_comment}`}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              {notificationListSuccess.length === 0 && (
                <label>{languageSet.no_data}</label>
              )}
            </div>
          )}
        </PopoverBody>
      </Popover>
    </div>
  );
};
// Listens to changes in store
const mapStateToProps = (state, ownProps) => {
  return state;
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestNotificationList: (notificationList) =>
      dispatch(initNotificationList(notificationList)),

    requestNotificationPullBadge: (notificationPullBadge) =>
      dispatch(notificationWallCountTimer(notificationPullBadge)),

    requestNotificationPullBadgeStop: (notificationPullBadgeStop) =>
      dispatch(notificationWallStopTimer(notificationPullBadgeStop)),

    requestAdminApprovedView: (notification) =>
      dispatch(displayAdminApproved(notification)),
    requestAdminPendingRejectView: (notification) =>
      dispatch(displayAdminRejectPending(notification)),
    requestOpenPopOver: (data) => dispatch(openNotificationPopOver(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationPopover));
