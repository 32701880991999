const _ = require("lodash");

export const arrangeList = (questionaireSubmitedList) => {
  questionaireSubmitedList.sort(function(a, b) {
    var date1 = new Date(a.created_on);
    var date2 = new Date(b.created_on);
    return date1 - date2;
  });
  var groupedList =
    questionaireSubmitedList &&
    questionaireSubmitedList.map((item) => {
      let date = ("0" + new Date(item.created_on).getDate())
        .slice(-2)
        .toString(); // to get date in dd format
      let month = ("0" + (new Date(item.created_on).getMonth() + 1))
        .slice(-2)
        .toString(); //to get month in mm format
      item.ceratedDate = month + date; //both will be overlapped in mm/dd format, for example 0101(january 1)
      return item;
    });
  //grouping
  groupedList = _.chain(questionaireSubmitedList)
    .groupBy("ceratedDate")
    .map((value, key) => ({
      key: key,
      value: value,
    }))
    .value();

  return groupedList;
};

const getNth = function(d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export const getDateLocal = (item) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  let date = new Date(item.created_on);

  return (
    date.getDate() +
    getNth(date.getDate()) +
    " " +
    monthNames[date.getMonth()] +
    "," +
    " " +
    day[date.getDay()]
  );
};

export const getTimeLocal = (item) => {
  let time = new Date(item);
  return time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};
