import * as actionTypes from "./action_types";

export const actionApproverReqTab = (data) => {
  return {
    payload: data,
    type: actionTypes.APPROVER_REQUEST_TAB,
  };
};

export const actionReportTab = (data) => {
  return {
    payload: data,
    type: actionTypes.APPROVER_REPORTS_TAB,
  };
};

export const actionAccountsTab = (data) => {
  return {
    payload: data,
    type: actionTypes.APPROVER_ACCOUNTS_TAB,
  };
};

export const actionBroadcastTab = (data) => {
  return {
    payload: data,
    type: actionTypes.APPROVER_BROADCAST_TAB,
  };
};
