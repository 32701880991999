import { put } from "redux-saga/effects";
import * as actions from "../../actions";
import { getTcPdf } from "../API";

export function* tcGetPdf(action) {
  try {
    const response = yield getTcPdf(action.payload);
    switch (response.status) {
      case 200:
        try {
          var responseData = yield response.json();
          yield put(
            actions.tcPdfSuccess(responseData.payload.tncs[0].links[0].link)
          );
        } catch (e) {
          yield put(actions.showErrorMessage("Invalid Json"));
        }
        break;
      case 401:
        yield put(actions.showErrorMessage("Unauthorized access"));

        break;

      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));

        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage(e));
  }
}
