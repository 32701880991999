import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./application-approved-rejected.css";
import ApplicationApproved from "../../../../assets/application_approved.png";
import ApplicationRejected from "../../../../assets/application_rejected.png";
import { getApproverResponseReset } from "../../../../store/actions";
const ApplicationApprovedRejected = (props) => {
  const { approverAction } = props.approverRequest;
  const [showRejected, setShowRejected] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    if (approverAction) {
      switch (approverAction) {
        case "approved":
          setShowApproved(true);
          break;
        case "rejected":
          setShowRejected(true);
          break;
        case "conflict":
          setShowMessage(true);
          break;
        default:
          return;
      }
    }
  }, [approverAction]);
  useEffect(() => {
    return () => {
      props.requestGetApproverResponseReset(); //cleanup states at exit of screen
    };
  }, []);
  return (
    <div>
      <div className="requests-header">
        <label>REQUESTS</label>
        <label style={{ paddingLeft: "1vw" }}> > </label>
        <div id="app">
          <a>Application</a>
        </div>
        <label style={{ paddingLeft: "1vw" }}> > </label>
        <div id="app_status">
          <a>Application Status</a>
        </div>
      </div>
      <div className="app-details-container custom shadow">
        {showApproved && !showMessage && !showRejected && (
          <div className="parent-image-container">
            <img className="approved-image" src={ApplicationApproved} />
            <div className="approved-notified-msg">
              <label style={{ color: "#008343" }}>
                Application has been approved.
              </label>
              <label style={{ color: "#707070", fontSize: "13px" }}>
                User has been notified.
              </label>
            </div>
          </div>
        )}
        {showRejected && !showApproved && !showMessage && (
          <div className="parent-image-container">
            <img className="approved-image" src={ApplicationRejected} />
            <div className="approved-notified-msg">
              <label style={{ color: "#DD1D21" }}>
                Application has been rejected.
              </label>
              <label style={{ color: "#707070", fontSize: "13px" }}>
                User has been notified.
              </label>
            </div>
          </div>
        )}
        {showMessage && !showApproved && !showRejected && (
          <div className="parent-image-container">
            <div className="decision-taken">
              <label>
                Sorry, the application has already been taken an action on.{" "}
                <br />
                <br />
                Proceed with other applications requests.
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestGetApproverResponseReset: (req) =>
      dispatch(getApproverResponseReset(req)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApplicationApprovedRejected));
