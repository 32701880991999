import React from "react";
import "./approver-reports-success-screen.css";
import SymbolCorrect from "../../../assets/symbol_correct.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getReportsPage,
  getApproverReportsReset,
  reportsDataReset,
} from "../../../store/actions";
const ApproverReportsSuccessScreen = (props) => {
  const handleReports = () => {
    props.requestReportReset();
    props.requestReportsPage();
    props.requestDataReset();
  };
  return (
    <div className="parent-reports-container">
      <div className="reports-header">
        <label>REPORTS</label>
      </div>
      <div className="outer-report-success-container">
        <div className="report-inner-success-container">
          <div className="report-success-container">
            <div className="inner-report-success-style">
              <div className="created-msg-holder report-create">
                <img className="symbol_created" src={SymbolCorrect} />
                <label>REPORT REQUEST SUCCESSFUL</label>
                <label style={{ fontSize: "small" }}>
                  Requested report will be sent to your registered email address.
                </label>
                <br />
                <br />
                <div id="newaccount" onClick={handleReports}>
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#003C88",
                    }}
                  >
                    New Report
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestReportsPage: () => dispatch(getReportsPage()),
    requestReportReset: () => dispatch(getApproverReportsReset()),
    requestDataReset: () => dispatch(reportsDataReset()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverReportsSuccessScreen));
