import * as actionTypes from "../../../actions";

const initialState = {
  payload: {},
};

export const reducerDeleteSite = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SITE_INIT:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };
    case actionTypes.DELETE_SITE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
      };

    case actionTypes.DELETE_SITE_FAIL:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
      };

    case actionTypes.DELETE_SITE_RESET:
      return {
        ...state,
        success: false,
        error: false,
        loading: false,
      };

    default:
      return state;
  }
};
