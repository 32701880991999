import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { displayAccounts } from "../../../store/actions";
import "./accounts-new-site-details.css";
import { useForm } from "react-hook-form";
import Select from "react-select";
import SearchIcon from "../../../assets/Search_Icn.png";
import { ListVirtualization } from "../../../common/components/list-virtualization";
import {
  approverAccountsAirportInit,
  siteCreateInit,
  getSiteCreateReset,
} from "../../../store/actions";
const AccountsNewSiteDetails = (props) => {
  const handleAccounts = () => {
    props.requestAccountsPage();
  };
  const { handleSubmit, setValue } = useForm();

  const {
    airportList,
    countryList,
    siteCreateSuccess,
    siteCreateError,
    loading,
  } = props.approverAccount || {};
  const [countryValue, setCountryValue] = useState();
  const [airportValue, setAirportValue] = useState();
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [isAllFieldFilled, setIsAllFieldFilled] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [itpOperateCompany, setItpOperateCompany] = useState("");
  const [invalidSiteName, setInvalidSiteName] = useState(false);
  const [airportBasedOnCountry, setAirportBasedOnCountry] = useState();

  const countryChange = (country) => {
    var countryCode = country.value.split(",").pop();
    var list = airportList.filter((item) => {
      if (item.COUNTRY_CD.trim() === countryCode.trim()) {
        return item;
      }
    });
    setAirportBasedOnCountry(list);
  };

  const isEveryFieldFilled = () => {
    if (!countryValue || !airportValue || !siteName || !itpOperateCompany) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (isEveryFieldFilled()) {
      setIsAllFieldFilled(false);
    } else {
      setIsAllFieldFilled(true);
    }
  });
  useEffect(() => {
    if (!airportList) {
      props.requestAccountsAirport();
    }
  }, []);
  const handleCountry = (selectedOption) => {
    if (selectedOption) {
      setValue("countryname", selectedOption.value);
      setCountryValue(selectedOption);
      countryChange(selectedOption);
    }
  };
  const handleAirport = (selectedOption) => {
    if (selectedOption) {
      setValue("airportvalue", selectedOption.value);
      setAirportValue(selectedOption);
    }
  };
  useEffect(() => {
    if (siteCreateSuccess) {
      props.successAction(true, true);
      props.requestGetSiteCreateReset();
    }
    if (siteCreateError) {
      if (siteCreateError === "Site name already exists") {
        setInvalidSiteName(true);
        props.requestGetSiteCreateReset();
      } else props.requestGetSiteCreateReset();
    }
  }, [siteCreateSuccess, siteCreateError]);
  const handleSave = () => {
    if (isAllFieldFilled) {
      var reqBody = {
        site_name: siteName.toLowerCase(),
        site_country: countryValue.value,
        itp_operating_company: itpOperateCompany,
        site_airport: airportValue.value,
        createdBy: localStorage.getItem("nWDF18M"),
      };
      props.requestSiteCreateInit(reqBody);
    }
  };
  return (
    <div className="site-details-parent-container">
      <div className="details-holder">
        <div className="new-user">
          <label>New Site</label>
          <hr className="new-user-dash"></hr>
        </div>
      </div>
      <div style={{ marginLeft: "7%" }}>
        {!isAllFieldFilled && isSaveButtonClicked && (
          <p className="error_msg">
            All field values are mandatory. Please complete before saving.
          </p>
        )}
      </div>
      <div className="details-container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <div className="md-form mt-0">
                <Select
                  type="text"
                  ClearIndicator="true"
                  classNamePrefix={
                    "react-select__control " +
                    (!countryValue && isSaveButtonClicked ? "border-red" : "") +
                    " "
                  }
                  placeholder="Enter Country Name"
                  isClearable="true"
                  isLoading={true}
                  options={countryList}
                  onChange={setCountryValue}
                  name="countryname"
                  components={{
                    DropdownIndicator: () => {
                      return (
                        <img
                          className="icon-search-size no-outline"
                          src={SearchIcon}
                        />
                      );
                    },
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null,
                    MenuList: ListVirtualization,
                  }}
                  onChange={handleCountry}
                />
              </div>
            </div>
            <div className="col">
              {airportBasedOnCountry && (
                <div className="md-form mt-0">
                  <Select
                    type="text"
                    ClearIndicator="true"
                    classNamePrefix={
                      "react-select__control " +
                      (!airportValue && isSaveButtonClicked
                        ? "border-red"
                        : "") +
                      " "
                    }
                    placeholder="Search Airport"
                    isClearable="true"
                    isLoading={true}
                    name="airportname"
                    options={airportBasedOnCountry}
                    onChange={setAirportValue}
                    components={{
                      DropdownIndicator: () => {
                        return (
                          <img
                            className="icon-search-size no-outline"
                            src={SearchIcon}
                          />
                        );
                      },
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null,
                      MenuList: ListVirtualization,
                    }}
                    onChange={handleAirport}
                  />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="details-container custom-style">
        <form>
          <div className="row">
            <div className="col">
              <div className="md-form mt-0" style={{ paddingLeft: "2%" }}>
                <input
                  type="text"
                  className={
                    "form-control input-custom custom-style shadow-none" +
                    " " +
                    ((!siteName && isSaveButtonClicked) ||
                    (invalidSiteName && isSaveButtonClicked)
                      ? "border-red"
                      : "")
                  }
                  placeholder="Site Name"
                  name="sitename"
                  onChange={(evt) => {
                    setSiteName(evt.target.value);
                    setInvalidSiteName(false);
                  }}
                />
                {invalidSiteName && isSaveButtonClicked && (
                  <p className="error_msg">Site name should be unique.</p>
                )}
              </div>
            </div>
            <div className="col">
              <div className="md-form mt-0" style={{ paddingRight: "2%" }}>
                <input
                  type="text"
                  className={
                    "form-control input-custom custom-style shadow-none" +
                    " " +
                    (!itpOperateCompany && isSaveButtonClicked
                      ? "border-red"
                      : "")
                  }
                  placeholder="ITP Operating Company"
                  name="itpoperatingcompany"
                  maxLength={30}
                  onChange={(evt) => {
                    setItpOperateCompany(evt.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="save-cancel-button-style">
        <div className="save-cancel-pos">
          <button
            type="login"
            className="btn btn-primary cancel-btn custom style-custom button-style shadow"
            onClick={handleAccounts}
          >
            CANCEL
          </button>
          <button
            type="save"
            className="btn btn-primary login-button custom-style shadow"
            onClick={() => {
              setIsSaveButtonClicked(true);
              handleSave();
            }}
            disabled={loading}
          >
            {loading && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { approverAccount: state.approverAccount };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestAccountsAirport: (req) => dispatch(approverAccountsAirportInit(req)),
    requestSiteCreateInit: (req) => dispatch(siteCreateInit(req)),
    requestGetSiteCreateReset: () => dispatch(getSiteCreateReset()),
    requestAccountsPage: (approverAccounts) =>
      dispatch(displayAccounts(approverAccounts)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsNewSiteDetails));
