import React from "react";
import first from "../../assets/First.png";
import flightNoHighlight from "../../assets/Second-nostate.png";
import flightHighlight from "../../assets/second_with_state.png";
import third from "../../assets/Third-nostate.png";
import "./fueler-dashboard.css";
import WizardIcon from "../../components/fueler-dashboard/wizard-icon/wizardicon";
import locationicon from "../../assets/location.png";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { connect } from "react-redux";
import { useState } from "react";
import CargoQuestions from "../../components/fueler-dashboard/cargo-questions";
import SearchIcon from "../../assets/Search_Icn.png";
import MainDest from "../../components/fueler-dashboard/main-dest";
import { withRouter } from "react-router";
import dashIcon from "../../assets/Dot.png";
import ApplicationPendingAlert from "../../components/fueler-dashboard/application-pending-alert/application-pending-alert";
import ApplicationRejectionAlert from "../../components/fueler-dashboard/application-rejection-alert/application-rejection-alert";
import { useEffect } from "react";
import { ListVirtualization } from "../../common/components/list-virtualization";
import {
  ICSC_ID,
  ICSC_SITE_ID,
  ADMIN_ID,
  REJECT_CUBA,
  YES,
  NO,
  DONT_KNOW,
} from "../../common/constants";

import {
  initQuestionnaireValidations,
  initQuestionnaireSubmit,
  resetQuestionnaireSubmit,
  resetQuestionnaireValidation,
  initFuelerInitialData,
  displayAdminApproved,
  displayAdminRejectPending,
  localizationSuccess,
} from "../../store/actions";

const FuelerWizard = (props) => {
  const {
    airportList,
    aircraftList,
    customerList,
    userId,
    siteDetails = "",
    iCSCSites = "",
    initialDataLoading,
  } = props.fuelerInitialData || {};

  const {
    questionnaireValidationSuccess,
    error,
  } = props.questionnaireValidation;
  const { questionnaireSubmitSuccess } = props.questionnaireSubmit;
  const [aircraftValue, setAircratfValue] = useState("");
  const [originvalue, setOriginValue] = useState("");
  const [customerValue, setCustomerValue] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [destCounts, setCountDest] = useState([1]);
  const [destinations, setDestinations] = useState([]);
  const [showCustomerName, setShowCustomerName] = useState(false);
  const [departureDate, setDepartureDate] = useState(new Date());
  const [natureOfFlightJson, setNatureOfFlightJson] = useState({});
  const [isAllFieldFilled, setIsAllFieldFilled] = useState(false);
  const [isDestMainEmpty, setDestmainISEmpty] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const [showNatureOfFlight, setShowNatureOfFlight] = useState(false);
  const [destXXI, setDestXXI] = useState(false);
  const [showSanctionQuestion, setShowSanctionQuestion] = useState(false);
  const [showPassenger, setShowPassenger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const [shellCustomerName, setShellCustomerName] = useState("");
  const [customerType, setCustomerType] = useState();
  const [rejectAlert, setRejectAlert] = useState(false);
  const [pendingAlert, setPendingAlert] = useState(false);
  const [questionnaireId, setQuestionnaireId] = useState();
  const [passengerSelected, setPassengerSelected] = useState(false);
  const { languageSet } = props.localization;
  const { fromAdmin } = props || false;
  const [flightDetailsNoHighlight, setFlightDetailsNoHighlight] = useState(
    flightNoHighlight
  );
  const [showQuestionStageWise, setShowQuestionStageWise] = useState(0);
  const [ICSCValue, setICSCValue] = useState();
  const userrole = localStorage.getItem("rWDKTId");

  const cargoView = "1";
  const passengerView = "2";
  const ferryView = "3";

  const isCustomerNameOther = (customerValue, showCustomerName) => {
    return (
      customerValue.value &&
      customerValue.value.includes("Other")
    );
  };


  const handleSubmitApi = (comment) => {
    var submitData = {
      userid: userId,
      questionnaireid: questionnaireId,
      comments: comment,
    };
    props.requestQuestionnaireSubmit(submitData);
  };
  useEffect(() => {
    props.requestFuelerInitialData(localStorage.getItem("rWDKTId"));
  }, []);

  useEffect(() => {
    if (isCustomerNameOther(customerValue, showCustomerName)) {
      setShowCustomerName(true);
    } else setShowCustomerName(false);
    if (
      customerValue.Type === "Reseller" ||
      customerValue.value === "Other (Fill yourself)"
    ) {
      setShowSanctionQuestion(true);
    } else setShowSanctionQuestion(false);
    if (
      (customerValue.Type === "Shell Customer" &&
        aircraftValue.AIRCRAFT_CONFIGID === "Commercial") ||
      customerValue === "" ||
      aircraftValue === "" //Empty string as set by useState, this is to show or hide nature of flight
    ) {
      setShowNatureOfFlight(false);
    } else setShowNatureOfFlight(true);

    if (aircraftValue.AIRCRAFT_CONFIGID === "Commercial") {
      setShowPassenger(false);
    } else {
      setShowPassenger(true);
    }

        if (destinations) {

      let shouldSkip = false

      destinations.forEach((item) => {

      if(item !== undefined && item !== null && item !== "" && shouldSkip === false){

      if (['China','Russia','Russian Fed.','Russian Federation','Afghanistan','Myanmar','Venezuela','Hong Kong','Belarus','Cambodia'].includes(item.COUNTRY_NM)) {

      setShowNatureOfFlight(true);

      shouldSkip = true;

      }

      else{

        if(destinations === "")

      {setShowNatureOfFlight(false)};

      }

      }

      });

      }

      

    if (registrationNo) {

      let range = ["RA", "RF", "YV", "BH", "BK", "BL", "XY", "XU", "KW", "FK", "YA", "EW", "UR"];

      let regCaps = registrationNo.toUpperCase();

      let firsttwo = regCaps.slice(0, 2);

      if (range.includes(firsttwo)){

      setShowNatureOfFlight(true);

      }

      else if(regCaps.startsWith("B") && regCaps.length === 5){

      setShowNatureOfFlight(true);

      }

      else{

        if(registrationNo ==="")

        {setShowNatureOfFlight(false);}

      }

      }
  }, [customerValue, aircraftValue, [JSON.stringify(destinations)], registrationNo]);

  useEffect(() => {
    /*To either go to approved screen or show pop up asking for confirmation*/
    if (questionnaireValidationSuccess) {
      setLoading(false);
      if (questionnaireValidationSuccess.message) {
        switch (questionnaireValidationSuccess.message.toLowerCase()) {
          case "approved":
            var submitData = {
              userid: userId,
              questionnaireid: questionnaireValidationSuccess.id,
              comments: "",
            };
            props.requestQuestionnaireSubmit(submitData);
            break;
          case "pending":
            setQuestionnaireId(questionnaireValidationSuccess.id);
            setPendingAlert(true);

            break;
          case "rejected":
            setQuestionnaireId(questionnaireValidationSuccess.id);
            setRejectAlert(true);

            break;
          default:
            return;
        }
        props.requestQuestionnaireValidationReset();
      }
    }
    if (error) {
      setLoading(false);
    }
  }, [questionnaireValidationSuccess, error]);

  useEffect(() => {
    //based on validations navigate to next page
    //navigation after the submit api

    if (questionnaireSubmitSuccess) {
      if (questionnaireSubmitSuccess.questionnaire) {
        switch (
          questionnaireSubmitSuccess.questionnaire.message.toLowerCase()
        ) {
          case "approved":
            if (ADMIN_ID === localStorage.getItem("rWDKTId")) {
              props.requestAdminApprovedView({
                notification: {
                  audit_number:
                    questionnaireSubmitSuccess.questionnaire.auditNo,
                  application_response:
                    questionnaireSubmitSuccess.questionnaire.message,
                },
                approvedByApprover: false,
                submitFlow: true,
              });
            } else {
              props.history.push("/home/approved", {
                notification: {
                  audit_number:
                    questionnaireSubmitSuccess.questionnaire.auditNo,
                  application_response:
                    questionnaireSubmitSuccess.questionnaire.message,
                },
                approvedByApprover: false,
                submitFlow: true,
              });
            }
            break;
          case "pending":
            if (ADMIN_ID === localStorage.getItem("rWDKTId")) {
              props.requestAdminPendingRejectView({
                application_response: "pending",
                submitFlow: true,
                notification: {
                  audit_number:
                    questionnaireSubmitSuccess.questionnaire.auditNo,
                },
              });
            } else {
              props.history.push("/home/pendingRejected", {
                application_response: "pending",
                submitFlow: true,
                notification: {
                  audit_number:
                    questionnaireSubmitSuccess.questionnaire.auditNo,
                },
              });
            }
            break;
          case "rejected":
            var rejectCuba = false;
            if (
              questionnaireSubmitSuccess.questionnaire.status_rules.reject_reasons.includes(
                REJECT_CUBA
              )
            ) {
              rejectCuba = true;
            }
            if (ADMIN_ID === localStorage.getItem("rWDKTId")) {
              props.requestAdminPendingRejectView({
                application_response: "rejected",
                submitFlow: true,
                rejectCuba: rejectCuba,
              });
            } else {
              props.history.push("/home/pendingRejected", {
                application_response: "rejected",
                submitFlow: true,
                rejectCuba: rejectCuba,
              });
            }
            break;
          default:
            break;
        }

        props.requestQuestionnaireReset();
      }
    }
  }, [questionnaireSubmitSuccess]);
  const handleMinus = (dest) => {
    const _destCounts = destCounts.filter((m) => m !== dest);
    setCountDest(_destCounts);
    let temp = destinations.slice(0, -1);
    setDestinations(temp);
  };
  const handlePlus = (dest, index) => {
    const destCount = [...destCounts];
    destCount.push(dest);
    if (destCount.length <= 5) {
      setCountDest(destCount);
      let temp = destinations;
      temp.push(null);
      setDestinations(temp);
    }
  };

  const onChangeFlightDetailHighlight = () => {
    setFlightDetailsNoHighlight(true);
  };

  var aircraftListFormatted,
    airportListFormatted,
    customerListFormatted,
    iCSCSitesFormatted = [];
  try {
    aircraftListFormatted = aircraftList.map((v) => ({
      value: v.AIRCRAFT_ID,
      label: `${v.AIRCRAFT_ID} (${v.AIRCRAFTNAME})`,
      AIRCRAFT_IATA: v.AIRCRAFT_IATA,
      AIRCRAFTNAME: v.AIRCRAFTNAME,
      AIRCRAFT_CONFIGID: v.AIRCRAFT_CONFIGID,
    }));
    airportListFormatted = airportList.map((v) => ({
      value: v.LOCATION_IATA_CD,
      label: `${v.LOCATION_IATA_CD}, ${v.LOCATION_NM} (${v.COUNTRY_NM})`,
      LOCATION_NM: v.LOCATION_NM,
      COUNTRY_CD: v.COUNTRY_CD,
      COUNTRY_NM: v.COUNTRY_NM,
    }));
    customerListFormatted = customerList.map((v) => ({
      value: v.AviationCustomer,
      label: `${v.AviationCustomer} (${v.Type})`,
      Type: v.Type,
    }));
    iCSCSitesFormatted = iCSCSites.map((v) => ({
      value: v.site_name,
      label: v.site_name,
      siteId: v.siteid,
    }));
  } catch (e) {}

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    register({ name: "aircrafttype" });
    register({ name: "shellcustomername" });
  }, [register]);

  const handleSwitchView = (value) => {
    setShowQuestionStageWise(value);
    const mainJsonTag = {};
    switch (value) {
      case cargoView:
        mainJsonTag.isCargo = true;
        setNatureOfFlightJson(mainJsonTag);
        break;
      case passengerView:
        mainJsonTag.isPassenger = true;
        setNatureOfFlightJson(mainJsonTag);
        break;
      case ferryView:
        mainJsonTag.isFerry = true;
        setNatureOfFlightJson(mainJsonTag);
        break;
    }
  };
  const handleIsPassengerSanctioned = (value) => {
    const extnJson = {};
    extnJson.ispassengersanctioned = value;
    natureOfFlightJson.passenger = extnJson;
    setNatureOfFlightJson(natureOfFlightJson);
  };

  const onSubmit = (data) => {
    if (isAllFieldFilled) {
      if (!showPassenger && passengerSelected) {
        handleIsPassengerSanctioned("");
      }
      const destinationAirport = [];
      const destinationCountry = [];
      var siteID;

      if (destinations) {
        destinations.forEach((item) => {
          if (item) {
            destinationAirport.push(item.value);
            destinationCountry.push(item.COUNTRY_NM);
          }
        });
      }

      if (userrole === ICSC_ID || ICSC_SITE_ID === siteDetails.siteid) {
        siteID = ICSCValue.siteId;
      } else siteID = siteDetails.siteid;

      data.originAirport = originvalue.value;
      data.originCountry = originvalue.COUNTRY_NM;
      data.departureTime = departureDate.getTime();
      data.desitnationAirport = destinationAirport;
      data.destinationCountry = destinationCountry;
      data.natureofflight = natureOfFlightJson;
      data.isenduserundersanction === "YES"
        ? (data.isenduserundersanction = true)
        : (data.isenduserundersanction = false);

      let questionnaireData = {
        userid: userId,
        questionnaire: {
          siteid: siteID,
          registrationno: registrationNo.replace(/[^0-9a-z]/gi, ""),
          aircrafttype: aircraftValue.value, //value could be empty at times
          customer: {
            shellcustomername:
              data.shellcustomername === "Other (Fill yourself)"
                ? ""
                : data.shellcustomername,
            other: data.othername,
            type:
              data.shellcustomername === "Other (Fill yourself)"
                ? ""
                : customerType,
          },
          isenduserundersanction: data.isenduserundersanction
            ? data.isenduserundersanction
            : false,
          totalnumberofdestination: data.desitnationAirport.length,
          originAirport: data.originAirport,
          originCountry: data.originCountry,
          departureTime: data.departureTime,
          desitnationAirport: data.desitnationAirport,
          destinationCountry: data.destinationCountry,
          natureofflight: data.natureofflight,
        },
      };
      setLoading(true);

      if (
        !data.natureofflight.isCargo &&
        !data.natureofflight.isPassenger &&
        !data.natureofflight.isFerry
      ) {
        delete questionnaireData.questionnaire["natureofflight"];
      }

      props.requestQuestionnaireValidation(questionnaireData);
    }
  };
  const icscValidation = () => {
    if (userrole === ICSC_ID || ICSC_SITE_ID === siteDetails.siteid) {
      if (!ICSCValue) return true;
    }
    return false;
  };
  const destinationValidation = () => {
    for (var i = 0; i < 5; i++) {
      if (isDestMainEmpty[i]) {
        return true;
      }
    }
    return false;
  };
  const otherCustomerNameValidation = () => {
    if (showCustomerName) {
      if (shellCustomerName === "") {
        return true;
      }
    }
    return false;
  };
  const isAnyEmptyFiled = () => {
    if (
      icscValidation() ||
      destinationValidation() ||
      otherCustomerNameValidation() ||
      !registrationNo ||
      !aircraftValue ||
      !customerValue ||
      !originvalue ||
      !departureDate
    ) {
      return true;
    }
    return false;
  };

  const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false);

  useEffect(() => {
    if (isAnyEmptyFiled()) {
      setIsAllFieldFilled(false);
    } else {
      setIsAllFieldFilled(true);
    }
  });

  const placeholderStyle = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        paddingLeft: 0,
      };
    },
  };
  const handleAircraftValue = (selectedOption) => {
    if (selectedOption) {
      setValue("aircrafttype", selectedOption.value);
      setAircratfValue(selectedOption);
    }
  };

  const handleCustomerListValue = (selectedOption) => {
    if (selectedOption) {
      setValue("shellcustomername", selectedOption.value);
      setCustomerType(selectedOption.Type);
      setCustomerValue(selectedOption);
    }
  };
  return (
    <div
      className={
        initialDataLoading
          ? "outer-contain-main container-opacity "
          : "outer-contain-main"
      }
    >
      {initialDataLoading && (
        <div className={fromAdmin ? "load-wrap admin" : "load-wrap"}>
          <div className="load">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="imagecontainer">
        <WizardIcon icon={first} text={languageSet.flight_n_route} />
        <div className="dash-style">
          <img className="dash-img" src={dashIcon} />
        </div>
        <WizardIcon
          icon={
            flightDetailsNoHighlight === true
              ? flightHighlight
              : flightNoHighlight
          }
          text={languageSet.flight_details}
        />
        <div className="dash-style">
          <img className="dash-img" src={dashIcon} />
        </div>
        <WizardIcon icon={third} text={languageSet.request_status} />
      </div>
      <div className="form-container">
        <div className="siteindicator">
          <img className="location" src={locationicon} />
          {userrole !== ICSC_ID && ICSC_SITE_ID !== siteDetails.siteid && (
            <label className="locationtext">
              {siteDetails.site_airport || ""},{siteDetails.site_country || ""}
            </label>
          )}
          <div className="select-width">
            {(userrole === ICSC_ID || ICSC_SITE_ID === siteDetails.siteid) && (
              <Select
                type="text"
                styles={placeholderStyle}
                ClearIndicator="true"
                classNamePrefix={
                  "react-select__control " +
                  (!ICSCValue && isSubmitBtnClicked ? "border-red" : "") +
                  " "
                }
                placeholder={languageSet.search_site_name}
                name="origin"
                isClearable="true"
                options={iCSCSitesFormatted}
                isLoading={true}
                components={{
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                  MenuList: ListVirtualization,
                }}
                onChange={setICSCValue}
              />
            )}
          </div>
        </div>

        <div className="outer-style">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col">
                <div className="md-form mt-0">
                  <label className="label-style">
                    {languageSet.flight_reg_number}
                  </label>
                  <input
                    type="text"
                    className={
                      "form-control input-custom shadow-none" +
                      " " +
                      (!registrationNo && isSubmitBtnClicked
                        ? "border-red"
                        : "")
                    }
                    value={registrationNo}
                    styles={placeholderStyle}
                    placeholder={languageSet.flight_reg_number}
                    name="registrationno"
                    disabled={initialDataLoading}
                    onChange={(evt) => {
                      setRegistrationNo(
                        evt.target.value.replace(/[^0-9a-z]/gi, "")
                      );
                    }}
                  />
                </div>
              </div>

              <div className="col">
                <div className="md-form mt-0">
                  <label className="label-style">
                    {languageSet.aircraft_type}
                  </label>
                  <Select
                    type="text"
                    ClearIndicator="true"
                    styles={placeholderStyle}
                    classNamePrefix={
                      "react-select__control " +
                      (!aircraftValue && isSubmitBtnClicked
                        ? "border-red"
                        : "") +
                      " "
                    }
                    placeholder={languageSet.pick_one}
                    isClearable="true"
                    isLoading={true}
                    isDisabled={initialDataLoading}
                    options={aircraftListFormatted}
                    name="aircrafttype"
                    components={{
                      DropdownIndicator: () => {
                        return (
                          <img
                            className="icon-search-size no-outline"
                            src={SearchIcon}
                          />
                        );
                      },
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null,
                      MenuList: ListVirtualization,
                    }}
                    onChange={handleAircraftValue}
                  />
                </div>
              </div>

              <div className="col">
                <div className="md-form mt-0">
                  <label className="label-style">
                    {languageSet.shell_customer_name}
                  </label>
                  <Select
                    type="text"
                    ClearIndicator="true"
                    styles={placeholderStyle}
                    classNamePrefix={
                      "react-select__control " +
                      (!aircraftValue && isSubmitBtnClicked
                        ? "border-red"
                        : "") +
                      " "
                    }
                    placeholder={languageSet.pick_one_or_choose_other}
                    name="shellcustomername"
                    ref={register}
                    isClearable="true"
                    isDisabled={initialDataLoading}
                    isLoading={true}
                    options={customerListFormatted}
                    components={{
                      DropdownIndicator: () => {
                        return (
                          <img
                            className="icon-search-size no-outline "
                            src={SearchIcon}
                          />
                        );
                      },
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null,
                      MenuList: ListVirtualization,
                    }}
                    onChange={handleCustomerListValue}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className={
                  showCustomerName === true ? "extra-field" : "gone-view"
                }
              >
                <input
                  type="text"
                  className={
                    "form-control input-custom shadow-none" +
                    " " +
                    (!shellCustomerName && isSubmitBtnClicked
                      ? "border-red"
                      : "")
                  }
                  placeholder={languageSet.shell_customer_name}
                  name="othername"
                  onChange={(evt) => {
                    setShellCustomerName(evt.target.value);
                  }}
                  ref={register}
                />
              </div>
            </div>
            <div className={showSanctionQuestion ? null : "hidden"}>
              <div className="EnduserAggrement">
                <label>{languageSet.designated_under_sanctions}</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="defaultInline1"
                  name="isenduserundersanction"
                  value="YES"
                  ref={register({
                    required: showSanctionQuestion ? true : false,
                  })}
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="defaultInline1">
                  {languageSet.yes}
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="defaultInline2"
                  value="NO"
                  name="isenduserundersanction"
                  ref={register({
                    required: showSanctionQuestion ? true : false,
                  })}
                  className="custom-control-input"
                />
                <label className="custom-control-label" for="defaultInline2">
                  {languageSet.no}
                </label>
              </div>
              <hr className="line-custom"></hr>
            </div>

            {destCounts.map((destCount, index) => (
              <MainDest
                index={index}
                destCountLength={destCounts.length}
                key={index}
                setOriginValue={setOriginValue}
                originvalue={originvalue}
                isSubmitBtnClicked={isSubmitBtnClicked}
                airportListFormatted={airportListFormatted}
                destinations={destinations}
                setDestinations={setDestinations}
                setDepartureDate={setDepartureDate}
                isDestMainEmpty={isDestMainEmpty}
                initialDataLoading={initialDataLoading}
                setDestmainISEmpty={setDestmainISEmpty}
                departureDate={departureDate}
                setDestXXI={setDestXXI}
                onClickPlus={() => handlePlus(destCounts.length + 1, index)}
                onClickMinus={() => handleMinus(destCounts.length)}
              />
            ))}

            <hr className="line-style"></hr>
            <div className={showNatureOfFlight ? "" : "gone-view"}>
              <label className="label-style">
                Please select all mandatory options
              </label>
              <div>
                <label className="label-style">
                  {languageSet.nature_of_flight}
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  value="1"
                  ref={register({
                    required: showNatureOfFlight ? true : false,
                  })}
                  className="custom-control-input"
                  onChange={() => {
                    handleSwitchView(cargoView);
                    setPassengerSelected(false);
                  }}
                  onClick={onChangeFlightDetailHighlight}
                  id="defaultInline3"
                  name="inlineDefaultRadiosExample"
                />
                <label className="custom-control-label" for="defaultInline3">
                  {languageSet.cargo}
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  className="custom-control-input"
                  value="2"
                  ref={register({
                    required: showNatureOfFlight ? true : false,
                  })}
                  onClick={onChangeFlightDetailHighlight}
                  id="defaultInline4"
                  name="inlineDefaultRadiosExample"
                  onChange={() => {
                    handleSwitchView(passengerView);
                    setPassengerSelected(true);
                  }}
                />
                <label className="custom-control-label" for="defaultInline4">
                  {languageSet.passenger}
                </label>
              </div>

              <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    className="custom-control-input"
                    value="3"
                    ref={register({
                      required: showNatureOfFlight ? true : false,
                    })}
                    onClick={onChangeFlightDetailHighlight}
                    id="defaultInline5"
                    name="inlineDefaultRadiosExample"
                    onChange={() => {
                      handleSwitchView(ferryView);
                      setPassengerSelected(false);
                    }}
                  />
                  <label className="custom-control-label" for="defaultInline5">
                    {languageSet.ferry}
                  </label>
              </div>
              {showQuestionStageWise === cargoView && (
                <CargoQuestions
                  register={register}
                  handleSubmit={handleSubmit}
                  setValue={handleSubmit}
                  setNatureOfFlightJson={setNatureOfFlightJson}
                  natureOfFlightJson={natureOfFlightJson}
                  showNatureOfFlight={showNatureOfFlight}
                />
              )}
              {showQuestionStageWise === passengerView && (
                <div className={showPassenger ? "outer" : "gone-view"}>
                  <div className="radio-que">
                    <label>{languageSet.passenger_sanctioned}</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      value="YES"
                      className="custom-control-input"
                      id="defaultInline8"
                      name="inlineDefaultRadios"
                      ref={register({
                        required:
                          showNatureOfFlight &&
                          natureOfFlightJson.isPassenger &&
                          showPassenger
                            ? true
                            : false,
                      })}
                      onChange={() => handleIsPassengerSanctioned(YES)}
                    />
                    <label
                      className="custom-control-label"
                      for="defaultInline8"
                    >
                      {languageSet.yes}
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      className="custom-control-input"
                      value="NO"
                      id="defaultInline9"
                      name="inlineDefaultRadios"
                      ref={register({
                        required:
                          showNatureOfFlight &&
                          natureOfFlightJson.isPassenger &&
                          showPassenger
                            ? true
                            : false,
                      })}
                      onChange={() => handleIsPassengerSanctioned(NO)}
                    />
                    <label
                      className="custom-control-label"
                      for="defaultInline9"
                    >
                      {languageSet.no}
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      value="unknown"
                      className="custom-control-input"
                      id="defaultInline10"
                      name="inlineDefaultRadios"
                      ref={register({
                        required:
                          showNatureOfFlight &&
                          natureOfFlightJson.isPassenger &&
                          showPassenger
                            ? true
                            : false,
                      })}
                      onChange={() => handleIsPassengerSanctioned(DONT_KNOW)}
                    />
                    <label
                      className="custom-control-label"
                      for="defaultInline10"
                    >
                      {languageSet.destination_not_known}
                    </label>
                  </div>
                </div>
              )}
              <hr className="line-style"></hr>
            </div>
            <div
              className={
                "form-check" +
                " " +
                (!isConfirm && isSubmitBtnClicked ? "error" : "normal")
              }
            >
              <input
                type="checkbox"
                className="form-check-input input-custom"
                id="exampleCheck1"
                name="finalconfirm"
                disabled={initialDataLoading}
                onChange={() => {
                  setConfirm(!isConfirm);
                }}
                ref={register({
                  required: true,
                })}
              />
              <label className="check-label">{languageSet.confirm_info}</label>
            </div>
            {!isAllFieldFilled && isSubmitBtnClicked && (
              <p className="error_msg">
                {languageSet.mandatory_fields_to_be_filled}
              </p>
            )}
            <div className="btn-custom">
              <button
                type="submit"
                className=" btn btn-primary shadow-none submit-button"
                onClick={() => {
                  setIsSubmitBtnClicked(true);
                }}
                disabled={loading || initialDataLoading}
              >
                {loading && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {languageSet.submit}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ApplicationPendingAlert
        flag={pendingAlert}
        setPendingAlert={setPendingAlert}
        handleSubmitApi={handleSubmitApi}
      />

      <ApplicationRejectionAlert
        flag={rejectAlert}
        setRejectAlert={setRejectAlert}
        handleSubmitApi={handleSubmitApi}
      />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestQuestionnaireValidation: (data) =>
      dispatch(initQuestionnaireValidations(data)),
    requestQuestionnaireSubmit: (data) =>
      dispatch(initQuestionnaireSubmit(data)),
    requestQuestionnaireReset: () => dispatch(resetQuestionnaireSubmit()),
    requestQuestionnaireValidationReset: () =>
      dispatch(resetQuestionnaireValidation()),
    requestFuelerInitialData: (data) => dispatch(initFuelerInitialData(data)),
    requestAdminApprovedView: (notification) =>
      dispatch(displayAdminApproved(notification)),
    requestAdminPendingRejectView: (notification) =>
      dispatch(displayAdminRejectPending(notification)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FuelerWizard));
