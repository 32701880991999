import React from "react";
import "./fuelerreset.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
const FuelerResetBanner = props => {
  const { languageSet} = props.localization
  return (
    <div>
      <div className="card custom-card">
        <div className="card-body card-text">
          <p>{languageSet.contactadmin_resetpw}</p>
        </div>
        </div>
        <div className="link-btn">
          <a href="/">{languageSet.logging}</a>
        </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FuelerResetBanner));
