import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Icon_Eye from "../../../assets/icon_eye.png";
import "./login.css";
import {
  initLogin,
  loginReset,
  healthCheckReset,
  initHealthCheck,
  initFuelerInitialData,
  localizationSet,
} from "../../../store/actions/";
import { useTranslation } from "react-i18next";

import {
  USER_NOT_FOUND,
  SECURITY_RESET,
  SIGN_UP,
  ACCOUNT_LOCK,
  ACCOUNT_LOCK_CONTACT_ADMIN,
} from "../../../common/constants";
const Login = (props) => {
  const { t } = useTranslation();
  const { error, success, loginLoading } = props.login;
  const {
    healthCheckError,
    userRole /*, loadingHealthcheck */,
    loadingForgotPassword,
  } = props.healthCheck;
  const { successLoad } = props.fuelerInitialData;

  const [userName, setUserName] = useState("");
  const [userPassword, setuserPassword] = useState("");
  const [emptyUserName, setEmptyUserName] = useState(false); //empty user name
  const [emptyUserPassword, setemptyUserPassword] = useState(false); //empty user password
  const [usernameBorder, setUserNameBorder] = useState(false);
  const [passwordBorder, setPasswordBorder] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [onClickEye, setOnClickEye] = useState(false);
  const { languageSet, language } = props.localization;

  useEffect(() => {
    props.requestSetLocalization();
  }, [language]);

  useEffect(() => {
    if (error === USER_NOT_FOUND) {
      setUserNameBorder(true);
    }
    if (error === SIGN_UP) {
      setUserNameBorder(true);
    }
    if (error === ACCOUNT_LOCK || error === ACCOUNT_LOCK_CONTACT_ADMIN) {
      setPasswordBorder(true);
    }

    if (error === SECURITY_RESET) {
      setPasswordReset(true);
    }
  });
  useEffect(() => {
    if (success) {
      props.requestHealthCheck({ userName: userName, type: "login" });
    }
  }, [success]);

  useEffect(() => {
    if (userRole) {
      switch (userRole) {
        case 1:
          props.history.push("/approver");
          break;
        case 2:
          props.history.push("/admin");
          break;
        case 3:
        case 4:
          props.history.push("/home");
          break;

        default:
          return;
      }

      if (userRole === 3 || userRole === 4) props.history.push("/home/");
    }
  }, [userRole]);

  const handleEyeClick = () => {
    setOnClickEye(!onClickEye);
  };

  const onLogin = () => {
    if (!userName) {
      setEmptyUserName(true);
    } else if (!userPassword) {
      setemptyUserPassword(true);
    } else {
      //If userName and password is not empty attempt login api
      const data = { userName: userName, password: userPassword };
      props.requestLogin({ data, navigation: props.history });
    }
  };
  const onForgotPassword = () => {
    if (!userName) {
      setEmptyUserName(true);
    } else {
      //If userName and password is not empty attempt login api
      const data = { userName: userName, type: "forgotPassword" };
      props.requestHealthCheck(data);
    }
  };

  const passwordErrors = (error) => {
    try {
      if (passwordReset)
        return <p className="error_msg">{languageSet.security_reset}</p>;

      let wrong_attempts = error
        .toString()
        .split(" ")
        .splice(-1);

      if (wrong_attempts[0] === "1") {
        return (
          <p className="error_msg two-line-ellipsis">
            {languageSet.account_lock_after_1_attempt}
          </p>
        );
      } else if (wrong_attempts[0] === "2") {
        return (
          <p className="error_msg two-line-ellipsis">
            {languageSet.account_lock_after_2_attempts}
          </p>
        );
      } else if (
        error === ACCOUNT_LOCK ||
        error === ACCOUNT_LOCK_CONTACT_ADMIN
      ) {
        return <p className="error_msg">{languageSet.account_locked}</p>;
      } else if (error === SIGN_UP) {
        return <p className="error_msg">{languageSet.signuperror}</p>;
      }
    } catch (e) {}
  };
  return (
    <div className="login-content">
      <div className="line-break bs-linebreak"></div>
      <label className="label-style">{languageSet.user_name}</label>
      <div className="input-group input-group-addon">
        <input
          value={userName}
          onChange={(evt) => {
            setUserName(evt.target.value);
            setEmptyUserName(false);
            setemptyUserPassword(false);
            setUserNameBorder(false);
            setPasswordBorder(false);
            props.requestLoginReset();
            props.requestHealthCheckReset();
          }}
          type="text"
          className={
            "form-control input-group-addon" +
            " " +
            (emptyUserName ? "border-red" : "")
          }
          aria-label="Text input with checkbox"
        />
      </div>
      {emptyUserName && (
        <p className="error_msg">{languageSet.enter_username}</p>
      )}
      {(error === USER_NOT_FOUND || healthCheckError === "userNotFound") && (
        <p className="error_msg">{languageSet.user_not_exist}</p>
      )}
      <div className="line-break bs-linebreak"></div>
      <label className="label-style">{languageSet.password}</label>
      <div className="input-group">
        <input
          value={userPassword}
          onChange={(evt) => {
            setuserPassword(evt.target.value);
            setEmptyUserName(false);
            setemptyUserPassword(false);
            setUserNameBorder(false);
            setPasswordBorder(false);
            props.requestLoginReset();
            props.requestHealthCheckReset();
          }}
          type={!onClickEye ? "password" : "text"}
          className={
            "form-control input-group-addon input-group-pwd no-outline" +
            " " +
            (emptyUserPassword || passwordBorder ? "border-red" : "")
          }
          aria-label="Text input with checkbox"
        />
        <div className="input-group-append">
          <div
            className={
              "input-group-text bg-white" +
              " " +
              (emptyUserPassword || passwordBorder ? "border-red" : "")
            }
          >
            <input
              className="icon-eye-size no-outline"
              onClick={handleEyeClick}
              type="image"
              src={Icon_Eye}
              aria-label="Checkbox for following text input"
            />
          </div>
        </div>
      </div>

      {emptyUserPassword && <p className="error_msg">{languageSet.pw_enter}</p>}
      {passwordErrors(error)}
      <div className="custom-buttom-style">
        <button
          type="login"
          className="btn btn-primary shadow-none login-button uppercase"
          onClick={onLogin}
          disabled={
            loginLoading || loadingForgotPassword /*|| loadingHealthcheck*/
          }
        >
          {loginLoading /*|| loadingHealthcheck*/ && (
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {languageSet.logging}
        </button>
        <div
          className="link-button"
          style={{ display: "flex", alignSelf: "center" }}
        >
          <button className="btn btn-link uppercase" onClick={onForgotPassword}>
            {loadingForgotPassword && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {languageSet.forgot_password}
          </button>
        </div>
      </div>
    </div>
  );
};

// Listens to changes in store
const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestLogin: (login) => dispatch(initLogin(login)),
    requestLoginReset: (login) => dispatch(loginReset(login)),
    requestHealthCheck: (healthCheck) => dispatch(initHealthCheck(healthCheck)),
    requestHealthCheckReset: (healthCheck) =>
      dispatch(healthCheckReset(healthCheck)),
    requestFuelerInitalData: (data) => dispatch(initFuelerInitialData(data)),
    requestSetLocalization: () => dispatch(localizationSet()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
