import React from "react";
import "./fuelerreset.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { switchTab } from "../../store/actions";
import correct_logo from "../../assets/symbol_correct.png";

const AdminResetBanner = (props) => {
  const onLoginAgain = () => {
    props.requestSwitchLoginTab("");
  };
  const { languageSet } = props.localization;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="card custom-card">
        <div className="card-body card-text">
          <img className="img_logo-custom" src={correct_logo} />
          <label className="success-pass-style">
            New password created successfully,
            <br />
            Login in again using the new password
          </label>
        </div>
      </div>
      <div className="custom-buttom-styling">
        <button
          type="loginagain"
          className="btn btn-primary shadow-none login-button"
          onClick={onLoginAgain}
        >
          {languageSet.logging}
        </button>
      </div>
    </div>
  );
};

// Listens to changes in store
const mapStateToProps = (state) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestSwitchLoginTab: (tab) => dispatch(switchTab(tab)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminResetBanner));
