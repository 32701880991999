import React, { Suspense } from "react";
import Home from "./home/home";

import "../assets/i18n/i18next";

function App() {
  return (
    <div>
      <Suspense fallback={null}>
        <Home />
      </Suspense>
    </div>
  );
}

export default App;
