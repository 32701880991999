import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "../../screens/approver/approver-dashboard.css";
import ShellPecten from "../../assets/shell_pection.png";
import AdminWizard from "../../components/admin-dashboard/admin-wizard";
import * as constantTypes from "../../common/constants";
import * as actionTypes from "../../store/actions/action_types";
import AdminApplicationWizard from "../../screens/fueler/fueler-dashboard";
import NotificationPopover from "../../components/header/notification-popover";
import Approved from "../../screens/fueler/application-status-approved/application-status-approved";
import PendingRejected from "../../screens/fueler/application-status-pending-rejected/application-status-pending-rejected";
import {
  displayReports,
  displayAdminAccounts,
  getReportsPage,
  initsUserDetails,
} from "../../store/actions";

import AccountsHighlighted from "../../assets/accounts_highlighted.png";
import AdminAccounts from "../../components/admin-dashboard/admin-accounts/admin-accounts";
import AdminAccountsUserDetails from "../../components/admin-dashboard/admin-account-user-details/admin-accounts-user-details";
import ApproverReportsScreen from "../../components/approver-dashboard/approver-reports/approver-reports-screen";
import ApproverReportsSuccessScreen from "../../components/approver-dashboard/approver-reports/approver-reports-success-screen";

const AdminDashboard = (props) => {
  const { screenToDisplay, payloadNotification } = props.adminAccounts; //newAccountSetup

  useEffect(() => {
    props.requestUserDetails();
  }, []);
  return (
    <div>
      <div className="main-parent shadow">
        <div className="flex-container" style={{ width: "100%" }}>
          <div className="leftcontainer">
            <div className="ShellPecten">
              <img className="logo" src={ShellPecten} />
            </div>
            <div className="logotext">
              <label className="flight-title">FLIGHT</label>
              <label className="release-title">RELEASE</label>
            </div>
          </div>
          <div className="right-container">
            <div className="notification">
              <NotificationPopover />
            </div>
            <div className="username-role">
              <label style={{ fontSize: "14px" }}>
                Hi, {localStorage.getItem("nWDF18M")}
              </label>
              <label style={{ color: "##595959", fontSize: "12px" }}>
                Admin
              </label>
            </div>
            <div>
              <img className="user-acnts" src={AccountsHighlighted} />
            </div>
          </div>
        </div>
      </div>
      <div className="parent-container">
        <div className="panel1 shadow">
          <AdminWizard />
        </div>
        <div className="panel2">
          {(() => {
            switch (screenToDisplay) {
              case constantTypes.ADMIN_APPLICATION_DEFAULT_SCREEN:
                return <AdminApplicationWizard fromAdmin={true} />;
              case constantTypes.ADMIN_APPLICATION_SCREEN:
                return <AdminApplicationWizard fromAdmin={true} />;
              case constantTypes.ADMIN_ACCOUNTS_SCREEN:
                return <AdminAccounts />;
              case constantTypes.ADMIN_ACCOUNTS_SETUP_SCREEN:
                return <AdminAccountsUserDetails />;
              case constantTypes.REPORTS_SCREEN:
                return <ApproverReportsScreen />;
              case constantTypes.REPORTS_SUCCESS_SCREEN:
                return <ApproverReportsSuccessScreen />;
              case actionTypes.ADMIN_APPLICATION_REJECT_PENDING:
                return <PendingRejected notification={payloadNotification} />;
              case actionTypes.ADMIN_APPLICATION_APPROVE:
                return <Approved notification={payloadNotification} />;
            }
          })()}
        </div>
      </div>
    </div>
  );
};
// This triggers an action
const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestApproverReqPage: (approverReq) =>
      dispatch(displayReports(approverReq)),
    requestAdminAccountsPage: (adminAccounts) =>
      dispatch(displayAdminAccounts(adminAccounts)),
    requestReportsPage: (approverReports) =>
      dispatch(getReportsPage(approverReports)),
    requestUserDetails: () => dispatch(initsUserDetails()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminDashboard));
