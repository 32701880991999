//Screen names
export const APPROVER_REQUEST_DEFAULT_SCREEN =
  "DISPLAY_APPROVER_REQUEST_DEFAULT_SCREEN";
export const APPROVER_REQUEST_SCREEN = "DISPLAY_APPROVER_REQUEST_LIST_SCREEN";
export const APPROVER_DETAIL_SCREEN = "DISPLAY_APPROVER_REQUEST_DETAIL_SCREEN";
export const APPROVER_ACTION_TAKEN_SCREEN = "APPROVER_ACTION_TAKEN";
export const APPROVER_ACCOUNTS_SCREEN = "APPROVER_ACCOUNTS_SCREEN";
export const ACCOUNTS_SETUP_SCREEN = "ACCOUNTS_SETUP_SCREEN";
export const REPORTS_SCREEN = "REPORTS_SCREEN";
export const APPROVER_BROADCAST_SCREEN = "APPROVER_BROADCAST_SCREEN";

//user types
export const FUELER = "fueler";
export const ADMIN = "admin";
export const APPROVER = "approver";
export const ICSC = "icsc";

export const APPROVER_ID = "1";
export const ADMIN_ID = "2";
export const FUELER_ID = "3";
export const ICSC_ID = "4";

export const ICSC_SITE_ID = parseInt(process.env.REACT_APP_SITEID);

//class types
export const ACCOUNTS_CLICK_STYLE = "ACCOUNTS_CLICK_STYLE";
export const REQUESTS_CLICK_STYLE = "REQUESTS_CLICK_STYLE";
export const REPORTS_CLICK_STYLE = "REPORTS_CLICK_STYLE";
export const BROADCAST_CLICK_STYLE = "BROADCAST_CLICK_STYLE";

export const REPORTS_SUCCESS_SCREEN = "REPORTS_SUCCESS_SCREEN";

//broadcast message creation screen
export const BROADCAST_SETUP_SCREEN = "BROADCAST_SETUP_SCREEN";

//accounts screen admin
export const ADMIN_ACCOUNTS_SCREEN = "ADMIN_ACCOUNTS_SCREEN";

//application screen admin
export const ADMIN_APPLICATION_DEFAULT_SCREEN =
  "ADMIN_APPLICATION_DEFAULT_SCREEN";
export const ADMIN_APPLICATION_SCREEN = "ADMIN_APPLICATION_SCREEN";
export const ADMIN_ACCOUNTS_SETUP_SCREEN = "ADMIN_ACCOUNTS_SETUP_SCREEN";

//String constants
export const SITE_NAME_CONFLICT_MSG = "duplicate";

//Login
export const USER_NOT_FOUND = "USER_NOT_FOUND";
export const SECURITY_RESET = "SECURITY_RESET";
export const SIGN_UP = "SIGNUP";
export const ACCOUNT_LOCK = "ACCOUNT_LOCK";
export const ACCOUNT_LOCK_CONTACT_ADMIN =
  "User is locked, please contact the administrator";

export const TC_COUNTRIES_CC = "TC_COUNTRIES_CC";

export const TC_COUNTRIES_ALL = "TC_COUNTRIES_ALL";

export const TC_CC_REGION = "TC_CC_REGION";

export const REJECT_CUBA = "isDestinationCountryCuba";

export const YES = "Yes";
export const NO = "No";
export const DONT_KNOW = "Don’t Know";
export const SHELL_CUSTOMER = "Shell Customer";

export const AUTH_KEY = "auth_key";
export const REFRESH_KEY = "refresh_key";
export const XXI = "XXI";
