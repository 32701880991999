import { put, all } from "redux-saga/effects";
import * as actions from "../actions";
import { getUserDetailsRequest } from "./API";
import * as actionTypes from "../actions/action_types";

export function* userDetails(action) {
  try {
    const response = yield getUserDetailsRequest();

    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        yield put(actions.userDetailsSuccess(responseData));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh("", actionTypes.GET_USER_DETAILS_INIT)
            );
          } else {
            yield put(actions.notificationListFail("UnAuthorized access"));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("unknown error:" + e));
  }
}
