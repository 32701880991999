import * as actionTypes from "../../action_types";

//Send broadcast
export const broadcastSendInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_INIT,
  };
};

export const broadcastSendSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_SUCCESS,
  };
};

export const broadcastSendFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_FAIL,
  };
};

export const broadcastSendReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_RESET,
  };
};
