import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { passwordResetRequest } from "./API";

export function* signUp(action) {
  const data = {
    username: action.payload.userName.toLowerCase(),
  };
  try {
    if (action.payload.isNonFueler) {
      const passReset = yield passwordResetRequest(data);
      if (passReset.status === 200) {
        yield put(
          actions.switchTab({
            tab: action.payload.tab,
            userName: data.username,
          })
        );
      } else {
        yield put(actions.healthCheckFail("errorInPasswordReset"));
      }
    } else {
      yield put(
        actions.healthCheckSuccessSignUp({
          isNewUser: action.payload.isNewUser,
          userRole: action.payload.userRole,
        })
      );
    }
  } catch (e) {
    yield put(actions.healthCheckFail("userNotFound"));
  }
}
