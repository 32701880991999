import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from "./navigation";
import * as actionTypes from "./store/actions";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, combineReducers } from "redux";
import ErrorBoundary from "./errorBoundary";
import SnackBarView from "./components/snackbar/snackbar";
import "./web.config";
import {
  loginSaga,
  healthCheckSaga,
  passwordResetAdminApproverSaga,
  fuelerInitialDataSaga,
  questionnaireValidationSaga,
  questionnaireSubmitSaga,
  notficationListSaga,
  notficationListBadgeSaga,
  approverequestListSaga,
  apprroverRResponseSaga,
  logoutSaga,
  approverAccountAirportListSaga,
  approverAccountSiteListSaga,
  localizationSaga,
  approverSiteCreateSaga,
  approverUserCreateSaga,
  dashBoardCountSaga,
  approverUserSearchSaga,
  approverUserEditSaga,
  approverUserDeleteSaga,
  reportsSaga,
  broadcastRequestSaga,
  broadcastCountriesSaga,
  approverSiteEditSaga,
  userDetailsSaga,
  fuelerPassResetSaga,
  deleteSiteSaga,
  tcGetCountriesSaga,
  tcGetPDFSaga,
  tcSignUpSaga,
  refreshTokenSaga,
} from "../src/store/sagas";
import {
  reducerLogin,
  reducerSignup,
  reducerHealthCheck,
  reducerSwitchLoginTabs,
  reducerAdminApproverpassReset,
  reducerFuelerInitialData,
  reducerQuestionnaireValidation,
  reducerQuestionnaireSubmit,
  reducerNotificationList,
  reducerNotificationBadge,
  reducerApproverRequest,
  reducerLogout,
  reducerApproverAccounts,
  reducerLanguageLocalization,
  reducerApproverDashboardCount,
  reducerSnackBar,
  reducerApproverTabSwitch,
  reducerAccountEdit,
  reducerReports,
  reducerReportsData,
  reducerApproverBroadcast,
  reducerBroadcastCountries,
  reducerAccountsData,
  reducerBroadcast,
  reducerAdminAccounts,
  reducerApproverSiteUpdate,
  reducerUserDetails,
  reducerFuelerPassReset,
  reducerDeleteSite,
  reducerTermsConditions,
  reducerTermsConditionsPdf,
} from "../src/store/reducers";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const appReducer = combineReducers({
  login: reducerLogin,
  signup: reducerSignup,
  healthCheck: reducerHealthCheck,
  switchLoginTabs: reducerSwitchLoginTabs,
  adminApproverPasswordReset: reducerAdminApproverpassReset,
  fuelerInitialData: reducerFuelerInitialData,
  questionnaireValidation: reducerQuestionnaireValidation,
  questionnaireSubmit: reducerQuestionnaireSubmit,
  notificationList: reducerNotificationList,
  notificationBadge: reducerNotificationBadge,
  approverRequest: reducerApproverRequest,
  logout: reducerLogout,
  approverAccount: reducerApproverAccounts,
  approverAccountsData: reducerAccountsData,
  localization: reducerLanguageLocalization,
  dashboardCount: reducerApproverDashboardCount,
  snackMessage: reducerSnackBar,
  approverTabState: reducerApproverTabSwitch,
  approverAccountEdit: reducerAccountEdit,
  approverReports: reducerReports,
  reportsData: reducerReportsData,
  approverBroadcast: reducerApproverBroadcast,
  broadcastCountries: reducerBroadcastCountries,
  broadcastData: reducerBroadcast,
  adminAccounts: reducerAdminAccounts,
  approverSiteUpdateData: reducerApproverSiteUpdate,
  userDetails: reducerUserDetails,
  fuelerPassReset: reducerFuelerPassReset,
  deleteSite: reducerDeleteSite,
  termsConditions: reducerTermsConditions,
  termsConditionsPdf: reducerTermsConditionsPdf,
});

const rootReducer = (state, action) => {
  //Resetting all state to initial state
  if (action.type === actionTypes.LOGOUT_RESET) {
    localStorage.clear();
    state = undefined;
  }

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  process.env.REACT_APP_ENV === "PROD" || process.env.REACT_APP_ENV === "QA"
    ? applyMiddleware(sagaMiddleware)
    : composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(healthCheckSaga);
sagaMiddleware.run(passwordResetAdminApproverSaga);
sagaMiddleware.run(fuelerInitialDataSaga);
sagaMiddleware.run(questionnaireValidationSaga);
sagaMiddleware.run(questionnaireSubmitSaga);
sagaMiddleware.run(notficationListSaga);
sagaMiddleware.run(approverequestListSaga);
sagaMiddleware.run(notficationListBadgeSaga);
sagaMiddleware.run(apprroverRResponseSaga);
sagaMiddleware.run(logoutSaga);
sagaMiddleware.run(approverAccountAirportListSaga);
sagaMiddleware.run(approverAccountSiteListSaga);
sagaMiddleware.run(localizationSaga);
sagaMiddleware.run(approverSiteCreateSaga);
sagaMiddleware.run(approverUserCreateSaga);
sagaMiddleware.run(dashBoardCountSaga);
sagaMiddleware.run(approverUserSearchSaga);
sagaMiddleware.run(approverUserEditSaga);
sagaMiddleware.run(approverUserDeleteSaga);
sagaMiddleware.run(reportsSaga);
sagaMiddleware.run(broadcastRequestSaga);
sagaMiddleware.run(broadcastCountriesSaga);
sagaMiddleware.run(approverSiteEditSaga);
sagaMiddleware.run(userDetailsSaga);
sagaMiddleware.run(fuelerPassResetSaga);
sagaMiddleware.run(deleteSiteSaga);
sagaMiddleware.run(tcGetCountriesSaga);
sagaMiddleware.run(tcGetPDFSaga);
sagaMiddleware.run(tcSignUpSaga);
sagaMiddleware.run(refreshTokenSaga);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <Navigation />
      </ErrorBoundary>
      <SnackBarView />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
