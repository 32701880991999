import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./application-rejection-alert.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
const ApplicationRejectionAlert = (props) => {
  const { flag } = props;
  const [showChoice, setShowChoice] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showYesStyle, setShowYesStyle] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [comment, setComment] = useState("");
  const { languageSet } = props.localization;
  const handleShowYesStyle = () => {
    if (showYesStyle) {
      setShowYesStyle(false);
    }
  };
  const handleShowNoStyle = () => {
    if (!showYesStyle) {
      setShowYesStyle(true);
    }
  };
  useEffect(() => {
    if (flag) {
      setShowChoice(true);
    }
  }, [flag]);
  const handleEnableComment = (value) => {
    if (value === "commentforapprover") {
      setEditComment(true);
    } else {
      setEditComment(false);
      setComment("");
    }
  };
  return (
    <React.Fragment>
      <Modal
        show={showChoice}
        onHide={() => {
          setShowChoice(false);
          props.setRejectAlert(false);
        }}
        animation={false}
      >
        <Modal.Body>
          APPLICATION REJECTION ALERT
          <div className="text custom-modal">
            {languageSet.rejection_detailed_alert}
          </div>
          <div></div>
          <div className="button-styling custom">
            <button
              type="login"
              className={
                !showYesStyle
                  ? "btn btn-primary yes-btn custom on-focus focus shadow-none"
                  : "btn btn-primary yes-btn custom non-focus no-focus shadow-none"
              }
              onClick={() => {
                setShowComment(true);
                setShowChoice(false);
              }}
              onClickCapture={handleShowYesStyle}
            >
              {languageSet.yes}
            </button>
            <button
              type="login"
              className={
                showYesStyle
                  ? "btn btn-primary no-btn custom on-focus focus shadow-none"
                  : "btn btn-primary no-btn custom non-focus no-focus shadow-none"
              }
              onClick={() => {
                setShowChoice(false);
                props.setRejectAlert(false);
              }}
              onClickCapture={handleShowNoStyle}
            >
              {languageSet.no}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showComment}
        onHide={() => {
          setShowComment(false);
          props.setRejectAlert(false);
        }}
        animation={false}
      >
        <Modal.Body>
          APPLICATION REJECTED ALERT
          <div className="text custom-style">
            {languageSet.approver_will_notified_rejected}
          </div>
          <div className="radio-style">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="defaultInline26"
                name="addcomment"
                value="commentforapprover"
                className="custom-control-input"
                onChange={() => handleEnableComment("commentforapprover")}
              />
              <label
                className="custom-control-label label-custom"
                for="defaultInline26"
              >
                {languageSet.add_comment}:
              </label>
            </div>
          </div>
          <div className="input-style-custom custom-style">
            <div className="input-group input-group-addon">
              <input
                placeholder="Type here. 500 characters max limit."
                value={comment}
                disabled={!editComment}
                type="text"
                className="form-control input-group-addon custom-input"
                aria-label="Text input with checkbox"
                onChange={(evt) => {
                  setComment(evt.target.value);
                }}
              />
            </div>
          </div>
          <div className="radio-style">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="defaultInline27"
                name="addcomment"
                value="nocomment"
                defaultChecked
                className="custom-control-input"
                onChange={() => {
                  handleEnableComment("nocomment");
                }}
              />
              <label
                className="custom-control-label label-custom"
                for="defaultInline27"
              >
                No thanks, {languageSet.submit_without_comment}.
              </label>
            </div>
          </div>
          <div className="button-styling">
            <button
              type="submit"
              className="btn btn-primary shadow-none submit-btn"
              disabled={editComment && comment === ""}
              onClick={() => {
                props.handleSubmitApi(comment);
                props.setRejectAlert(false);
                setShowChoice(false);
              }}
            >
              {languageSet.submit}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return { localization: state.localization };
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApplicationRejectionAlert));
