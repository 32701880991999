import * as actionTypes from "../actions";

const initialState = {
  payload: {},
};

export const reducerLogout = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT_INIT:
      return { ...state, payload: action.payload, loading: true, error: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, payload: "success", loading: false, error: false };

    case actionTypes.LOGOUT_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
