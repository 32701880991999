import * as actionTypes from "./action_types";

export const broadcastDataInit = (data) => {
  return {
    payload: data,
    type: actionTypes.BROADCAST_DATA_INIT,
  };
};

export const broadcastDataReset = (data) => {
  return {
    payload: data,
    type: actionTypes.BROADCAST_DATA_RESET,
  };
};
