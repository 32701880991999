import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { refreshApi } from "./API";
import * as actionTypes from "../actions/action_types";
import { AUTH_KEY, REFRESH_KEY } from "../../common/constants";
export function* refreshToken(action) {
  try {
    const response = yield refreshApi({ state: "test" });

    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        localStorage.setItem(AUTH_KEY, responseData.access_token);
        localStorage.setItem(REFRESH_KEY, responseData.refresh_token);
      
        switch (action.tag) {
          case actionTypes.QUSESTIONNAIRE_VALIDATON_INIT:
            yield put(actions.initQuestionnaireValidations(action.payload));
            break;
          case actionTypes.QUSESTIONNAIRE_SUBMIT_INIT:
            yield put(actions.initQuestionnaireSubmit(action.payload));
            break;

          case actionTypes.ADMIN_FUELER_PASSWORD_RESET_INIT:
            yield put(actions.initFuelerPassReset(action.payload));
            break;
          case actionTypes.APPRROVER_RESPONSE_INIT:
            yield put(actions.approverResponseInit(action.payload));
            break;

          case actionTypes.APPROVER_REQUEST_GET_LIST_START:
            yield put(actions.getApproverRequestListStop());
            yield put(actions.getApproverRequestListStart(action.payload));
            break;
          case actionTypes.APPROVER_USER_COUNT_INIT:
            yield put(actions.getApproverUserCountInit(action.payload));
            break;
          case actionTypes.APPROVER_ACCOUNTS_SITE_LIST_INIT:
            yield put(actions.approverAccountsSiteInit(action.payload));
            break;

          case actionTypes.APPROVER_SITE_CREATE_INIT:
            yield put(actions.siteCreateInit(action.payload));
            break;

          case actionTypes.APPROVER_USER_CREATE_INIT:
            yield put(actions.userCreateInit(action.payload));
            break;

          case actionTypes.APPROVER_USER_SEARCH_INIT:
            yield put(actions.userSearchInit(action.payload));
            break;

          case actionTypes.APPROVER_USER_EDIT_INIT:
            yield put(actions.approverUserEditInit(action.payload));
            break;

          case actionTypes.APPROVER_USER_DELETE_INIT:
            yield put(actions.approverUserDeleteInit(action.payload));
            break;

          case actionTypes.APPROVER_SITE_UPDATE_INIT:
            yield put(actions.siteUpdateInit(action.payload));
            break;

          case actionTypes.DELETE_SITE_INIT:
            yield put(actions.deleteSiteInit(action.payload));
            break;

          case actionTypes.APPROVER_REPORTS_INIT:
            yield put(actions.getApproverReportsInit(action.payload));
            break;

          case actionTypes.APPROVER_BROADCAST_GET_COUNTRY_INIT:
            yield put(actions.broadcastCountryInit(action.payload));
            break;

          case actionTypes.NOTIFICATIONLIST_INIT:
            yield put(actions.initNotificationList(action.count));
            break;

          case actionTypes.FUELER_INIT:
            yield put(actions.initFuelerInitialData());
            break;

          case actionTypes.GET_USER_DETAILS_INIT:
            yield put(actions.initsUserDetails());
            break;
        }

        break;

      default:
        yield put(
          actions.showErrorMessage("Refresh error code - " + response.status)
        );
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("Refresh Unknown error"));
  }
}
