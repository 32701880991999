import React from "react";
import "./approver-list.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getApproverRequestListStart,
  getApproverRequestListStop,
  displayDetails,
  displayReports,
} from "../../../../store/actions";
import { useEffect } from "react";
import { arrangeList, getDateLocal, getTimeLocal } from "./approver-list-util";
const ApproverList = (props) => {
  const { questionaireSubmitedList = [], loading, error } =
    props.approverRequest || {};

  //Group the list according to date in the format mm/dd
  var groupedList = arrangeList(questionaireSubmitedList);
  useEffect(() => {
    props.requestgetApproverRequestListStart();
    return () => {
      //This will execute every time the component unloads.
      props.requestApproverRequestListStop();
    };
  }, []);
  const handleSelect = (applicationItem) => {
    props.requestApproverDetails(applicationItem);
  };
  return (
    <div>
      <div className="requests-header">
        <label>REQUESTS</label>
      </div>
      <React.Fragment>
        {error && !loading && <label>{error}</label>}
        {loading && (
          <div
            className="spinner-border text-primary loader-position"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {!loading &&
          questionaireSubmitedList &&
          questionaireSubmitedList.length === 0 && (
            <div>
              <table className="table shadow">
                <thead>
                  <tr>
                    <th className="table-header empty">Time</th>
                    <th className="table-header empty">Application ID</th>
                    <th className="table-header empty">Site Name</th>
                    <th className="table-header empty">Status</th>
                  </tr>
                </thead>
              </table>
              <div className="no-requests">
                <label>
                  It’s a light day! :) <br />
                  <br />
                  No requests to take action on.
                </label>
              </div>
            </div>
          )}
        {!loading &&
          groupedList.map((item, index) => (
            <div className="list-container" key={index}>
              <div index={index}>
                <label className="date-design">
                  Date: {getDateLocal(item.value[0])}
                </label>
                <table className="table shadow">
                  {index === 0 && (
                    <thead>
                      <tr key={index}>
                        <th className="table-header">Time</th>
                        <th className="table-header">Application ID</th>
                        <th className="table-header">Site Name</th>
                        <th className="table-header">Status</th>
                      </tr>
                    </thead>
                  )}
                  {item.value.map((item, index) => (
                    <tbody key={index}>
                      <tr
                        key={index}
                        className="clickable-row"
                        onClick={() => handleSelect(item)}
                      >
                        <td>{getTimeLocal(item.created_on)}</td>
                        <td>{item.auditnumber}</td>
                        <td>{item.question_data[0].site}</td>
                        <td>{item.status_while_requesting}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          ))}
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestApproverReqPage: (approverReq) =>
      dispatch(displayReports(approverReq)),
    requestgetApproverRequestListStart: (req) =>
      dispatch(getApproverRequestListStart(req)),
    requestApproverRequestListStop: (req) =>
      dispatch(getApproverRequestListStop(req)),
    requestApproverDetails: (approverReq) =>
      dispatch(displayDetails(approverReq)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverList));
