import { put, delay } from "redux-saga/effects";
import * as actions from "../actions";
import { getApproverRequestList } from "./API";
import * as actionType from "../actions/action_types";

export function* approverRequest(action) {
  while (true) {
    try {
      const response = yield getApproverRequestList();
      switch (response.status) {
        case 200:
          const responseData = yield response.json();

          yield put(
            actions.getApproverRequestListSuccess(responseData.payload)
          );
          break;
        case 401:
          try {
            const responseJson = yield response.json();
            if (
              responseJson.error.detail == "Token is either invalid or expired"
            ) {
              yield put(
                actions.initRefresh(
                  action.payload,
                  actionType.APPROVER_REQUEST_GET_LIST_START
                )
              );
            } else {
              yield put(
                actions.getApproverRequestListFail("UnAuthorized access")
              );
            }
          } catch (e) {
            yield put(
              actions.showErrorMessage(
                "error code - " + response.status + "-" + e.stringify
              )
            );
          }

          break;
        default:
          yield put(actions.notificationListFail("unknown error"));
          break;
      }
      yield delay(10000);
    } catch (e) {
      yield put(actions.notificationListFail(e));
      break;
    }
  }
}
