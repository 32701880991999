import React, { useState, useEffect } from "react";
import Icon_Eye from "../../assets/icon_eye.png";
import "./adminreset.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  initAdminApproverPassReset,
  switchTab,
  resetTC,
  tcPdfReset,
  switchTabReset,
  adminApproverPassResetStateReset,
} from "../../store/actions";

const AdminReset = (props) => {
  const [username] = useState(props.userName);
  const [temporaryPassword, setTempPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emptyTempPassword, setemptyTempPassword] = useState(false);
  const [emptyNewPassword, setemptyNewPassword] = useState(false); //empty new password
  const [emptyConfPassword, setemptyConfPwd] = useState(false); //empty confirm password
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false); //confirm pasword field format validation
  const [invalidNewPassword, setInvalidNewPassword] = useState(false); //new pasword field format validation
  const [onClickEye, setOnClickEye] = useState(false); //new password
  const [EyeClick, setEyeClick] = useState(false); //temporary password
  const [showErrorMessages, setshowErrorMessages] = useState(true);
  const [newPwdBorder, setNewPwdBorder] = useState(false); // new password border
  const [confPwdBorder, setConfPwdBorder] = useState(false); // conf password border
  const [NewConfPwdMatch, setNewConfPwdMatch] = useState(false); // new and confirm password mismatch
  const { passwordReset, passwordResetError, passResetLoading } =
    props.adminApproverPasswordReset || {};
  useEffect(() => {
    if (passwordReset === "User password updated") {
      props.requestSwitchLoginTab({ tab: "bannerAdmin" });
      props.requestPasswordStateReset();
    }
  }, [passwordReset]);

  const onCancel = () => {
    props.requestTcPdfReset();
    props.requestResetTC();
    props.requestSwitchLoginTab("");
    props.requestSwitchTabReset("");
  };
  const handleEyeClick = () => {
    setOnClickEye(!onClickEye); //new password
  };
  const onEyeClick = () => {
    setEyeClick(!EyeClick); //temporary password
  };
  const onPasswordupdate = () => {
    if (temporaryPassword === "") {
      setemptyTempPassword(true);
    } else if (newPassword === "") {
      setemptyNewPassword(true);
    } else if (confirmPassword === "") {
      setemptyConfPwd(true);
    } else {
      const data = {
        username: username.toLowerCase(),
        temporaryPassword: temporaryPassword,
        newPassword: newPassword,
      };

      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#@*&!])[A-Za-z\d$#@*&!]{8,16}$/;
      if (
        passwordResetError === "Incorrect temporary password" &&
        showErrorMessages
      ) {
        setshowErrorMessages(true);
      } else if (reg.test(newPassword) === false) {
        setInvalidNewPassword(true);
      } else if (reg.test(confirmPassword) === false) {
        setInvalidConfirmPassword(true);
      } else if (confirmPassword != newPassword) {
        setNewConfPwdMatch(true);
      } else {
        setNewConfPwdMatch(false);
        props.requestPasswordReset({ data, navigation: props.history });
      }
      setshowErrorMessages(true);
    }
  };

  return (
    <div className="mainview">
      <div className="top_text">
        <p>
          To set password, <br></br>
          {props.languageSet.temp_pw}
        </p>
      </div>
      <label className="label-style">{props.languageSet.user_name}</label>
      <div className="input-group input-group-addon">
        <input
          value={username}
          readOnly="true"
          type="text"
          className="form-control input-group-addon"
          aria-label="Text input with checkbox"
        />
      </div>
      <div className="line-break bs-linebreak"></div>
      <label className="label-style">
        {props.languageSet.temporary_password}
      </label>
      <div className="input-group">
        <input
          value={temporaryPassword}
          onChange={(evt) => {
            setTempPassword(evt.target.value.trim());
            setshowErrorMessages(false);
            setemptyTempPassword(false);
          }}
          type={!EyeClick ? "password" : "text"}
          className={
            "form-control" +
            " " +
            (emptyTempPassword || passwordResetError ? "border-red" : "")
          }
          aria-label="Text input with checkbox"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append">
          <div
            className={
              "input-group-text bg-white" +
              " " +
              (passwordResetError || emptyTempPassword ? "border-red" : "")
            }
          >
            <input
              className="icon-eye-size no-outline"
              onClick={onEyeClick}
              type="image"
              src={Icon_Eye}
              aria-label="Checkbox for following text input"
            />
          </div>
        </div>
      </div>
      {emptyTempPassword && (
        <p className="error_msg">Please enter temporary password to proceed.</p>
      )}
      {passwordResetError === "Incorrect temporary password" &&
        showErrorMessages && (
          <p className="error_msg">
            The temporary password you entered is incorrect.
          </p>
        )}
      <div className="line-break bs-linebreak"></div>
      <label className="label-style">{props.languageSet.new_password}</label>
      <div className="input-group">
        <input
          value={newPassword}
          type={!onClickEye ? "password" : "text"}
          onChange={(evt) => {
            setNewPassword(evt.target.value);
            setInvalidNewPassword(false);
            setConfPwdBorder(false);
            setemptyNewPassword(false);
            setInvalidConfirmPassword(false);
          }}
          //TODO form-control input-group-addon input-group-pwd no-outline
          className={
            "form-control" +
            " " +
            (emptyNewPassword ||
            newPwdBorder ||
            (invalidNewPassword && !emptyNewPassword)
              ? "border-red"
              : "")
          }
          aria-label="Text input with checkbox"
        />
        <div className="input-group-append">
          <div
            className={
              "input-group-text bg-white" +
              " " +
              (emptyNewPassword ||
              newPwdBorder ||
              (invalidNewPassword && !emptyNewPassword)
                ? "border-red"
                : "")
            }
          >
            <input
              className="icon-eye-size no-outline"
              onClick={handleEyeClick}
              type="image"
              src={Icon_Eye}
              aria-label="Checkbox for following text input"
            />
          </div>
        </div>
      </div>

      {emptyNewPassword && (
        <p className="error_msg">Enter your new password to proceed.</p>
      )}
      {invalidNewPassword && !emptyNewPassword && (
        <p className="error_msg">
          {props.languageSet.invalid_pw_format}{" "}
          {props.languageSet.pw_validation}.
        </p>
      )}
      <div className="line-break bs-linebreak"></div>
      <label className="label-style">
        {props.languageSet.confirm_password}
      </label>
      <div className="input-group input-group-addon">
        <input
          value={confirmPassword}
          type="text"
          onChange={(evt) => {
            setConfirmPassword(evt.target.value);
            setemptyNewPassword(false);
            setNewPwdBorder(false);
            setInvalidConfirmPassword(false);
            setemptyConfPwd(false);
            setNewConfPwdMatch(false);
          }}
          className={
            "form-control no-outline" +
            " " +
            (emptyConfPassword || invalidConfirmPassword || NewConfPwdMatch
              ? "border-red"
              : "")
          }
        />
      </div>
      {emptyConfPassword && (
        <p className="error_msg">Enter confirm password to proceed.</p>
      )}
      {invalidConfirmPassword && !emptyConfPassword && (
        <p className="error_msg">
          {props.languageSet.invalid_pw_format}
          {props.languageSet.pw_validation}.
        </p>
      )}
      {NewConfPwdMatch && !invalidConfirmPassword && !invalidNewPassword && (
        <p className="error_msg">{props.languageSet.pw_mismatch}.</p>
      )}

      <div className="style-button">
        <button
          type="Save"
          className="btn btn-primary shadow-none save-signin-button"
          disabled={passResetLoading}
          onClick={onPasswordupdate}
        >
          {passResetLoading && (
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {props.languageSet.save}
        </button>
        <div className="link-button">
          <button className="btn btn-link" onClick={onCancel}>
            {props.languageSet.cancel}
          </button>
        </div>
      </div>
    </div>
  );
};

// Listens to changes in store
const mapStateToProps = (state) => {
  return {
    adminApproverPasswordReset: state.adminApproverPasswordReset,
    languageSet: state.localization.languageSet,
  };
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestPasswordReset: (adminApproverPasswordReset) =>
      dispatch(initAdminApproverPassReset(adminApproverPasswordReset)),
    requestSwitchLoginTab: (tab) => dispatch(switchTab(tab)),
    requestResetTC: () => dispatch(resetTC()),
    requestTcPdfReset: () => dispatch(tcPdfReset()),
    requestSwitchTabReset: () => dispatch(switchTabReset()),
    requestPasswordStateReset: () =>
      dispatch(adminApproverPassResetStateReset()),
  };
};
//
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminReset));
