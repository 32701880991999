import { put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../actions/action_types";
const getQuestionnairevalidation = async (data) => {
  const response = await fetch(
    process.env.REACT_APP_API_QUESTIONNAIRE_VALIDATION,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, OPTIONS",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + localStorage.getItem("auth_key"),
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};

// eslint-disable-next-line
export function* questionnaireValidation(action) {
  try {
    const response = yield getQuestionnairevalidation(action.payload);

    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        yield put(
          actions.questionnaireValidationsSuccess(responseData.payload)
        );
        break;
      case 400:
        yield put(actions.questionnaireValidationsFail("Bad request"));
        yield put(actions.showErrorMessage("Bad request"));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.QUSESTIONNAIRE_VALIDATON_INIT
              )
            );
          } else {
            yield put(
              actions.questionnaireValidationsFail("UnAuthorized access")
            );
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }
        break;
      case 500:
        yield put(
          actions.questionnaireValidationsFail("Internal server error")
        );
        yield put(actions.questionnaireValidationsFail("Bad request"));
        break;
      default:
        yield put(actions.questionnaireValidationsFail("unknown error"));
        yield put(actions.questionnaireValidationsFail("Bad request"));
        break;
    }
  } catch (e) {
    yield put(actions.questionnaireValidationsFail("unknown error"));
  }
}
