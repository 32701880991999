import { put } from "redux-saga/effects";
import * as actions from "../actions";
var shajs = require("sha.js");

const passResetRequest = async (data) => {
  const response = await fetch(process.env.REACT_APP_API_UPDATE_PASS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify(data),
  });

  return response;
};

export function* passwordResetAdminApprover(action) {
  const data = {
    username: action.data.username,
    temporaryPassword: action.data.temporaryPassword,
    newPassword: new shajs.sha256()
      .update(action.data.newPassword)
      .digest("hex"),
  };
  try {
    const response = yield passResetRequest(data);
    const response_data = yield response.text();
    if (response.status === 200) {
      yield put(actions.adminApproverPassResetSuccess(response_data));
    } else {
      yield put(actions.adminApproverPassResetFail(response_data));
    }
  } catch (e) {}
}
