import { put, all } from "redux-saga/effects";
import * as actions from "../actions";
import { ADMIN_ID, ICSC_ID } from "../../common/constants";
import * as actionTypes from "../actions/action_types";
import {
  getUserDetailsRequest,
  getAirportRequest,
  getAircraftRequest,
  getCustomerName,
  getSiteList,
} from "./API";

export function* fuelerInitialData(action) {
  var iCSCSites,
    iCSCList = {};

  try {
    const [airportList, aircraftList, customerList, userDetails] = yield all([
      getAirportRequest(),
      getAircraftRequest(),
      getCustomerName(),
      getUserDetailsRequest(),
    ]);

    if (
      airportList.status ||
      aircraftList.status ||
      customerList.status ||
      userDetails.status === 401
    ) {
      yield put(actions.initRefresh(action.payload, actionTypes.FUELER_INIT));
    }
    if (action.payload === ICSC_ID || action.payload === ADMIN_ID) {
      iCSCSites = yield getSiteList();
      iCSCList = yield iCSCSites.json();
    }
    const userDetailsData = yield userDetails.json();

    yield put(
      actions.fuelerInitialDataSuccess({
        success: true,
        airportList: airportList,
        aircraftList: aircraftList,
        customerList: customerList,
        userDetails: userDetailsData,
        iCSCSites: iCSCList,
      })
    );
  } catch (e) {
    yield put(actions.fuelerInitialDataFail(e));
  }
}
