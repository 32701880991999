import React, { useState, useEffect } from "react";
import "./user-site-view-details.css";
import { Modal, ModalBody } from "reactstrap";
import AlertsClose from "../../../../assets/alerts_close.png";
import SiteLocationHighlighted from "../../../../assets/site_location_highlighted.png";
import SiteDeleteDialog from "./site-delete-dialog";
import SiteEditDialog from "./site-edit-dialog";
const UserSiteViewDetails = (props) => {
  const [modalView, setModalView] = useState(false);
  const {
    setSiteModalEdit,
    siteModalEdit,
    siteModalDelete,
    setSiteModalDelete,
    searchCallSite,
    siteSearchString
  } = props;
  const [item, setItem] = useState();
  useEffect(() => {
    setItem(props.item);
  }, [props.item]);
  useEffect(() => {
    setModalView(props.showSiteDetails);
  }, [props.showSiteDetails]);
  const toggle = () => {
    setModalView(!modalView);
    props.setShowSiteDetails(false);
  };
  return (
    <div>
      <Modal isOpen={modalView} toggle={toggle} id="my-view-modal">
        <ModalBody>
          <div className="cross-btn">
            <img className="cross-img" src={AlertsClose} onClick={toggle} />
          </div>
          <div id="modal-title">
            <img className="profile-img" src={SiteLocationHighlighted} />
            <label className="name-label">{item && item.site_airport}</label>
          </div>
          <hr id="small-hr"></hr>
          <div id="modal-inner-body">
            <table>
              <tr>
                <td>Country</td>
                <td style={{ paddingRight: "1%" }}>:</td>
                <td>{item && item.site_country}</td>
              </tr>
              <br />
              <tr>
                <td>Airport</td>
                <td style={{ paddingLeft: "1%" }}>:</td>
                <td>{item && item.site_airport}</td>
              </tr>
              <br />
              <tr>
                <td style={{ width: "20%" }}>ITP Operating Company</td>
                <td style={{ paddingLeft: "1%" }}>:</td>
                <td>{item && item.itp_operating_company}</td>
              </tr>
              <br />
              <tr>
                <td>Site Name</td>
                <td style={{ paddingLeft: "1%" }}>:</td>
                <td>{item && item.site_name}</td>
              </tr>
            </table>
          </div>
          <div id="modal-footer">
            <button
              type="login"
              className="btn btn-primary edit-btn custom shadow-none"
              onClick={() => {
                setSiteModalEdit(true, item);
              }}
              onClickCapture={toggle}
            >
              EDIT
            </button>
            <button
              type="login"
              className="btn btn-primary delete-btn custom shadow-none"
              onClick={() => {
                setSiteModalDelete(true, item);
              }}
              onClickCapture={toggle}
            >
              DELETE
            </button>
          </div>
          <br />
        </ModalBody>
      </Modal>
      <SiteDeleteDialog
        setSiteModalDelete={setSiteModalDelete}
        siteModalDelete={siteModalDelete}
        item={item}
        siteSearchString={siteSearchString}
        searchCallSite={searchCallSite}
      />
      <SiteEditDialog
        siteModalEdit={siteModalEdit}
        setSiteModalEdit={setSiteModalEdit}
        item={item}
        siteSearchString={siteSearchString}
        searchCallSite={searchCallSite}
      />
    </div>
  );
};

export default UserSiteViewDetails;
