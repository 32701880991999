import * as actiontypes from "../actions";

const initialState = {
  userDetails: {},
};

export const reducerUserDetails = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.GET_USER_DETAILS_INIT:
      return { ...state, payload: action.payload };

    case actiontypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
      };

    case actiontypes.GET_USER_DETAILS_RESET:
      return {
        ...state,
        initialState,
      };
      break;

    default:
      return state;
  }
};
