import { put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../actions/action_types";
import {
  getAirportList,
  getSiteList,
  createSite,
  createUser,
  userSearch,
  updateUser,
  deleteUser,
  updateSites,
  deleteSiteApi,
} from "./API";

const extractCountryList = (responseData) => {
  var country = {},
    countryList = [];
  responseData.forEach((item) => {
    if (!(item.COUNTRY_CD === "Don't Know" || item.COUNTRY_CD === "XXI")) {
      var countryName = item.COUNTRY_NM + "," + item.COUNTRY_CD;
      country[item.COUNTRY_NM] = {
        label: countryName,
        value: countryName,
      };
    }
  });
  Object.keys(country).forEach(function(key) {
    var object = country[key];
    countryList.push(object);
  });
  countryList.sort(function(a, b) {
    if (a.country_name > b.country_name) {
      return 1;
    }
    if (b.country_name > a.country_name) {
      return -1;
    }
    return 0;
  });

  return countryList;
};

const extractAirportList = (responseData) => {
  var airportListFormatted = responseData.map((v) => ({
    value: `${v.LOCATION_IATA_CD + ", " + v.LOCATION_NM}`,
    label: `${v.LOCATION_IATA_CD + ", " + v.LOCATION_NM}`,
    LOCATION_NM: v.LOCATION_NM,
    COUNTRY_CD: v.COUNTRY_CD,
    COUNTRY_NM: v.COUNTRY_NM,
  }));
  return airportListFormatted;
};

const extractSiteList = (responseData) => {
  var icscSite;
  var siteListFormatted = [];
  responseData.forEach((v, index) => {
    if (!(v.site_name === "ICSC")) {
      siteListFormatted[index] = {
        value: v.siteid,
        label: `${v.site_name +
          "," +
          v.itp_operating_company +
          "," +
          v.site_country}`,
        createdOn: v.createdOn,
        createdBy: v.createdBy,
        itp_operating_company: v.itp_operating_company,
        site_airport: v.site_airport,
        site_country: v.site_country,
        site_name: v.site_name,
      };
    } else {
      siteListFormatted[index] = {
        value: v.siteid,
        label: `${v.site_name +
          "," +
          v.itp_operating_company +
          "," +
          v.site_country}`,
        createdOn: v.createdOn,
        createdBy: v.createdBy,
        itp_operating_company: v.itp_operating_company,
        site_airport: v.site_airport,
        site_country: v.site_country,
        site_name: v.site_name,
      };
      icscSite = v.siteid;
    }
  });

  return { siteList: siteListFormatted, icscSite: icscSite };
};
//Saga  airport list
export function* approverAccountAirportList(action) {
  try {
    const response = yield getAirportList();
    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        var country = extractCountryList(responseData);
        var airport = extractAirportList(responseData);
        yield put(
          actions.getApproverAccountsAirportSuccess({
            countryList: country,
            airportList: airport,
          })
        );
        break;
      case 401:
        yield put(actions.showErrorMessage("UnAuthorized access"));
        yield put(actions.getApproverAccountsAirportFail(true));
        break;
      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.getApproverAccountsAirportFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.getApproverAccountsAirportFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.getApproverAccountsAirportFail(e));
  }
}

//Saga  Site list
export function* approverAccountSiteList(action) {
  try {
    const response = yield getSiteList();
    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        var siteList = extractSiteList(responseData);
        yield put(actions.getApproverAccountsSiteSuccess(siteList));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_ACCOUNTS_SITE_LIST_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.getApproverAccountsSiteFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;
      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.getApproverAccountsSiteFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("Unknown Error"));
        yield put(actions.getApproverAccountsSiteFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.getApproverAccountsSiteFail(e));
  }
}

//Saga Site Create

export function* approverSiteCreate(action) {
  try {
    const response = yield createSite(action.payload);
    switch (response.status) {
      case 200:
        yield put(actions.getSiteCreateSuccess(true));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_SITE_CREATE_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("UnAuthorized access"));
            yield put(actions.getSiteCreateFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;
      case 409:
        yield put(actions.getSiteCreateFail("Site name already exists"));
        break;
      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.getSiteCreateFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.getSiteCreateFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.getSiteCreateFail("Unknown error"));
    yield put(actions.getSiteCreateFail(true));
  }
}

//Saga User Create

export function* approverUserCreate(action) {
  try {
    const response = yield createUser(action.payload);
    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        yield put(actions.getUserCreateSuccess(responseData));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_USER_CREATE_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("UnAuthorized access"));
            yield put(actions.getUserCreateFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;
      case 409:
        yield put(actions.getUserCreateFail("User name already  exists"));
        break;
      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.getUserCreateFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.getUserCreateFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
    put(actions.getUserCreateFail(true));
  }
}
//saga user search

export function* approverUserSearch(action) {
  try {
    const response = yield userSearch(action.payload);
    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        yield put(actions.userSearchSuccess(responseData));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_USER_SEARCH_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.userSearchFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;
      case 404:
        yield put(actions.userSearchFail("User name does not exist"));
        yield put(actions.userSearchFail(true));
        break;
      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.userSearchFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.userSearchFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
    yield put(actions.userSearchFail(true));
  }
}

//User update
export function* approverUserEdit(action) {
  try {
    const response = yield updateUser(action.payload);
    switch (response.status) {
      case 200:
        yield put(actions.showSuccessMessage("Edited Successfully"));
        yield put(actions.approverUserEditSuccess(true));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_USER_EDIT_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.approverUserEditFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;
      case 404:
        yield put(actions.userSearchFail("User name does not exist"));
        yield put(actions.approverUserEditFail(true));
        break;
      case 400:
        yield put(actions.approverUserEditFail("User name already  exists"));
        break;
      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.approverUserEditFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.approverUserEditFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
    yield put(actions.approverUserEditFail(true));
  }
}

//User delete
export function* approverUserDelete(action) {
  try {
    const response = yield deleteUser(action.payload);
    switch (response.status) {
      case 200:
        yield put(actions.showSuccessMessage("Deleted Successfully"));
        yield put(actions.approverUserDeleteSuccess(true));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_USER_DELETE_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.approverUserDeleteFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;
      case 404:
        yield put(actions.userSearchFail("User name does not exist"));
        yield put(actions.approverUserDeleteFail(true));
        break;
      case 409:
        yield put(actions.userSearchFail("User already deleted"));
        yield put(actions.approverUserDeleteFail(true));
        break;
      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.approverUserDeleteFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.approverUserDeleteFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
    yield put(actions.approverUserDeleteFail(true));
  }
}

export function* approverEditSite(action) {
  try {
    const response = yield updateSites(action.payload);
    switch (response.status) {
      case 200:
        yield put(actions.siteUpdateSuccess(true));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_SITE_UPDATE_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            const response_Json = yield response.json();
            yield put(actions.siteUpdateFail(response_Json.error.message));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;

      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));

        break;
      case 400:
        yield put(actions.showErrorMessage("Site name already exists"));
        yield put(actions.siteUpdateFail("duplicate"));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));

        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("unknown error:" + e));
  }
}
//Delete Site
export function* deleteSite(action) {
  try {
    const response = yield deleteSiteApi(action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.deleteSiteSuccess());
        break;

      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(action.payload, actionTypes.DELETE_SITE_INIT)
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.deleteSiteFail());
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;

      case 403:
        yield put(actions.showErrorMessage("Only Approver can delete site"));
        yield put(actions.deleteSiteFail());
        break;

      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.deleteSiteFail());
        break;

      case 400:
        yield put(actions.showErrorMessage("Bad request"));
        yield put(actions.deleteSiteFail());
        break;

      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.deleteSiteFail());

        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("unknown error:" + e));
    yield put(actions.deleteSiteFail());
  }
}
