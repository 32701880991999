import * as actionTypes from "./action_types";

export const resetQuestionnaireSubmit = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_SUBMIT_RESET
  };
};

export const initQuestionnaireSubmit = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_SUBMIT_INIT
  };
};

export const questionnaireSubmitSuccess = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_SUBMIT_SUCCESS
  };
};

export const questionnaireSubmitFail = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_SUBMIT_FAILURE
  };
};
