import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./admin-accounts-user-details.css";
import BackIcon from "../../../assets/back_icon.png";
import AccountsNewFuelerDetails from "./accounts-new-fueler-details";
import { displayAdminAccounts } from "../../../store/actions";

import AccountsNewAdminDetails from "./accounts-new-admin-details";

import SuccessAction from "./user-creation-successful";
import DropdownArrow from "../../../assets/dropdown-arrow.png";
import DropdownUp from "../../../assets/dropdown_up.png";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const AdminAccountsUserDetails = (props) => {
  const handleAccounts = () => {
    props.requestAccountsPage();
  };
  const [dropDownValue, setDropDownValue] = useState("ADMIN");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);
  const [siteCreation, setSiteCreation] = useState(false);
  const { site_name: siteName } =
    props.userDetails.userDetails.sitedetails || {};
  const { languageSet } = props.localization;
  const icsc = "ICSC";
  var items = [
    { label: "ADMIN", value: "ADMIN" },
    { label: "FUELER", value: "FUELER" },
  ];
  if (siteName === icsc) {
    items = [
      { label: "ADMIN", value: "ADMIN" },
      { label: "ICSC", value: "ICSC" },
    ];
  }

  const toggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSelect = (event) => {
    setSuccessScreen(false);
    setDropDownValue(event.target.innerText);
    setIsDropdownOpen(!isDropdownOpen);
  };
  const successAction = (value, site) => {
    setSuccessScreen(value);
    setSiteCreation(site);
  };
  return (
    <div className="parent-user-details-container">
      <div className="new-account-header">
        <div className="left-header">
          <label>{languageSet.accounts.toUpperCase()}</label>
          <label style={{ paddingLeft: "1vw" }}> {">"} </label>
          <div id="newaccount">
            <a
              style={{
                cursor: "pointer",
              }}
            >
              {languageSet.create_new_account}
            </a>
          </div>
        </div>
      </div>
      <div className="user-details-container shadow">
        <div className="new-acnt-holder">
          <div className="left-back-container">
            <img className="back-img" src={BackIcon} onClick={handleAccounts} />
          </div>
          <div className="acnt-heading-middle-container">
            <label>{languageSet.create_new_account}</label>
          </div>
        </div>
        <div className="account-type-container">
          <label>{languageSet.pick_account_type}</label>
          <Dropdown isOpen={isDropdownOpen} toggle={toggle} id="dropdown">
            <DropdownToggle id="dropdown-style">{dropDownValue}</DropdownToggle>
            <img
              id="dropdown_img"
              src={!isDropdownOpen ? DropdownArrow : DropdownUp}
              onClick={toggle}
            ></img>
            <DropdownMenu id="menu">
              {items.map(({ value, index }) => (
                <DropdownItem
                  onClick={handleSelect}
                  className="special"
                  key={index}
                  value={value}
                  id="dropdown-item"
                >
                  {value}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        {successScreen ? (
          <SuccessAction
            successAction={successAction}
            siteMessage={siteCreation}
            languageSet={languageSet}
          />
        ) : (
          (() => {
            switch (dropDownValue) {
              case "ADMIN":
                return (
                  <AccountsNewAdminDetails successAction={successAction} />
                );
              case "FUELER":
              case "ICSC":
                return (
                  <AccountsNewFuelerDetails
                    successAction={successAction}
                    siteName={siteName}
                  />
                );
            }
          })()
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { userDetails: state.userDetails, localization: state.localization };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestAccountsPage: (approverAccounts) =>
      dispatch(displayAdminAccounts(approverAccounts)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminAccountsUserDetails));
