import React from "react";
import { Route, Redirect } from "react-router-dom";
import { APPROVER_ID, ADMIN_ID, FUELER_ID, ICSC_ID } from "./common/constants";
const AuthenticatedRoute = ({ component: Component, ...others }) => {
  const authkey = localStorage.getItem("auth_key");
  const userType = localStorage.getItem("rWDKTId");
  const url = others.computedMatch.url;

  const redirectUrl = (props) => {
    switch (userType) {
      case APPROVER_ID:
        if (url.startsWith("/approver", 0)) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/approver",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      case ADMIN_ID:
        if (url.startsWith("/admin", 0)) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/admin",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      case FUELER_ID:
      case ICSC_ID:
        if (url.startsWith("/home", 0)) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/home",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      default:
        return (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location,
              },
            }}
          />
        );
    }
  };
  return (
    <Route
      {...others}
      render={(props) => {
        if (authkey) {
          let component = redirectUrl(props);
          return component;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default AuthenticatedRoute;
