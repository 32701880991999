import { getDateStandardFormat } from "../../../common/components/date-component";

const selectAll = "all";
const selectApproved = "approved";
const selectRejected = "rejected";
const selectPending = "pending";
var props;
export const initProps = (_props) => {
  props = _props;
};

export const getStatus = {
  get selectAll() {
    return selectAll;
  },
  get selectApproved() {
    return selectApproved;
  },
  get selectRejected() {
    return selectRejected;
  },
  get selectPending() {
    return selectPending;
  },
};

export const handleActiveStatus = (_status) => {
  var { reportDetails, statusReport } = props.reportsData;
  switch (_status) {
    case getStatus.selectAll:
      statusReport = {
        ...statusReport,
        isAll: true,
        isApproved: false,
        isRejected: false,
        isPending: false,
      };
      break;
    case getStatus.selectApproved:
      statusReport = {
        ...statusReport,
        isAll: false,
        isApproved: true,
        isRejected: false,
        isPending: false,
      };
      break;
    case getStatus.selectRejected:
      statusReport = {
        ...statusReport,
        isAll: false,
        isApproved: false,
        isRejected: true,
        isPending: false,
      };
      break;
    case getStatus.selectPending:
      statusReport = {
        ...statusReport,
        isAll: false,
        isApproved: false,
        isRejected: false,
        isPending: true,
      };
      break;
    default:
      statusReport = {
        ...statusReport,
        isAll: true,
        isApproved: false,
        isRejected: false,
        isPending: false,
      };
      break;
  }

  props.requestReportsData({
    ...reportDetails,
    status: _status,
    activeStatus: statusReport,
  });
};

export const handleReqReport = () => {
  var { reportDetails, statusReport } = props.reportsData;
  props.reportsRequest({
    ...reportDetails,
    startdate: getDateStandardFormat(reportDetails.startdate),
    enddate: getDateStandardFormat(reportDetails.enddate),
    activeStatus: statusReport,
  });
};

export const handleReportStartDate = (startDate) => {
  var { reportDetails, statusReport } = props.reportsData;
  props.requestReportsData({
    ...reportDetails,
    startdate: startDate,
    activeStatus: statusReport,
  });
};
export const handleReportEndDate = (endDate) => {
  var { reportDetails, statusReport } = props.reportsData;
  props.requestReportsData({
    ...reportDetails,
    enddate: endDate,
    activeStatus: statusReport,
  });
};
