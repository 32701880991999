import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { healthCheckRequest, passwordResetRequest } from "../sagas/API";
import {
  APPROVER_ID,
  ADMIN_ID,
  FUELER_ID,
  ICSC_ID,
} from "../../common/constants.js";
import CryptoJS from "crypto-js";

export function* healthCheck(action) {
  const data = {
    username: action.query.userName.toLowerCase(),
  };
  try {
    const response = yield healthCheckRequest(data);

    try {
      const responseString = yield response.text();
      let bytes = CryptoJS.AES.decrypt(
        responseString,
        process.env.REACT_APP_ENCRYPT_KEY
      );
      let originalText = bytes.toString(CryptoJS.enc.Utf8);
      const responseData = JSON.parse(originalText);
      if (response.status === 200) {
        if (action.query.type === "forgotPassword") {
          if (
            String(responseData[0].userroleid) === APPROVER_ID ||
            String(responseData[0].userroleid) === ADMIN_ID
          ) {
            if (responseData[0].isNewUser) {
              yield put(actions.loginFail("signUp"));
            } else {
              const passReset = yield passwordResetRequest(data);
              if (passReset.status === 200) {
                yield put(
                  actions.switchTab({
                    tab: "approverPasswordReset",
                    userName: data.username,
                  })
                );
                yield put(actions.healthCheckResetLoading());
              } else {
                yield put(actions.healthCheckFail("errorInPasswordReset"));
                yield put(actions.healthCheckResetLoading());
              }
            }
          } else {
            if (responseData[0].isNewUser) {
              yield put(actions.loginFail("signUp"));
            } else {
              if (responseData[0].isPasswordReset) {
                yield put(
                  actions.switchTab({
                    tab: "fuelerPasswordReset",
                    userName: data.username,
                  })
                );
              } else {
                yield put(actions.switchTab({ tab: "contactAdmin" }));
              }
            }
          }
        } else if (action.query.type === "signUp") {
          //Approver and Admin has similar signup screen
          if (
            responseData[0].userroleid == APPROVER_ID ||
            responseData[0].userroleid == ADMIN_ID
          ) {
            if (!responseData[0].isNewUser) {
              yield put(
                actions.healthCheckSuccessSignUp({
                  isNewUser: responseData[0].isNewUser,
                  userRole: "admin",
                })
              );
            } else {
              yield put(
                actions.initTcCountries(
                  {
                    type: "countries",
                    enabled: true,
                    userName: data.username,
                  },
                  {
                    tab: "approverPasswordReset",
                    userName: data.username,
                    isNonFueler: true,
                  }
                )
              );
              yield put(actions.healthCheckResetLoading());
            }
          } else {
            //Fueler and ICSC has similar signup screen
            if (!responseData[0].isNewUser) {
              yield put(
                actions.healthCheckSuccessSignUp({
                  isNewUser: responseData[0].isNewUser,
                  userRole: "fueler",
                })
              );
            } else {
              yield put(
                actions.initTcCountries(
                  {
                    type: "countries",
                    enabled: true,
                    userName: data.username,
                  },
                  {
                    isNewUser: responseData[0].isNewUser,
                    userRole: "fueler",
                    userName: data.username,
                  }
                )
              );
              yield put(actions.healthCheckResetLoading());
            }
          }
        } else if (action.query.type === "login") {
          localStorage.setItem("iIF7LEd", responseData[0].userid);
          localStorage.setItem("rWDKTId", responseData[0].userroleid);
          localStorage.setItem("nWDF18M", responseData[0].username);

          yield put(
            actions.healthCheckSuccessLogin({
              userRole: responseData[0].userroleid,
            })
          );
        }
      } else if (response.status === 401) {
        //call refresh token
        // yield put(actions.initRefresh());
      }
    } catch (e) {
      yield put(actions.healthCheckFail("userNotFound"));
    }
  } catch (e) {}
}
