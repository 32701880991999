import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./language-dropdown.css";
import globe from "../../assets/globe.svg";
import dropdown from "../../assets/dropdown.svg";
import languageList from "../../assets/i18n/languages.json";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { initLocalization } from "../../store/actions";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ textDecoration: "none" }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className="border_lang">
      <img className="globe_img" src={globe} />
      <p className="lang_text">{children}</p>
      <img className="dropdown_img" src={dropdown} />
    </div>
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");
    return (
      <div ref={ref} className={className} aria-labelledby={labeledBy}>
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const LangDropDown = (props) => {
  languageList.filter((item, index) => (item.eventKey = index + 1));
  
  const [languageName, setLanguageName] = useState(props.localization.language);

  const { i18n } = useTranslation();

  useEffect(() => {
    props.dispatchLocalization(props.localization.language);
  }, []);

  const setLang = (item) => {
    setLanguageName(item.languageName);
    const lang = item.languageName;
    props.dispatchLocalization(lang);
    i18n.changeLanguage(item.langCode);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {languageName}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {languageList.map((item) => (
          <Dropdown.Item
            onClick={() => {
              setLang(item);
            }}
            key={item.eventKey}
          >
            {item.languageName}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLocalization: (languageType) =>
      dispatch(initLocalization(languageType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LangDropDown));
