import * as actionTypes from "../actions";

const initialState = {
  payload: "",
  dashboardLoading: false,
};

export const reducerApproverDashboardCount = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_USER_COUNT_INIT:
      return {
        payload: action.payload,
        dashboardLoading: true,
      };
    case actionTypes.APPROVER_USER_COUNT_SUCCESS:
      return {
        dashBoardCount: action.payload,
        dashboardLoading: false,
      };
    case actionTypes.APPROVER_USER_COUNT_FAIL:
      return {
        payload: action.payload,
        dashboardLoading: false,
      };
    default:
      return state;
  }
};
