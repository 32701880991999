import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./approver-dashboard.css";
import ShellPecten from "../../assets/shell_pection.png";
import ApproverWizard from "../../components/approver-dashboard/approver-wizard.jsx";
import * as constantTypes from "../../common/constants";
import ApproverList from "../../components/approver-dashboard/approver-requests/requests/approver-list";
import ApproverApplicationViewDetails from "../../components/approver-dashboard/approver-requests/view-details/approver-application-view-details";
import ActionTaken from "../../components/approver-dashboard/approver-requests/final-status/application-approved-rejected";
import {
  displayReports,
  getApproverRequestList,
  displayAccounts,
  getReportsPage,
  getBroadcastPage,
} from "../../store/actions";
import AccountsHighlighted from "../../assets/accounts_highlighted.png";
import ApproverAccountSection from "../../components/approver-dashboard/approver-accounts/approver-accounts-section";
import ApproverAccountsUserDetails from "../../components/approver-dashboard/approver-accounts-user-details/approver-accounts-user-details";
import ApproverBroadcastScreen from "../../components/approver-dashboard/approver-broadcast-message/approver-broadcast-screen";
import ApproverReportsScreen from "../../components/approver-dashboard/approver-reports/approver-reports-screen";
import ApproverReportsSuccessScreen from "../../components/approver-dashboard/approver-reports/approver-reports-success-screen";
import ApproverCreateNewBroadcast from "../../components/approver-dashboard/approver-broadcast-message/approver-create-new-broadcast";
const ApproverDashboard = (props) => {
  const { screenToDisplay } = props.approverRequest; //newAccountSetup
  return (
    <div>
      <div className="main-parent shadow">
        <div className="flex-container" style={{ width: "100%" }}>
          <div className="leftcontainer">
            <div className="ShellPecten">
              <img className="logo" src={ShellPecten} />
            </div>
            <div className="logotext">
              <label className="flight-title">FLIGHT</label>
              <label className="release-title">RELEASE</label>
            </div>
          </div>
          <div className="right-container">
            <div className="username-role">
              <label style={{ fontSize: "14px" }}>
                Hi, {localStorage.getItem("nWDF18M")}
              </label>
              <label style={{ color: "##595959", fontSize: "12px" }}>
                Approver
              </label>
            </div>
            <div>
              <img className="user-acnts" src={AccountsHighlighted} />
            </div>
          </div>
        </div>
      </div>
      <div className="parent-container">
        <div className="panel1 shadow">
          <ApproverWizard />
        </div>
        <div className="panel2">
          {(() => {
            switch (screenToDisplay) {
              case constantTypes.APPROVER_REQUEST_DEFAULT_SCREEN:
                return <ApproverList />;
              case constantTypes.APPROVER_REQUEST_SCREEN:
                return <ApproverList />;
              case constantTypes.APPROVER_DETAIL_SCREEN:
                return <ApproverApplicationViewDetails />;
              case constantTypes.APPROVER_ACTION_TAKEN_SCREEN:
                return <ActionTaken />;
              case constantTypes.APPROVER_ACCOUNTS_SCREEN:
                return <ApproverAccountSection />;
              case constantTypes.ACCOUNTS_SETUP_SCREEN:
                return <ApproverAccountsUserDetails />;
              case constantTypes.REPORTS_SCREEN:
                return <ApproverReportsScreen />;
              case constantTypes.APPROVER_BROADCAST_SCREEN:
                return <ApproverBroadcastScreen />;
              case constantTypes.REPORTS_SUCCESS_SCREEN:
                return <ApproverReportsSuccessScreen />;
              case constantTypes.BROADCAST_SETUP_SCREEN:
                return <ApproverCreateNewBroadcast/>
            }
          })()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestApproverReqPage: (approverReq) =>
      dispatch(displayReports(approverReq)),
    requestgetApproverRequestList: (req) =>
      dispatch(getApproverRequestList(req)),
    requestAccountsPage: (approverAccounts) =>
      dispatch(displayAccounts(approverAccounts)),
    requestReportsPage: (approverReports) =>
      dispatch(getReportsPage(approverReports)),
    requestBroadcastPage: (approverBroadcast) =>
      dispatch(getBroadcastPage(approverBroadcast)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverDashboard));
