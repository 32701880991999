import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Icon_Eye from "../../assets/icon_eye.png";
import "./fuelerreset.css";
import { withRouter } from "react-router";
import {
  initAdminApproverPassReset,
  switchTab,
  switchTabReset,
  resetTC,
  tcPdfReset,
} from "../../store/actions";

const FuelerReset = (props) => {
  const [username, setUserName] = useState(props.userName);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emptyNewPassword, setemptyNewPassword] = useState(false); //empty new password
  const [emptyConfPassword, setemptyConfPwd] = useState(false); //empty confirm password
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false); //confirm password field format validation
  const [invalidNewPassword, setInvalidNewPassword] = useState(false); //new password field format validation
  const [onClickEye, setOnClickEye] = useState(false); //new password
  const [EyeClick, setEyeClick] = useState(false); //confirm password
  const [showErrorMessages, setshowErrorMessages] = useState(true);
  const [newPwdBorder, setNewPwdBorder] = useState(false); // new password border
  const [confPwdBorder, setConfPwdBorder] = useState(false); // conf password border
  const [NewConfPwdMatch, setNewConfPwdMatch] = useState(false); // new and confirm password mismatch

  const onCancel = () => {
    props.requestTcPdfReset();
    props.requestResetTC();
    props.requestSwitchLoginTab("");
    props.requestSwitchTabReset("");
  };
  useEffect(() => {
    if (props.passwordReset === "User password updated as requested") {
      props.requestSwitchLoginTab({ tab: "bannerfueler" });
    }
  }, [props.passwordReset]);
  const onFuelerReset = () => {
    if (!newPassword) {
      setemptyNewPassword(true);
    } else if (!confirmPassword) {
      setemptyConfPwd(true);
    } else {
      const data = {
        username: username.toLowerCase(),
        temporaryPassword: "",
        newPassword: newPassword,
      };
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#@*&!])[A-Za-z\d$#@*&!]{8,16}$/;

      if (reg.test(newPassword) === false) {
        setInvalidNewPassword(true);
      } else if (confirmPassword !== newPassword) {
        setNewConfPwdMatch(true);
      } else {
        setNewConfPwdMatch(false);
        props.requestPasswordReset({ data, navigation: props.history });
      }

      setshowErrorMessages(true);
    }
  };
  const handleEyeClick = () => {
    setOnClickEye(!onClickEye); //new password
  };
  const onEyeClick = () => {
    setEyeClick(!EyeClick); //temporary password
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="label-style">{props.languageSet.user_name}</label>
        <div className="input-group input-group-addon">
          <input
            value={username}
            contentEditable="false"
            type="text"
            className="form-control input-group-addon"
            aria-label="Text input with checkbox"
          />
        </div>
        <div className="line-break bs-linebreak"></div>
        <label className="label-style">{props.languageSet.new_password}</label>
        <div className="input-group input-group-addon">
          <input
            value={newPassword}
            type={onClickEye === false ? "password" : "text"}
            onChange={(evt) => {
              setNewPassword(evt.target.value);
              setInvalidNewPassword(false);
              setConfPwdBorder(false);
              setemptyNewPassword(false);
            }}
            className={
              "form-control input-group-addon input-group-pwd" +
              " " +
              (emptyNewPassword ||
              newPwdBorder ||
              (invalidNewPassword && !emptyNewPassword)
                ? "border-red"
                : "")
            }
            aria-label="Text input with checkbox"
          />
          <div className="input-group-append">
            <div
              className={
                "input-group-text bg-white" +
                " " +
                (emptyNewPassword ||
                newPwdBorder ||
                (invalidNewPassword && !emptyNewPassword)
                  ? "border-red"
                  : "")
              }
            >
              <input
                className="icon-eye-size"
                type="image"
                onClick={handleEyeClick}
                src={Icon_Eye}
                aria-label="Checkbox for following text input"
              />
            </div>
          </div>
        </div>
        {emptyNewPassword && (
          <p className="error_msg three-line-ellipsis">Enter your new password to proceed.</p>
        )}
        {invalidNewPassword && !emptyNewPassword && (
          <p className="error_msg three-line-ellipsis">
            {props.languageSet.invalid_pw_format}{" "}
            {props.languageSet.pw_validation}
          </p>
        )}
        <div className="line-break bs-linebreak"></div>
        <label className="label-style">
          {props.languageSet.confirm_password}
        </label>
        <div className="input-group input-group-addon">
          <input
            value={confirmPassword}
            type={EyeClick === false ? "password" : "text"}
            onChange={(evt) => {
              setConfirmPassword(evt.target.value);
              setemptyNewPassword(false);
              setNewPwdBorder(false);
              setConfPwdBorder(false);
              setemptyConfPwd(false);
              setNewConfPwdMatch(false);
            }}
            className={
              "form-control input-group-addon input-group-pwd" +
              " " +
              (emptyConfPassword || invalidConfirmPassword ? "border-red" : "")
            }
            aria-label="Text input with checkbox"
          />

          <div className="input-group-append">
            <div
              className={
                "input-group-text bg-white" +
                " " +
                (emptyConfPassword || invalidConfirmPassword
                  ? "border-red"
                  : "")
              }
            >
              <input
                className="icon-eye-size"
                type="image"
                onClick={onEyeClick}
                src={Icon_Eye}
                aria-label="Checkbox for following text input"
              />
            </div>
          </div>
        </div>
        {emptyConfPassword && (
          <p className="error_msg three-line-ellipsis">Enter confirm password to proceed.</p>
        )}
        {NewConfPwdMatch && (
          <p className="error_msg three-line-ellipsis">{props.languageSet.pw_mismatch}.</p>
        )}
        <div className="style-button">
          <button
            type="Save"
            className="btn btn-primary shadow-none save-signin-button"
            onClick={onFuelerReset}
          >
            {props.languageSet.save}
          </button>
          <div className="link-button">
            <button className="btn btn-link" onClick={onCancel}>
              {props.languageSet.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Listens to changes in store
const mapStateToProps = (state) => {
  return {
    passwordReset: state.adminApproverPasswordReset.passwordReset,
    passwordResetError: state.adminApproverPasswordReset.passwordResetError,
    languageSet: state.localization.languageSet,
  };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestPasswordReset: (adminApproverPasswordReset) =>
      dispatch(initAdminApproverPassReset(adminApproverPasswordReset)),
    requestSwitchLoginTab: (tab) => dispatch(switchTab(tab)),
    requestResetTC: () => dispatch(resetTC()),
    requestTcPdfReset: () => dispatch(tcPdfReset()),
    requestSwitchTabReset: () => dispatch(switchTabReset()),
  };
};
//
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FuelerReset));
