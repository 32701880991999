export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_RESET = "LOGIN_RESET";

export const SIGNUP_INIT = "SIGNUP_INIT";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const AUTH_REMOVE_TOKEN = "AUTH_REMOVE_TOKEN";

export const TC_COUNTRIES_INIT = "TC_COUNTRIES_INIT";
export const TC_COUNTRIES_SUCCESS = "TC_COUNTRIES_SUCCESS";
export const TC_RESET = "TC_RESET";
export const TC_PDF_INIT = "TC_PDF_INIT";
export const TC_PDF_SUCCESS = "TC_PDF_SUCCESS";
export const TC_PDF_RESET = "TC_PDF_RESET";
export const TC_ACCEPTED = "TC_ACCEPTED";

export const HEALTHCHECK_INIT = "HEALTHCHECK_INIT";
export const HEALTHCHECK_FAIL = "HEALTHCHECK_FAIL";
export const HEALTHCHECK_SUCCESS_PASSWORD = "HEALTHCHECK_SUCCESS_PASSWORD";
export const HEALTHCHECK_SUCCESS_SIGNUP = "HEALTHCHECK_SUCCESS_SIGNUP";
export const HEALTHCHECK_SUCCESS_LOGIN = "HEALTHCHECK_SUCCESS_LOGIN";
export const HEALTHCHECK_RESET = "HEALTHCHECK_RESET";
export const HEALTHCHECK_RESET_LOADING = "HEALTHCHECK_RESET_LOADING";

export const GET_USER_DETAILS_INIT = "GET_USER_DETAILS_INIT";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_RESET = "GET_USER_DETAILS_RESET";

export const SWITCH_TAB = "SWITCH_TAB";
export const SWITCH_TAB_RESET = "SWITCH_TAB_RESET";
export const INITIATE_TC = "INITIATE_TC";

export const ADMIN_APPROVER_PASSWORD_RESET_INIT =
  "ADMIN_APPROVER_PASSWORD_RESET_INIT";
export const ADMIN_APPROVER_PASSWORD_RESET_FAIL =
  "ADMIN_APPROVER_PASSWORD_RESET_FAIL";
export const ADMIN_APPROVER_PASSWORD_RESET_SUCCESS =
  "ADMIN_APPROVER_PASSWORD_RESET_SUCCESS";
export const ADMIN_APPROVER_PASSWORD_RESET_STATE_RESET =
  "ADMIN_APPROVER_PASSWORD_RESET_STATE_RESET";

export const FUELER_INIT = "FUELER_INIT";
export const FUELER_SUCCESS = "FUELER_SUCCESS";
export const FUELER_FAIL = "FUELER_FAIL";

export const QUSESTIONNAIRE_VALIDATON_INIT = "QUSESTIONNAIRE_VALIDATION_INIT";
export const QUSESTIONNAIRE_VALIDATON_SUCCESS =
  "QUSESTIONNAIRE_VALIDATON_SUCCESS";
export const QUSESTIONNAIRE_VALIDATON_FAILURE =
  "QUSESTIONNAIRE_VALIDATON_FAILURE";
export const QUSESTIONNAIRE_VALIDATION_RESET = "QUSESTIONNAIRE_VALIDATON_RESET";

export const QUSESTIONNAIRE_SUBMIT_RESET = "QUSESTIONNAIRE_SUBMIT_RESET";
export const QUSESTIONNAIRE_SUBMIT_INIT = "QUSESTIONNAIRE_SUBMIT_INIT";
export const QUSESTIONNAIRE_SUBMIT_SUCCESS = "QUSESTIONNAIRE_SUBMIT_SUCCESS";
export const QUSESTIONNAIRE_SUBMIT_FAILURE = "QUSESTIONNAIRE_SUBMIT_FAILURE";

export const NOTIFICATIONLIST_INIT = "NOTIFICATIONLIST_INIT";
export const NOTIFICATIONLIST_SUCCESS = "NOTIFICATIONLIST_SUCCESS";
export const NOTIFICATIONLIST_FAILURE = "NOTIFICATIONLIST_FAILURE";
export const NOTIFICATIONLIST_OPEN = "NOTIFICATIONLIST_OPEN";

export const LOCALIZATION_INIT = "LANGUAGE_LOCALIZATION";
export const LOCALIZATION_SUCCESS = "LOCALIZATION_SUCCESS";
export const LOCALIZATION_FAILURE = "LOCALIZATION_FAILURE";
export const LOCALIZATION_SET = "LOCALIZATION_SET";

export const APPROVER_REQUEST_LIST = "APPROVER_REQUEST_LIST";
export const APPROVER_DETAILS = "APPROVER_DETAILS";
export const APPROVER_ACTION_TAKEN = "APPROVER_ACTION_TAKEN";
export const APPROVER_REPORTS = "APPROVER_REPORTS";
export const APPROVER_ACCOUNTS = "APPROVER_ACCOUNTS";
export const APPROVER_BROADCAST_MESSAGE = "APPROVER_BROADCAST_MESSAGE";
export const APPROVER_REQUEST_GET_LIST_START =
  "APPROVER_REQUEST_GET_LIST_START";
export const APPROVER_REQUEST_GET_LIST_SUCCESS =
  "APPROVER_REQUEST_GET_LIST_SUCCESS";
//Timer
export const APPROVER_REQUEST_GET_LIST_FAIL = "APPROVER_REQUEST_GET_LIST_FAIL";
export const APPROVER_REQUEST_GET_LIST_STOP = "APPROVER_REQUEST_GET_LIST_STOP";
//Details actions
export const APPRROVER_RESPONSE_INIT = "APPROVER_RESPONSE_INIT";
export const APPRROVER_RESPONSE_SUCCESS = "APPROVER_RESPONSE_SUCCESS";
export const APPRROVER_RESPONSE_FAIL = "APPROVER_RESPONSE_FAIL";
export const APPRROVER_RESPONSE_RESET = "APPRROVER_RESPONSE_RESET";

//Timer
export const NOTIFICATION_WALL_START_TIMER = "NOTIFICATION_WALL_START_TIMER";
export const NOTIFICATION_WALL_STOP_TIMER = "NOTIFICATION_WALL_STOP_TIMER";
export const NOTIFICATION_WALL_ERROR_TIMER = "NOTIFICATION_WALL_ERROR_TIMER";

//accounts user details screen
export const APPROVER_ACCOUNTS_SETUP_SCREEN = "APPROVER_ACCOUNTS_SETUP_SCREEN";
//Accounts airport list
export const APPROVER_ACCOUNTS_AIRPORT_LIST_INIT =
  "APPROVER_ACCOUNTS_AIRPORT_LIST_INIT";
export const APPROVER_ACCOUNTS_AIRPORT_LIST_SUCCESS =
  "APPROVER_ACCOUNTS_AIRPORT_LIST_SUCCESS";
export const APPROVER_ACCOUNTS_AIRPORT_LIST_FAIL =
  "APPROVER_ACCOUNTS_AIRPORT_LIST_FAIL";
export const APPROVER_ACCOUNTS_AIRPORT_LIST_RESET =
  "APPROVER_ACCOUNTS_AIRPORT_LIST_RESET";

//Accounts Site List
export const APPROVER_ACCOUNTS_SITE_LIST_INIT =
  "APPROVER_ACCOUNTS_SITE_LIST_INIT";
export const APPROVER_ACCOUNTS_SITE_LIST_SUCCESS =
  "APPROVER_ACCOUNTS_SITE_LIST_SUCCESS";
export const APPROVER_ACCOUNTS_SITE_LIST_FAIL =
  "APPROVER_ACCOUNTS_SITE_LIST_FAIL";
export const APPROVER_ACCOUNTS_SITE_LIST_RESET =
  "APPROVER_ACCOUNTS_SITE_LIST_RESET";

//Accounts search user
export const APPROVER_ACCOUNTS_SEARCH_INIT = "APPROVER_ACCOUNTS_SEARCH_INIT";
export const APPROVER_ACCOUNTS_SEARCH_SUCCESS =
  "APPROVER_ACCOUNTS_SEARCH_SUCCESS";
export const APPROVER_ACCOUNTS_SEARCH_FAIL = "APPROVER_ACCOUNTS_SEARCH_FAIL";
export const APPROVER_ACCOUNTS_SEARCH_RESET = "APPROVER_ACCOUNTS_SEARCH_RESET";

//Approver accounts Create Site
export const APPROVER_SITE_CREATE_INIT = "APPROVER_SITE_CREATE_INIT";
export const APPROVER_SITE_CREATE_SUCCESS = "APPROVER_SITE_CREATE_SUCCESS";
export const APPROVER_SITE_CREATE_FAIL = "APPROVER_SITE_CREATE_FAIL";
export const APPROVER_SITE_CREATE_RESET = "APPROVER_SITE_CREATE_RESET";

//Approver accounts update Site
export const APPROVER_SITE_UPDATE_INIT = "APPROVER_SITE_UPDATE_INIT";
export const APPROVER_SITE_UPDATE_SUCCESS = "APPROVER_SITE_UPDATE_SUCCESS";
export const APPROVER_SITE_UPDATE_FAIL = "APPROVER_SITE_UPDATE_FAIL";
export const APPROVER_SITE_UPDATE_RESET = "APPROVER_SITE_UPDATE_RESET";

//Approver accounts Create User
export const APPROVER_USER_CREATE_INIT = "APPROVER_USER_CREATE_INIT";
export const APPROVER_USER_CREATE_SUCCESS = "APPROVER_USER_CREATE_SUCCESS";
export const APPROVER_USER_CREATE_FAIL = "APPROVER_USER_CREATE_FAIL";
export const APPROVER_USER_CREATE_RESET = "APPROVER_USER_CREATE_RESET";

//Logout
export const LOGOUT_INIT = "LOGOUT_INIT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_RESET = "LOGOUT_RESET";

//Refresh
export const REFRESH_INIT = "REFRESH_INIT";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAIL = "REFRESH_FAIL";
export const REFRESH_RESET = "REFRESH_RESET";

//approver dashboard user count
export const APPROVER_USER_COUNT_INIT = "APPROVER_USER_COUNT_INIT";
export const APPROVER_USER_COUNT_SUCCESS = "APPROVER_USER_COUNT_SUCCESS";
export const APPROVER_USER_COUNT_FAIL = "APPROVER_USER_COUNT_FAIL";

//approver dashboard user search
export const APPROVER_USER_SEARCH_INIT = "APPROVER_USER_SEARCH_INIT";
export const APPROVER_USER_SEARCH_SUCCESS = "APPROVER_USER_SEARCH_SUCCESS";
export const APPROVER_USER_SEARCH_FAIL = "APPROVER_USER_SEARCH_FAIL";
export const APPROVER_USER_SEARCH_RESET = "APPROVER_USER_SEARCH_RESET";

//snack bar
export const SNACK_ERROR_MESSAGE = "SNACK_ERROR_MESSAGE";
export const SNACK_SUCCESS_MESSAGE = "SNACK_SUCCESS_MESSAGE";
export const SNACK_WARNING_MESSAGE = "SNACK_WARNING_MESSAGE";
export const SNACK_INFO_MESSAGE = "SNACK_INFO_MESSAGE";
export const SNACK_INIT = "SNACK_INIT";

//snack approverTabBar
export const APPROVER_REQUEST_TAB = "APPROVER_REQUEST_TAB";
export const APPROVER_REPORTS_TAB = "APPROVER_REPORT_TAB";
export const APPROVER_ACCOUNTS_TAB = "APPROVER_ACCOUNTS_TAB";
export const APPROVER_BROADCAST_TAB = "APPROVER_BROADCAST_TAB";

//user account edit
export const USER_EDIT_FIRST_NAME = "USER_EDIT_FIRST_NAME";
export const USER_EDIT_LAST_NAME = "USER_EDIT_LAST_NAME";
export const USER_EDIT_USER_NAME = "USER_EDIT_USER_NAME";
export const USER_EDIT_EMAIL = "USER_EDIT_EMAIL";
export const USER_EDIT_SITE = "USER_EDIT_SITE";
export const USER_EDIT_INVALID_NAME = "USER_EDIT_INVALID_NAME";

//user account edit api
export const APPROVER_USER_EDIT_INIT = "APPROVER_USER_EDIT_INIT";
export const APPROVER_USER_EDIT_SUCCESS = "APPROVER_USER_EDIT_SUCCESS";
export const APPROVER_USER_EDIT_FAIL = "APPROVER_USER_EDIT_FAIL";
export const APPROVER_USER_EDIT_RESET = "APPROVER_USER_EDIT_RESET";

//user Delete api
export const APPROVER_USER_DELETE_INIT = "APPROVER_USER_DELETE_INIT";
export const APPROVER_USER_DELETE_SUCCESS = "APPROVER_USER_DELETE_SUCCESS";
export const APPROVER_USER_DELETE_FAIL = "APPROVER_USER_DELETE_FAIL";
export const APPROVER_USER_DELETE_RESET = "APPROVER_USER_DELETE_RESET";
export const RESET_ALL_VALUES = "RESET_ALL_VALUES";
// report success screen
export const REPORTS_SUCCESS_SCREEN = "REPORTS_SUCCESS_SCREEN";

// reports API
export const APPROVER_REPORTS_RESET = "APPROVER_REPORTS_RESET";
export const APPROVER_REPORTS_INIT = "APPROVER_REPORTS_INIT";
export const APPROVER_REPORTS_SUCCESS = "APPROVER_REPORTS_SUCCESS";
export const APPROVER_REPORTS_FAIL = "APPROVER_REPORTS_FAIL";

//broadcast message creating screen
export const BROADCAST_MESSAGE_CREATION_SCREEN =
  "BROADCAST_MESSAGE_CREATION_SCREEN";

//Broadcast create api
export const APPROVER_BROADCAST_RESET = "APPROVER_BROADCAST_RESET";
export const APPROVER_BROADCAST_INIT = "APPROVER_BROADCAST_INIT";
export const APPROVER_BROADCAST_SUCCESS = "APPROVER_BROADCAST_SUCCESS";
export const APPROVER_BROADCAST_FAIL = "APPROVER_BROADCAST_FAIL";

//Broadcast get countrywise site
export const APPROVER_BROADCAST_GET_COUNTRY_RESET =
  "APPROVER_BROADCAST_GET_COUNTRY_RESET";
export const APPROVER_BROADCAST_GET_COUNTRY_INIT =
  "APPROVER_BROADCAST_GET_COUNTRY_INIT";
export const APPROVER_BROADCAST_GET_COUNTRY_SUCCESS =
  "APPROVER_BROADCAST_GET_COUNTRY_SUCCESS";
export const APPROVER_BROADCAST_GET_COUNTRY_FAIL =
  "APPROVER_BROADCAST_GET_COUNTRY_FAIL";

// reports data
export const REPORTS_DATA_INIT = "REPORTS_DATA_INIT";
export const REPORTS_DATA_RESET = "REPORTS_DATA_RESET";

// Actions data
export const ACTIONS_DATA_INIT = "ACTIONS_DATA_INIT";
export const ACTIONS_DATA_RESET = "ACTIONS_DATA_RESET";
export const BROADCAST_DATA_INIT = "BROADCAST_DATA_INIT";
export const BROADCAST_DATA_RESET = "BROADCAST_DATA_RESET";

//accounts screen admin
export const ADMIN_ACCOUNTS_SCREEN = "ADMIN_ACCOUNTS_SCREEN";

//application screen admin
export const ADMIN_APPLICATION_DEFAULT_SCREEN =
  "ADMIN_APPLICATION_DEFAULT_SCREEN";

export const ADMIN_APPLICATION_SCREEN = "ADMIN_APPLICATION_SCREEN";
export const ADMIN_ACCOUNTS_SETUP_SCREEN = "ADMIN_ACCOUNTS_SETUP_SCREEN";

//ADMIN RESET FUELER PASSWORD
export const ADMIN_FUELER_PASSWORD_RESET_INIT =
  "ADMIN_FUELER_PASSWORD_RESET_INIT";
export const ADMIN_FUELER_PASSWORD_RESET_SUCCESS =
  "ADMIN_FUELER_PASSWORD_RESET_SUCCESS";
export const ADMIN_FUELER_PASSWORD_RESET_FAIL =
  "ADMIN_FUELER_PASSWORD_RESET_FAIL";
export const ADMIN_FUELER_PASSWORD_RESET_RESET =
  "ADMIN_FUELER_PASSWORD_RESET_RESET";

//Delete site

export const DELETE_SITE_INIT = "DELETE_SITE_INIT";
export const DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS";
export const DELETE_SITE_FAIL = "DELETE_SITE_FAIL";
export const DELETE_SITE_RESET = "DELETE_SITE_RESET";

export const ADMIN_APPLICATION_REJECT_PENDING =
  "ADMIN_APPLICATION_REJECT_PENDING";
export const ADMIN_APPLICATION_APPROVE = "ADMIN_APPLICATION_APPROVE";
