import React, { useState, useEffect } from "react";
import Signup from "../../components/login-signup-tab-switch/signup/signup";
import Login from "../../components/login-signup-tab-switch/login/login";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { tcAccepted, healthCheckReset } from "../../store/actions/";
const LoginSignup = (props) => {
  const [tabs, setTabControl] = useState(true);
  const { languageSet } = props.localization;

  const { isTermsAccepted } = props.history.location.state || "";

  useEffect(() => {
    if (isTermsAccepted) {
      handleTab(false);
      props.requestTcAccepted();
      props.history.replace("", {
        isTermsAccepted: false,
      });
    }
  }, [isTermsAccepted]);

  const handleTab = (isLogin) => {
    props.requestHealthCheckReset();
    setTabControl(isLogin);
  };
  return (
    <div>
      <div
        className="btn-group"
        role="group"
        aria-label="Button group with nested dropdown"
      >
        <div>
          <button
            type="button"
            className={
              "btn btn-primary custom-button shadow-none uppercase " +
              (tabs ? "btn-active" : "")
            }
            onClick={() => {
              handleTab(true);
            }}
          >
            {languageSet.logging}
          </button>
        </div>
        <div>
          <button
            type="button"
            className={
              "btn btn-primary custom-button shadow-none uppercase " +
              (!tabs ? "btn-active" : "")
            }
            onClick={() => {
              handleTab(false);
            }}
          >
            {languageSet.signup}
          </button>
        </div>
      </div>
      {tabs && <Login />}
      {!tabs && <Signup />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestTcAccepted: () => dispatch(tcAccepted()),
    requestHealthCheckReset: (healthCheck) =>
      dispatch(healthCheckReset(healthCheck)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginSignup));
