import * as actionTypes from "./action_types";

export const initSignUp = (data) => {
  return {
    payload: data,
    type: actionTypes.SIGNUP_INIT,
  };
};

export const signUpSuccess = (data) => {
  return {
    payload: data,
    type: actionTypes.SIGNUP_SUCCESS,
  };
};

export const signUpFail = (data) => {
  return {
    payload: data,
    type: actionTypes.SIGNUP_FAIL,
  };
};
