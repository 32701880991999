import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import UserSearchViewDetail from "../admin-account-user-details/user-search-view-detail";
import {
  displayAccounts,
  displayAdminUserDetails,
  approverAccountsSiteInit,
  getApproverUserCountInit,
  userSearchInit,
  userSearchReset,
  accountsDataInit,
  accountsDataReset,
} from "../../../store/actions";
import "../../approver-dashboard/approver-accounts/approver-accounts-section.css";
import AddAccount from "../../../assets/add_account.png";
import DeleteIcon from "../../../assets/delete.png";
import EditIcon from "../../../assets/edit.png";
import ResetPasswordIcon from "../../../assets/reset_password_icon.png";

import {
  initProps,
  handleNewAccount,
  searchUser,
  currentDate,
  monthNames,
  getNth,
  labelStyle,
  changeNoUser,
  changeSearchResultShow,
  setShowUserDetails,
  setShowModalEdit,
  setShowModalDelete,
  setIsSearch,
  setAdminResetPassword,
} from "../../admin-dashboard/admin-accounts/admin-accounts-util";
import { lang } from "moment";
const AdminAccounts = (props) => {
  initProps(props);
  /*************States*************/
  const { dashBoardCount, dashboardLoading } = props.dashboardCount;
  const { site_name: siteName } =
    props.userDetails.userDetails.sitedetails || {};

  const icsc = "ICSC";
  const {
    noUser,
    showUserDetails,
    userSearchString,
    modalEdit,
    modalDelete,
    adminResetPassword,
  } = props.approverAccountsData.userState;
  const {
    showSearchResult,
    isSearch,
    showMessage,
    searchStringLength,
    selectedItem,
  } = props.approverAccountsData.commonState;

  const { siteList, userSearchList, userSearchError, loadingSearch } =
    props.approverAccount || {};

  const { languageSet } = props.localization;

  /****************************************/
  useEffect(() => {
    props.requestUserCount();

    return () => {
      props.requestAccountsDataReset();
    };
  }, []);

  useEffect(() => {
    if (userSearchList) {
      changeNoUser(false);
      if (!(searchStringLength < 3)) {
        changeSearchResultShow(true);
      }
    }
    if (userSearchError) {
      if (!(searchStringLength < 3)) changeNoUser(true);
    }
  }, [userSearchList, userSearchError]);

  const searchCall = (data) => {
    props.requestUserSearch(data);
  };

  return (
    <div
      className={
        dashboardLoading
          ? "parent-accounts-container container-opacity"
          : "parent-accounts-container"
      }
    >
      {dashboardLoading && (
        <div className={"load-wrap approver"}>
          <div className="load">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="accounts-header">
        <div className="left-header">
          <label>{languageSet.accounts.toUpperCase()}</label>
        </div>
        <div className="button-container">
          <button
            type="button"
            className="btn btn-amber custom-style new-account-style shadow-none"
            onClick={handleNewAccount}
          >
            <div className="new-acnt-style">
              <img className="acnt-img-style" src={AddAccount} />
              {languageSet.create_new_account}
            </div>
          </button>
        </div>
      </div>
      <div
        className={
          isSearch ? "tab-container search-item shadow" : "tab-container shadow"
        }
      >
        <div className="search-container-style search-custom">
          <div className="row">
            <div className="col">
              <div className="md-form mt-0">
                <input
                  className="form-control input-custom custom-style admin-dashboard shadow"
                  placeholder={languageSet.search_user_acnt}
                  onFocus={() => {
                    setIsSearch(true);
                  }}
                  onChange={searchUser}
                />
              </div>
            </div>
          </div>
        </div>
        {showMessage && (
          <div className="result-container-style">
            <label>Please enter 3 characters to search</label>
          </div>
        )}
        {loadingSearch && (
          <div
            className="spinner-border text-primary loader-position"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {noUser && !showMessage && !loadingSearch && (
          <div className="result-container-style">
            <label>No Data</label>
          </div>
        )}
        {!loadingSearch && !noUser && showSearchResult && (
          <div className="result-container-style list shadow">
            {userSearchList.map((item, index) => (
              <div
                key={index}
                className="search-result-container clickable-row"
                onClick={() => {
                  setShowUserDetails(true, item);
                }}
              >
                <div className="search-result-user-name">
                  <label>{item.username}</label>
                </div>
                <div className="search-result-icons-container">
                  {(item.userroleid === 3 || item.userroleid === 4) && (
                    <img
                      className="reset-image-style"
                      src={ResetPasswordIcon}
                      onClick={(e) => {
                        setAdminResetPassword(true, item);
                        if (e.stopPropagation) e.stopPropagation();
                      }}
                    />
                  )}
                  <img
                    className="edit-image-style"
                    src={EditIcon}
                    onClick={(e) => {
                      setShowModalEdit(true, item);
                      if (e.stopPropagation) e.stopPropagation();
                    }}
                  />
                  <img
                    className="delete-image-style"
                    src={DeleteIcon}
                    onClick={(e) => {
                      setShowModalDelete(true, item);

                      if (e.stopPropagation) e.stopPropagation();
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {!isSearch && (
        <div className="count-status-pos">
          <div className="count-parent-container custom-count">
            <div className="count-container custom-inner-count">
              <label className="admin-count-admin custom-admin">
                {dashBoardCount && dashBoardCount.admin_count === 1
                  ? languageSet.admin
                  : languageSet.admins}
                <label style={labelStyle}>
                  {dashBoardCount && dashBoardCount.admin_count}
                </label>
              </label>
              {siteName !== icsc ? (
                <label className="fueler-count-fueler custom-fueler">
                  {dashBoardCount && dashBoardCount.fueler_count === 1
                    ? languageSet.fueler
                    : languageSet.fuelers}
                  <label style={labelStyle}>
                    {dashBoardCount && dashBoardCount.fueler_count}
                  </label>
                </label>
              ) : (
                <label className="fueler-count-fueler custom-fueler">
                  {languageSet.icsc}
                  <label style={labelStyle}>
                    {dashBoardCount && dashBoardCount.ICSC_count}
                  </label>
                </label>
              )}
            </div>
          </div>
        </div>
      )}
      {!isSearch && (
        <div className="date-parent-container">
          <div className="date-inner-container">
            <label>
              {languageSet.last_updated}: {currentDate.getDate()}
              {getNth()} {monthNames[currentDate.getMonth()]}'{" "}
              {currentDate
                .getYear()
                .toString()
                .substring(1, 3)}
            </label>
          </div>
        </div>
      )}

      <UserSearchViewDetail
        showUserDetails={showUserDetails}
        setShowUserDetails={setShowUserDetails}
        item={selectedItem}
        siteList={siteList}
        searchCall={searchCall}
        userSearchString={userSearchString}
        modalEdit={modalEdit}
        showModalEdit={setShowModalEdit}
        modalDelete={modalDelete}
        showModalDelete={setShowModalDelete}
        adminResetPassword={adminResetPassword}
        setAdminResetPassword={setAdminResetPassword}
      />
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    dashboardCount: state.dashboardCount,
    approverAccount: state.approverAccount,
    approverAccountsData: state.approverAccountsData,
    userDetails: state.userDetails,
    localization: state.localization,
  };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestApproverAcntsPage: (approverAcnts) =>
      dispatch(displayAccounts(approverAcnts)),
    requestNewAccountSetup: () => dispatch(displayAdminUserDetails()),
    requestApproverAccountSite: () => dispatch(approverAccountsSiteInit()),
    requestUserCount: () => dispatch(getApproverUserCountInit()),
    requestUserSearch: (req) => dispatch(userSearchInit(req)),
    requestUserSearchReset: (req) => dispatch(userSearchReset(req)),
    requestAccountsDataInit: (req) => dispatch(accountsDataInit(req)),
    requestAccountsDataReset: (req) => dispatch(accountsDataReset(req)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminAccounts));
