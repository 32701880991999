import * as actionTypes from "../../action_types";

export const reportsDataInit = (data) => {
  return {
    payload: data,
    type: actionTypes.REPORTS_DATA_INIT,
  };
};

export const reportsDataReset = (data) => {
  return {
    payload: data,
    type: actionTypes.REPORTS_DATA_RESET,
  };
};
