import * as actiontypes from "./action_types";

export const initFuelerInitialData = data => {
  return {
    payload: data,
    type: actiontypes.FUELER_INIT
  };
};

export const fuelerInitialDataSuccess = data => {
  return {
    payload: data,
    type: actiontypes.FUELER_SUCCESS
  };
};

export const fuelerInitialDataFail = data => {
  return {
    payload: data,
    type: actiontypes.FUELER_FAIL
  };
};
