import * as actionTypes from "./action_types";

export const initLogin = (data) => {
  return {
    type: actionTypes.LOGIN_INIT,
    ...data,
  };
};

export const loginSuccess = (login) => {
  return {
    payload: login,
    type: actionTypes.LOGIN_SUCCESS,
  };
};

export const loginFail = (data) => {
  return {
    payload: data,
    type: actionTypes.LOGIN_FAIL,
  };
};

export const loginReset = (data) => {
  return {
    payload: data,
    type: actionTypes.LOGIN_RESET,
  };
};
