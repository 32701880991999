import * as actionTypes from "./action_types";
import { AUTH_KEY, REFRESH_KEY } from "../../common/constants";

export const setAuthToken = (auth_token, refresh_token) => {
  localStorage.setItem(AUTH_KEY, auth_token);
  localStorage.setItem(REFRESH_KEY, refresh_token);
  return {
    payload: auth_token,
    type: actionTypes.AUTH_SET_TOKEN,
  };
};

export const removeAuthToken = (response) => {
  return {
    payload: response,
    type: actionTypes.AUTH_REMOVE_TOKEN,
  };
};
