const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const getNth = function(d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export const getDateFormatted = (item) => {
  //Function to  get date in format 20th May 2020, Fri
  let date = new Date(item);

  return (
    date.getDate() +
    getNth(date.getDate()) +
    " " +
    monthNames[date.getMonth()] +
    " " +
    date.getFullYear() +
    "," +
    " " +
    day[date.getDay()]
  );
};

export const getDateStandardFormat = (item) => {
  //Function to get date in format mm/dd/yyyy
  var date = new Date(item);

  var value =
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2)  +
    "/" +
    date.getFullYear();
    return value;
};
