import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  initHealthCheck,
  healthCheckReset,
  switchTab,
  initTcCountries,
  resetTC,
  tcPdfReset,
  initSignUp,
} from "../../../store/actions/";
import { withRouter } from "react-router";
import "./signup.css";
import TcCountriesPopup from "../../../components/Terms/popup/tc-countries-popup.jsx";
const Signup = (props) => {
  const {
    healthCheckError,
    isNewUserSignUp,
    userRole,
    loadingHealthcheck,
  } = props.healthCheck;
  const { tcCountries, displayCountries, switchTab } = props.termsConditions;
  const { isTCAccepted } = props.termsConditionsPdf;
  const [userName, setUserName] = useState("");
  const [emptyUserName, setEmptyUserName] = useState(false); //empty user name
  const [usernameBorder, setUsernameBorder] = useState(false);
  const { languageSet } = props.localization;
  const [tcCountriesModelOpen, setTcCountriesModelOpen] = useState(false);

  useEffect(() => {
    if (healthCheckError === "userNotFound") {
      setUsernameBorder(true);
    }
    if (isNewUserSignUp === false) {
      setUsernameBorder(true);
    }
  });

  useEffect(() => {
    if (isTCAccepted) {
      setUserName(switchTab.userName.trim());
      props.requestTcPdfReset();
      props.requestSignUp(switchTab);
    }
  }, [isTCAccepted]);

  useEffect(() => {
    if (displayCountries) {
      setTcCountriesModelOpen(true);
      props.requestResetTC();
    }
  }, [displayCountries]);
  useEffect(() => {
    if (healthCheckError === "" && isNewUserSignUp) {
      if (userRole === "fueler") {
        props.requestSwitchLoginTab({
          tab: "fuelerPasswordReset",
          userName: userName,
        });
      }
    }
  }, [healthCheckError, isNewUserSignUp]);

  const onSignUp = () => {
    if (!userName) {
      setEmptyUserName(true);
    } else {
      let data = { userName: userName.trim(), type: "signUp" };
      props.requestHealthCheck(data);
    }
  };
  const checkError = (healthCheckError) => {
    if (healthCheckError === "userNotFound") {
      return <p className="error_msg">{languageSet.user_not_exist}</p>;
    } else if (isNewUserSignUp === false) {
      return <p className="error_msg">{languageSet.acnt_active}.</p>;
    }
  };
  return (
    <div>
      <div className="line-break bs-linebreak"></div>
      <label className="label-style">{languageSet.user_name}</label>
      <div className="input-group input-group-addon">
        <input
          type="text"
          value={userName}
          onChange={(evt) => {
            setUserName(evt.target.value);
            setEmptyUserName(false);
            setUsernameBorder(false);
            props.requestHealthCheckReset();
          }}
          className={
            "form-control input-group-addon" +
            " " +
            (emptyUserName || usernameBorder ? "border-red" : "")
          }
          aria-label="Text input with checkbox"
        />
      </div>
      {emptyUserName && <p className="error_msg">{languageSet.user_enter}</p>}
      {checkError(healthCheckError)}
      <div className="align-self-center">
        <button
          type="signup"
          className="btn btn-primary signup-button uppercase shadow-none"
          onClick={onSignUp}
          disabled={loadingHealthcheck}
        >
          {loadingHealthcheck /*|| loadingHealthcheck*/ && (
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {languageSet.signup}
        </button>
      </div>
      <TcCountriesPopup
        tcCountriesModelOpen={tcCountriesModelOpen}
        setTcCountriesModelOpen={setTcCountriesModelOpen}
        tcCountries={tcCountries}
        userNameHeading={switchTab.userName}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestHealthCheck: (healthCheck) => dispatch(initHealthCheck(healthCheck)),
    requestHealthCheckReset: (healthCheck) =>
      dispatch(healthCheckReset(healthCheck)),
    requestSwitchLoginTab: (tab) => dispatch(switchTab(tab)),
    requestTC: (payload) => dispatch(initTcCountries(payload)),
    requestResetTC: () => dispatch(resetTC()),
    requestTcPdfReset: () => dispatch(tcPdfReset()),
    requestSignUp: (payload) => dispatch(initSignUp(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
