import * as actionTypes from "./action_types";
export const initLocalization = (languageType) => {
  return {
    payload: languageType,
    type: actionTypes.LOCALIZATION_INIT,
  };
};

export const localizationSuccess = (success) => {
  return {
    payload: success,
    type: actionTypes.LOCALIZATION_SUCCESS,
  };
};

export const localizationFailure = (failure) => {
  return {
    payload: failure,
    type: actionTypes.LOCALIZATION_FAILURE,
  };
};
//
export const localizationSet = (localizationJson) => {
  return {
    payload: localizationJson,
    type: actionTypes.LOCALIZATION_SET,
  };
};
