const selectGlobal = "global";
const selectCountry = "country";
const selectSite = "site";
const approver = "APPROVER";

var props;

export const initProps = (_props) => {
  props = _props;
};

export const getLocation = {
  get selectGlobal() {
    return selectGlobal;
  },
  get selectCountry() {
    return selectCountry;
  },
  get selectSite() {
    return selectSite;
  },
};

export const handleActiveStatus = (_status) => {
  var { broadcastDetails, statusBroadcast } = props.broadcastData;
  switch (_status) {
    case getLocation.selectGlobal:
      statusBroadcast = {
        ...statusBroadcast,
        isGlobal: true,
        isCountry: false,
        isSite: false,
        site: [],
        indexList: [],
        countryList: [],
        duplicateCountry: false,
      };
      break;
    case getLocation.selectCountry:
      statusBroadcast = {
        ...statusBroadcast,
        isGlobal: false,
        isCountry: true,
        isSite: false,
        site: [],
        indexList: [],
        countryList: [],
        duplicateCountry: false,
      };
      break;
    case getLocation.selectSite:
      statusBroadcast = {
        ...statusBroadcast,
        isGlobal: false,
        isCountry: false,
        isSite: true,
        site: [],
        indexList: [],
        countryList: [],
        duplicateCountry: false,
      };
      break;
    default:
      statusBroadcast = {
        ...statusBroadcast,
        isGlobal: true,
        isCountry: false,
        isSite: false,
        site: [],
        indexList: [],
        countryList: [],
        duplicateCountry: false,
      };
      break;
  }
  props.requestBroadcastData({
    ...broadcastDetails,
    status: _status,
    activeStatus: statusBroadcast,
  });
};

export const setSite = (data) => {
  var { broadcastDetails, statusBroadcast } = props.broadcastData;
  var siteList = [];
  if (data) siteList = [data.value];
  statusBroadcast = { ...statusBroadcast, site: siteList };

  props.requestBroadcastData({
    ...broadcastDetails,
    activeStatus: statusBroadcast,
  });
};
const checkDuplicate = (countryList, newCountry) => {
  for (var key in countryList) {
    if (countryList[key] === newCountry) return true;
  }
  return false;
};
export const setCountries = (data, remove, index) => {
  var { broadcastDetails, statusBroadcast } = props.broadcastData;
  var countryList = statusBroadcast.countryList;
  var indexList = statusBroadcast.indexList;

  if (
    data !== null &&
    checkDuplicate(statusBroadcast.countryList, data.label)
  ) {
    statusBroadcast = {
      ...statusBroadcast,
      duplicateCountry: true,
    };
  } else {
    if (!remove) {
      var objectName = index;

      countryList[objectName] = null;
      if (data) {
        countryList[objectName] = data.label;
        if (!indexList.includes(index)) indexList.push(index);
      } else {
        indexList.splice(indexList.indexOf(index), 1);
      }

      statusBroadcast = {
        ...statusBroadcast,
        countryList: countryList,
        indexList: indexList,
        duplicateCountry: false,
      };
    }
    if (remove) {
      var lastItem = Math.max(...indexList);
      if (lastItem !== 0) {
        indexList.splice(indexList.indexOf(lastItem), 1);
        delete countryList[lastItem];
        statusBroadcast = {
          ...statusBroadcast,
          countryList: countryList,
          indexList: indexList,
          duplicateCountry: false,
        };
      }
    }
  }
  props.requestBroadcastData({
    ...broadcastDetails,
    activeStatus: statusBroadcast,
  });
};

export const handleSend = (userType, totalCountryList, message) => {
  var { statusBroadcast } = props.broadcastData;
  var countryList = statusBroadcast.countryList;
  var indexList = statusBroadcast.indexList;
  var siteId = [];
  var createdBy = localStorage.getItem("iIF7LEd");
  if (userType === approver) siteId = [];
  else {
    if (statusBroadcast.isGlobal) {
      siteId = [];
    } else if (indexList.length === 0) siteId = statusBroadcast.site;
    else {
      for (var key in countryList) {
        if (countryList[key]) {
          var temp = totalCountryList.filter((item) => {
            if (item.label === countryList[key]) return item.value;
          });

          siteId = [...siteId, ...temp[0].value];
        }
      }
    }
  }

  var request = {
    created_by: createdBy,
    user_type:
      userType.toLowerCase() === "fueler/icsc"
        ? "fueler"
        : userType.toLowerCase(),
    site_id: siteId,
    notification_message: message,
  };
  props.requestBroadcastSendInit(request);
};
