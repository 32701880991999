import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { logoutAPI } from "../sagas/API";

export function* logout() {
  try {
    const response = yield logoutAPI();
    switch (response.status) {
      case 200:
        yield put(actions.logoutSuccess());
        break;

      case 400:
        yield put(actions.showErrorMessage("Bad request"));
        yield put(actions.logoutFail(true));
        break;

      case 401:
        yield put(actions.showErrorMessage("Unauthorized access"));
        yield put(actions.logoutFail(true));
        break;

      case 500:
        yield put(actions.showErrorMessage("Internal server error"));
        yield put(actions.logoutFail(true));
        break;

      default:
        yield put(actions.showErrorMessage("Unknown error"));
        yield put(actions.logoutFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
  }
}
