import * as actionTypes from "../actions";
const initialState = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  site: "",
  invalidUserName: false,
};

export const reducerAccountEdit = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_EDIT_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case actionTypes.USER_EDIT_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case actionTypes.USER_EDIT_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case actionTypes.USER_EDIT_SITE:
      return {
        ...state,
        site: action.payload,
      };
    case actionTypes.USER_EDIT_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case actionTypes.USER_EDIT_INVALID_NAME:
      return {
        ...state,
        editInvalidName: action.payload,
      };

      case actionTypes.RESET_ALL_VALUES:
        return {
          ...state,
          initialState,
        };
    default:
      return {
        ...state,
      };
  }
};
