import * as actionTypes from "../../../actions";

var startDate = new Date();
startDate.setDate(startDate.getDate() - 30);

const initialState = {
  reportDetails: {
    startdate: startDate,
    enddate: new Date(),
    status: "all",
  },
  statusReport: {
    isAll: true,
    isApproved: false,
    isRejected: false,
    isPending: false,
  },
};

export const reducerReportsData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORTS_DATA_INIT:
      return {
        reportDetails: {
          ...state.reportDetails,
          startdate: action.payload.startdate,
          enddate: action.payload.enddate,
          status: action.payload.status,
        },
        statusReport: {
          ...state.statusReport,
          isAll: action.payload.activeStatus.isAll,
          isApproved: action.payload.activeStatus.isApproved,
          isRejected: action.payload.activeStatus.isRejected,
          isPending: action.payload.activeStatus.isPending,
        },
      };

    case actionTypes.REPORTS_DATA_RESET:
      return {
        reportDetails: initialState.reportDetails,
        statusReport: initialState.statusReport,
      };

    default:
      return state;
  }
};
