import * as actionTypes from "../../../actions";

const initialState = {
  payload: {},
  updateSiteSuccess: false,
  updateSiteError: {},
  loadingUpdateSite: false,
};

export const reducerApproverSiteUpdate = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_SITE_UPDATE_RESET:
      return {
        ...state,
        updateSiteSuccess: false,
        updateSiteError: {},
        loadingUpdateSite: false,
      };
    case actionTypes.APPROVER_SITE_UPDATE_INIT:
      return {
        ...state,
        payload: action.payload,
        loadingUpdateSite: true,
      };

    case actionTypes.APPROVER_SITE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSiteSuccess: true,
        updateSiteError: {},
        loadingUpdateSite: false,
      };

    case actionTypes.APPROVER_SITE_UPDATE_FAIL:
      return {
        ...state,
        updateSiteSuccess: false,
        updateSiteError: action.payload,
        loadingUpdateSite: false,
      };

    default:
      return state;
  }
};
