import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./approver-accounts-user-details.css";
import BackIcon from "../../../assets/back_icon.png";
import AccountsNewFuelerDetails from "./accounts-new-fueler-details";
import { displayAccounts } from "../../../store/actions";
import AccountsNewSiteDetails from "./accounts-new-site-details";
import AccountsNewApproverDetails from "./accounts-new-approver-details";
import AccountsNewAdminDetails from "./accounts-new-admin-details";
import AccountsNewICSCDetails from "./accounts-new-icsc-details";
import SuccessAction from "./user-creation-successful";
import DropdownArrow from "../../../assets/dropdown-arrow.png";
import DropdownUp from "../../../assets/dropdown_up.png";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const ApproverAccountsUserDetails = (props) => {
  const { languageSet } = props.localization;
  const handleAccounts = () => {
    props.requestAccountsPage();
  };
  const [dropDownValue, setDropDownValue] = useState("SITE");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);
  const [siteCreation, setSiteCreation] = useState(false);
  const [items] = useState([
    { label: "SITE", value: "SITE" },
    { label: "APPROVER", value: "APPROVER" },
    { label: "ADMIN", value: "ADMIN" },
    { label: "FUELER", value: "FUELER" },
    { label: "ICSC", value: "ICSC" },
  ]);
  const toggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSelect = (event) => {
    setSuccessScreen(false);
    setDropDownValue(event.target.innerText);
    setIsDropdownOpen(!isDropdownOpen);
  };
  const successAction = (value, site) => {
    setSuccessScreen(value);
    setSiteCreation(site);
  };
  return (
    <div className="parent-user-details-container">
      <div className="new-account-header">
        <div className="left-header">
          <label>ACCOUNTS</label>
          <label style={{ paddingLeft: "1vw" }}> {">"} </label>
          <div id="newaccount">
            <a
              style={{
                cursor: "pointer",
              }}
            >
           {languageSet.create_new_account}
            </a>
          </div>
        </div>
      </div>
      <div className="user-details-container shadow">
        <div className="new-acnt-holder">
          <div className="left-back-container">
            <img className="back-img" src={BackIcon} onClick={handleAccounts} />
          </div>
          <div className="acnt-heading-middle-container">
            <label>{languageSet.create_new_account}t</label>
          </div>
        </div>
        <div className="account-type-container">
          <label>Choose Account Type</label>
          <Dropdown isOpen={isDropdownOpen} toggle={toggle} id="dropdown">
            <DropdownToggle id="dropdown-style">{dropDownValue}</DropdownToggle>
            <img
              id="dropdown_img"
              src={!isDropdownOpen ? DropdownArrow : DropdownUp}
              onClick={toggle}
            ></img>
            <DropdownMenu id="menu">
              {items.map(({ value, index }) => (
                <DropdownItem
                  onClick={handleSelect}
                  className="special"
                  key={index}
                  value={value}
                  id="dropdown-item"
                >
                  {value}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        {successScreen ? (
          <SuccessAction
            successAction={successAction}
            siteMessage={siteCreation}
          />
        ) : (
          (() => {
            switch (dropDownValue) {
              case "SITE":
                return <AccountsNewSiteDetails successAction={successAction} />;
              case "APPROVER":
                return (
                  <AccountsNewApproverDetails successAction={successAction} />
                );
              case "ADMIN":
                return (
                  <AccountsNewAdminDetails successAction={successAction} />
                );
              case "FUELER":
                return (
                  <AccountsNewFuelerDetails successAction={successAction} />
                );
              case "ICSC":
                return <AccountsNewICSCDetails successAction={successAction} />;
            }
          })()
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestAccountsPage: (approverAccounts) =>
      dispatch(displayAccounts(approverAccounts)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverAccountsUserDetails));
