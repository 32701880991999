import * as actiontypes from "../actions";

const initialState = {
  payload: {}
};

export const reducerQuestionnaireSubmit = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.QUSESTIONNAIRE_SUBMIT_RESET:
      return { ...state, questionnaireSubmitSuccess: {}, error: {} };

    case actiontypes.QUSESTIONNAIRE_SUBMIT_INIT:
      return { ...state, payload: action.payload };

    case actiontypes.QUSESTIONNAIRE_SUBMIT_SUCCESS:
      return {
        ...state,
        questionnaireSubmitSuccess: action.payload,
        payload: {}
      };

    case actiontypes.QUSESTIONNAIRE_SUBMIT_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
