import { put, delay } from "redux-saga/effects";
import * as actions from "../../../actions";
import { createBroadcast } from "../../API";
import * as actionTypes from "../../../actions/action_types";

export function* broadcastRequest(action) {
  try {
    const response = yield createBroadcast(action.payload);
    switch (response.status) {
      case 200:
        yield put(actions.broadcastSendSuccess(true));
        yield put(actions.showSuccessMessage("Success"));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_BROADCAST_GET_COUNTRY_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.broadcastSendFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        } 
      
        break;
      case 404:
        yield put(actions.userSearchFail("User not found"));
        yield put(actions.broadcastSendFail(true));
        break;

      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.broadcastSendFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.broadcastSendFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.broadcastSendFail(e));
  }
}
