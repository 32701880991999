import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { fuelerPasswordReset } from "./API";
import * as actionType from "../actions/action_types";
export function* fuelerPassReset(action) {
  try {
    const response = yield fuelerPasswordReset(action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.fuelerPassResetSuccess(true));
        yield put(actions.showSuccessMessage("Password Reset Success"));
        return;

      case 400:
        yield put(actions.showErrorMessage("bad request"));
        yield put(actions.fuelerPassResetFail(true));
        break;

      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionType.ADMIN_FUELER_PASSWORD_RESET_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.fuelerPassResetFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;

      case 500:
        yield put(actions.showErrorMessage("internal server error"));
        yield put(actions.fuelerPassResetFail(true));
        break;
      case 404:
        yield put(actions.showErrorMessage("Bad req"));
        yield put(actions.fuelerPassResetFail(true));
        break;

      default:
        yield put(actions.showErrorMessage("Unknown error"));
        yield put(actions.fuelerPassResetFail(true));

        break;
    }
    yield put(actions.getApproverReportsFail());
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
    yield put(actions.fuelerPassResetFail(true));
  }
}
