import React, { useState } from "react";
import "./tc-countries-popup.css";
import AlertsClose from "../../../assets/alerts_close.png";
import DropdownArrow from "../../../assets/dropdown-arrow.png";
import DropdownUp from "../../../assets/dropdown_up.png";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { InitTcPdf, tcPdfReset } from "../../../store/actions/";
import { useEffect } from "react";
const TcCountriesPopup = (props) => {
  const {
    tcCountriesModelOpen,
    setTcCountriesModelOpen,
    tcCountries,
    loading,
    userNameHeading,
  } = props;
  const { pdfLink } = props.termsConditionsPdf;
  const toggle = () => setTcCountriesModelOpen(!tcCountriesModelOpen);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [regions, setRegions] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const toggleDropDown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [dropDownValue, setDropDownValue] = useState("Select Countries");

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (tcCountries.length !== 0) {
      setDropDownValue(tcCountries[0].name);
      setItems(tcCountries);
      setCountryCode(tcCountries[0].cc);
      setRegions(tcCountries[0].regions);
    }
  }, [tcCountries]);

  useEffect(() => {
    if (pdfLink) {
      props.history.push("/termsAndConditions", {
        pdf_link: pdfLink,
      });
      props.requestTcPdfReset();
    }
  }, [pdfLink]);

  const handleDropDownClick = (index, name) => {
    setDropDownValue(name);

    const itemSelected = items[index];
    setCountryCode(itemSelected.cc);
    if (itemSelected.regions.length !== 0) {
     setRegions(itemSelected.regions); 
    }else{
      setRegions([]); 
    }
    setIsDropdownOpen(!isDropdownOpen);
  };
  const onSubmit = () => {
    if (regions.length !== 0) {
      props.requestTcPdfCC(
        "type=document&cc=" + countryCode + "&region=" + selectedRegion
      );
    } else {
      if (countryCode === "all") {
        props.requestTcPdfCC("type=document&cc=all&enabled=true");
      } else {
        props.requestTcPdfCC(
          "type=document&cc=" + countryCode + "&enabled=false"
        );
      }
    }
  };
  const handleSelectRegion = (regionCode) => {
    setSelectedRegion(regionCode);
  };

  return (
    <div>
      <Modal isOpen={tcCountriesModelOpen} toggle={toggle} >
        <div style= {{padding: "0px 40px"}} >
        <div className="tc-right-close_popup">
          <img className="alert-close_popup" src={AlertsClose} onClick={toggle} />
        </div>
        <label className="text-left">Hi {userNameHeading} !</label>
        <br />
        <div>
          <div className="text-center">
            
            <label className="text-left">
              Please select the country you are in:
            </label>
            <div>
              <Dropdown
                isOpen={isDropdownOpen}
                toggle={toggleDropDown}
                id="tc_dropdown_model"
              >
                <DropdownToggle id="tc-dropdown-style">
                  {dropDownValue}
                </DropdownToggle>
                <img
                  id="dropdown_img_model"
                  src={!isDropdownOpen ? DropdownArrow : DropdownUp}
                  onClick={toggleDropDown}
                ></img>

                <DropdownMenu id="menu-popup">
                  {items.map(({ name }, index) => (
                    <DropdownItem
                      className="special"
                      key={index}
                      value={name}
                      id="dropdown-item-popup"
                      onClick={() => handleDropDownClick(index, name)}
                    >
                      {name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <br />

              {(regions.length >= 1) && (
                <div>
                  <label className="text-left">Select the region: </label>

                  {regions.length >= 1 && (
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        value={regions[0].regcode}
                        className="custom-control-input"
                        onClick={() => handleSelectRegion(regions[0].regcode)}
                        id="defaultInline3"
                        name="inlineDefaultRadiosExample"
                      />
                      <label
                        className="custom-control-label"
                        for="defaultInline3"
                      >
                        {regions[0].name}
                      </label>
                    </div>
                  )}
                  {regions.length >= 2 && (
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        className="custom-control-input"
                        value={regions[1].regcode}
                        onClick={() => handleSelectRegion(regions[1].regcode)}
                        id="defaultInline4"
                        name="inlineDefaultRadiosExample"
                        // onChange={() => handleSwitchView(passengerView)}
                      />
                      <label
                        className="custom-control-label"
                        for="defaultInline4"
                      >
                        {regions[1].name}
                      </label>
                    </div>
                  )}

                  {regions.length >= 3 && (
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        className="custom-control-input"
                        value={regions[2].regcode}
                        onClick={() => handleSelectRegion(regions[3].regcode)}
                        id="defaultInline5"
                        name="inlineDefaultRadiosExample"
                      />
                      <label
                        className="custom-control-label"
                        for="defaultInline5"
                      >
                        {regions[2].name}
                      </label>
                    </div>
                  )}
                </div>
              )}
              <button
                type="login"
                className="btn btn-primary shadow-none login-button uppercase"
                onClick={onSubmit}
              >
                {loading && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                SUBMIT
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestTcPdfCC: (payload) => dispatch(InitTcPdf(payload)),
    requestTcPdfReset: () => dispatch(tcPdfReset()),
   
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TcCountriesPopup));
