import React, { useState } from "react";
import ShellPecten from "../../assets/shell_pection.png";
import logout from "../../assets/logout.svg";
import NotificationPopover from "./notification-popover";
import Logout from "../../components/header/logout";
import { openNotificationPopOver } from "../../store/actions";
import "./header.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const Header = (props) => {
  const [setLogout, setShowLogout] = useState(false);
  const { languageSet } = props.localization;
  return (
    <div className="main-parent shadow">
      <div className="flex-container" style={{ width: "100%" }}>
        <div className="leftcontainer">
          <div className="ShellPecten">
            <img className="logo" src={ShellPecten} />
          </div>

          <div className="logotext">
            <label className="flight-title">{languageSet.header_title}</label>
          </div>
        </div>

        <div className="rightcontainer">
          <div className="notification">
            <NotificationPopover />
          </div>
          <div className="logout">
            <img
              className="logouticon"
              src={logout}
              onClick={() => {
                props.requestOpenPopOver(false);
                setShowLogout(true);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Logout setLogout={setLogout} setShowLogout={setShowLogout} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestOpenPopOver: (data) => dispatch(openNotificationPopOver(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
