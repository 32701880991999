import { put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../actions/action_types";

const getQuestionnaireSubmit = async (data) => {
  const response = await fetch(process.env.REACT_APP_API_QUESTIONNAIRE_SUBMIT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + localStorage.getItem("auth_key"),
    },
    body: JSON.stringify(data),
  });

  return response;
};

// eslint-disable-next-line
export function* questionnaireSubmit(action) {
  try {
    const response = yield getQuestionnaireSubmit(action.payload);
    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        yield put(actions.questionnaireSubmitSuccess(responseData.payload));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.QUSESTIONNAIRE_SUBMIT_INIT
              )
            );
          } else {
            yield put(
              actions.questionnaireValidationsFail("UnAuthorized access")
            );
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }
        break;
      case 500:
        yield put(actions.getApproverRequestListFail("Internal server error"));
        break;
      default:
        yield put(actions.notificationListFail("unknown error"));
        break;
    }
  } catch (e) {
    yield put(actions.questionnaireSubmitFail(e));
  }
}
