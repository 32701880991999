import React, { useState, useEffect } from "react";
import "../../screens/fueler/fueler-dashboard.css";
import Add from "../../assets/add.png";
import Minus from "../../assets/minus.png";
import dateIcon from "../../assets/datepicker.png";
import Select from "react-select";
import SearchIcon from "../../assets/Search_Icn.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { XXI } from "../../common/constants";
import { ListVirtualization } from "../../common/components/list-virtualization";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const MainDest = (props) => {
  const placeholderStyle = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        paddingLeft: 0,
      };
    },
  };
  const setAddViewWithPlus = () => {
    settoggleUrl(Add);
    setOriginalClass("col hidden");
    setfromClass("hidden");
    setIsPlusOperation(true);
  };

  const [toggleUrl, settoggleUrl] = useState(Add);
  const [originClass, setOriginalClass] = useState("");
  const [formClass, setfromClass] = useState("");
  const [isPlusOperation, setIsPlusOperation] = useState(true);
  const [destinationTag, setDestinationTag] = useState("FINAL DESTINATION");
  const [destinationEmpty, setDestinationISEmpty] = useState(true);
  const { destCountLength, index, initialDataLoading } = props;
  const { languageSet } = props.localization;

  useEffect(() => {
    //changing destination empty field to false
    var temp = props.isDestMainEmpty;
    temp[index] = destinationEmpty;
    props.setDestmainISEmpty(temp);
  }, [destinationEmpty]);
  useEffect(() => {
    //Setting the new destination empty field as true
    var temp = props.isDestMainEmpty;
    temp[index] = true;
    props.setDestmainISEmpty(temp);

    return () => {
      var temp = props.isDestMainEmpty;
      temp[index] = false;
      props.setDestmainISEmpty(temp);
    };
  }, []);
  useEffect(() => {
    setOriginalClass("col");

    if (destCountLength === 1) {
      switch (index) {
        case 0:
          settoggleUrl(Add);
          setIsPlusOperation(true);
          setDestinationTag(
            languageSet &&
              languageSet.destination_label +
                " " +
                languageSet.destination +
                "(1)"
          );
          break;
      }
    } else if (destCountLength === 2) {
      switch (index) {
        case 0:
          settoggleUrl(Minus);
          setIsPlusOperation(false);
          setDestinationTag(languageSet && languageSet.destination + "(1)");
          break;
        case 1:
          settoggleUrl(Add);
          setOriginalClass("col hidden");
          setfromClass("hidden");
          setIsPlusOperation(true);
          setDestinationTag(
            languageSet.destination_label +
              " " +
              languageSet.destination +
              "(2)"
          );
          break;
      }
    } else if (destCountLength === 3) {
      switch (index) {
        case 0:
          settoggleUrl(Minus);
          setIsPlusOperation(false);
          setDestinationTag(languageSet.destination + "(1)");
          break;
        case 1:
          setAddViewWithPlus();
          setDestinationTag(languageSet.destination + "(2)");
          break;
        case 2:
          setAddViewWithPlus();
          setDestinationTag(
            languageSet.destination_label +
              " " +
              languageSet.destination +
              "(3)"
          );
          break;
      }
    } else if (destCountLength === 4) {
      switch (index) {
        case 0:
          settoggleUrl(Minus);
          setIsPlusOperation(false);
          setDestinationTag(languageSet.destination + "(1)");
          break;
        case 1:
          setAddViewWithPlus();
          setDestinationTag(languageSet.destination + "(2)");
          break;
        case 2:
          setAddViewWithPlus();
          setDestinationTag(languageSet.destination + "(3)");
          break;
        case 3:
          setAddViewWithPlus();
          setDestinationTag(
            languageSet.destination_label +
              " " +
              languageSet.destination +
              "(4)"
          );
          break;
      }
    } else if (destCountLength === 5) {
      switch (index) {
        case 0:
          settoggleUrl(Minus);
          setIsPlusOperation(false);
          setDestinationTag(languageSet.destination + "(1)");
          break;
        case 1:
          setAddViewWithPlus();
          setDestinationTag(languageSet.destination + "(2)");
          break;
        case 2:
          setAddViewWithPlus();
          setDestinationTag(languageSet.destination + "(3)");
          break;
        case 3:
          setAddViewWithPlus();
          setDestinationTag(languageSet.destination + "(4)");
          break;
        case 3:
          setAddViewWithPlus();
          setDestinationTag(languageSet.destination + "(4)");
          break;
        case 4:
          setAddViewWithPlus();
          setDestinationTag(
            languageSet.destination_label +
              " " +
              languageSet.destination +
              "(5)"
          );
          break;
      }
    }
  }, [props.destCountLength]);

  const handleDateChange = (date) => {
    props.setDepartureDate(date);
  };
  const handleOrigin = (opt) => {
    props.setOriginValue(opt);
  };

  const handleFinalDestination = (opt) => {
    if (!opt) {
      setDestinationISEmpty(true);
    } else {
      setDestinationISEmpty(false);
    }

    let curDest = props.destinations;
    curDest[index] = opt;
    checkXXI(curDest);
    props.setDestinations(curDest);
  };

  const checkXXI = (curDest) => {
    var otherDestXXI = false;
    curDest.forEach((item) => {
      if (item) {
        if (item.value === XXI) {
          otherDestXXI = true;
        }
      }
    });
    props.setDestXXI(otherDestXXI);
  };
  const DatePickerCustomInput = ({ value, onClick }) => (
    <div className={"col-custom"}>
      <div className="input-group-append custom-search">
        <input
          className={
            "icon-date-size custom-date" +
            " " +
            (!props.departureDate && props.isSubmitBtnClicked
              ? "border-red"
              : "")
          }
          type="image"
          src={dateIcon}
          aria-label="Checkbox for following text input"
          onClick={onClick}
        />
      </div>
      <button
        className={
          "form-control input-custom" +
          " " +
          (!props.departureDate && props.isSubmitBtnClicked ? "border-red" : "")
        }
        onClick={onClick}
      >
        {value}
      </button>
    </div>
  );
  return (
    <div className="row">
      <div className={originClass}>
        <div className="md-form mt-0">
          <label className="label-style">{languageSet.origin}</label>
          <Select
            className={formClass}
            ClearIndicator="true"
            type="text"
            styles={placeholderStyle}
            classNamePrefix={
              "react-select__control " +
              (!props.originvalue && props.isSubmitBtnClicked
                ? "border-red"
                : "") +
              " "
            }
            placeholder={languageSet.from}
            name="origin"
            isClearable="true"
            isLoading={true}
            isDisabled={initialDataLoading}
            options={props.airportListFormatted}
            components={{
              DropdownIndicator: () => {
                return (
                  <img
                    className="icon-search-size no-outline "
                    src={SearchIcon}
                  />
                );
              },
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
              MenuList: ListVirtualization,
            }}
            onChange={handleOrigin}
          />
        </div>
      </div>
      <div className={originClass}>
        <div className="md-form mt-0">
          <label className="label-style">{languageSet.departure}</label>
          <div className="col-custom">
            <DatePicker
              dateFormat="MMMM d, yyyy"
              placeholder="MMMM d, yyyy"
              selected={props.departureDate}
              onChange={handleDateChange}
              disabled={initialDataLoading}
              customInput={<DatePickerCustomInput />}
            />
          </div>
        </div>
      </div>

      <div className="col">
        <div className="md-form mt-0">
          <label className="label-style">{destinationTag}</label>
          <Select
            type="text"
            styles={placeholderStyle}
            ClearIndicator="true"
            classNamePrefix={
              "react-select__control " +
              (destinationEmpty && props.isSubmitBtnClicked
                ? "border-red"
                : "") +
              " "
            }
            placeholder={languageSet.to}
            isDisabled={initialDataLoading}
            name="origin"
            isClearable="true"
            options={props.airportListFormatted}
            isLoading={true}
            components={{
              DropdownIndicator: () => {
                return (
                  <img
                    className="icon-search-size no-outline "
                    src={SearchIcon}
                  />
                );
              },
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
              MenuList: ListVirtualization,
            }}
            onChange={handleFinalDestination}
          />

          <label className="label-style-custom-style">
            {languageSet.type_XXI_unknown_confidential_dest}.
          </label>
        </div>
      </div>

      <div className="col">
        <div className="md-form mt-0">
          <label className="label-style add">{languageSet.destination}</label>
          <div className="add">
            <img
              className="add_img"
              src={toggleUrl}
              onClick={
                isPlusOperation === true
                  ? props.onClickPlus
                  : props.onClickMinus
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    localization: state.localization,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainDest));
