import * as actionTypes from "../actions";
import { healthCheck } from "../sagas/saga_healthcheck";

const initialState = {
  isPasswordReset: {},
  isNewUser: {},
  isFueler: {},
  healthCheckError: {},
  loadingHealthcheck: false,
  loadingForgotPassword: false,
};

export const reducerHealthCheck = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HEALTHCHECK_INIT:
      if (action.query.type && action.query.type === "forgotPassword") {
        return {
          ...state,
          payload: action.payload,
          loadingHealthcheck: false,
          loadingForgotPassword: true,
        };
      }

      return {
        ...state,
        payload: action.payload,
        loadingHealthcheck: true,
        loadingForgotPassword: false,
      };

    case actionTypes.HEALTHCHECK_SUCCESS_PASSWORD:
      return {
        ...state,
        isPasswordReset: action.payload.isPasswordReset,
        isNewUser: action.payload.isNewUser,
        isFueler: true,
        healthCheckError: "",
        loadingHealthcheck: false,
        loadingForgotPassword: false,
      };

    case actionTypes.HEALTHCHECK_SUCCESS_SIGNUP:
      return {
        ...state,
        isNewUserSignUp: action.payload.isNewUser,
        userRole: action.payload.userRole,
        healthCheckError: "",
        loadingHealthcheck: false,
        loadingForgotPassword: false,
      };

    case actionTypes.HEALTHCHECK_SUCCESS_LOGIN:
      return {
        ...state,
        userRole: action.payload.userRole,
        healthCheckError: "",
        loadingHealthcheck: false,
        loadingForgotPassword: false,
      };

    case actionTypes.HEALTHCHECK_FAIL:
      return {
        ...state,
        healthCheckError: action.payload,
        loadingHealthcheck: false,
        loadingForgotPassword: false,
      };
      break;

    case actionTypes.HEALTHCHECK_RESET:
      return {
        ...state,
        isPasswordReset: {},
        isNewUser: {},
        isFueler: {},
        healthCheckError: {},
        loadingHealthcheck: false,
        loadingForgotPassword: false,
      };
      break;

    case actionTypes.HEALTHCHECK_RESET_LOADING:
      return {
        ...state,
        loadingHealthcheck: false,
        loadingForgotPassword: false,
      };
      break;

    default:
      return state;
  }
};
