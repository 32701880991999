import * as actionTypes from "./action_types";

export const actionAccountEditUserName = (data) => {
  return {
    payload: data,
    type: actionTypes.USER_EDIT_USER_NAME,
  };
};
export const actionAccountEditFirstName = (data) => {
  return {
    payload: data,
    type: actionTypes.USER_EDIT_FIRST_NAME,
  };
};
export const actionAccountEditLastName = (data) => {
  return {
    payload: data,
    type: actionTypes.USER_EDIT_LAST_NAME,
  };
};
export const actionAccountEditEmail = (data) => {
  return {
    payload: data,
    type: actionTypes.USER_EDIT_EMAIL,
  };
};
export const actionAccountEditSite = (data) => {
  return {
    payload: data,
    type: actionTypes.USER_EDIT_SITE,
  };
};

export const actionAccountEditInvalidName = (data) => {
  return {
    payload: data,
    type: actionTypes.USER_EDIT_INVALID_NAME,
  };
};
