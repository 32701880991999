import * as actionTypes from "../../../actions";

const initialState = {
  broadcastDetails: {
    status: "global",
  },
  statusBroadcast: {
    isGlobal: true,
    isCountry: false,
    isSite: false,
    site: [],
    countryList: {},
    indexList: [],
    duplicateCountry: false,
  },
};

export const reducerBroadcast = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BROADCAST_DATA_INIT:
      return {
        broadcastDetails: {
          ...state.broadcastDetails,
          status: action.payload.status,
        },
        statusBroadcast: {
          ...state.statusBroadcast,
          isGlobal: action.payload.activeStatus.isGlobal,
          isCountry: action.payload.activeStatus.isCountry,
          isSite: action.payload.activeStatus.isSite,
          site: action.payload.activeStatus.site,
          countryList: action.payload.activeStatus.countryList,
          duplicateCountry: action.payload.activeStatus.duplicateCountry,
          indexList: action.payload.activeStatus.indexList,
        },
      };

    case actionTypes.BROADCAST_DATA_RESET:
      return {
        broadcastDetails: initialState.broadcastDetails,
        statusBroadcast: initialState.statusBroadcast,
      };

    default:
      return state;
  }
};
