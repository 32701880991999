import * as actionTypes from "../actions";
import defaultLanguage from "../../assets/langDefault/langEnglish.json";
import { getLocalization } from "../../store/sagas/API";

function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const initialState = {
  languageSet: getLocalization.localizationJson,
  language: "english",
  error: "",
};

export const reducerLanguageLocalization = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCALIZATION_INIT:
      return { ...state };
    case actionTypes.LOCALIZATION_SUCCESS:
      return { ...state, language: action.payload };
    case actionTypes.LOCALIZATION_SET:
      return {
        ...state,
        languageSet: getLocalization.localizationJson.Translations[0],
        language: ucFirst(getLocalization.localizationJson.language),
      };
    case actionTypes.LOCALIZATION_FAILURE:
      return { ...state, error: action.payload };
    default:
      try {
        return {
          ...state,
          languageSet: getLocalization.localizationJson.Translations[0],
          language: ucFirst(getLocalization.localizationJson.language),
        };
      } catch {
        return {
          ...state,
          languageSet: defaultLanguage[0].Translations[0],
          language: ucFirst(defaultLanguage[0].language),
        };
      }
  }
};
