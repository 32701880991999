import * as actiontypes from "./action_types";

export const initsUserDetails = (query) => {
  return {
    type: actiontypes.GET_USER_DETAILS_INIT,
    query,
  };
};

export const userDetailsSuccess = (payload) => {
  return {
    payload: payload,
    type: actiontypes.GET_USER_DETAILS_SUCCESS,
  };
};

export const userDetailsReset = (payload) => {
  return {
    payload: payload,
    type: actiontypes.HEALTHCHECK_SUCCESS_LOGIN,
  };
};
