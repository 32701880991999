import * as actionTypes from "../actions";
const initialState = {
  tabState: {
    isRequestTab: true,
    isReportsTab: false,
    isAccountsTab: false,
    isBroadCastTab: false,
  },
};

export const reducerApproverTabSwitch = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_REQUEST_TAB:
      return {
        tabState: {
          ...state.tabState,
          isRequestTab: true,
          isReportsTab: false,
          isAccountsTab: false,
          isBroadCastTab: false,
        },
      };

    case actionTypes.APPROVER_REPORTS_TAB:
      return {
        tabState: {
          ...state.tabState,
          isRequestTab: false,
          isReportsTab: true,
          isAccountsTab: false,
          isBroadCastTab: false,
        },
      };

    case actionTypes.APPROVER_ACCOUNTS_TAB:
      return {
        tabState: {
          ...state,
          isRequestTab: false,
          isReportsTab: false,
          isAccountsTab: true,
          isBroadCastTab: false,
        },
      };

    case actionTypes.APPROVER_BROADCAST_TAB:
      return {
        tabState: {
          ...state,
          isRequestTab: false,
          isReportsTab: false,
          isAccountsTab: false,
          isBroadCastTab: true,
        },
      };

    default:
      return state;
  }
};
