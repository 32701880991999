import * as actionTypes from "../../action_types";

export const initFuelerPassReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.ADMIN_FUELER_PASSWORD_RESET_INIT,
  };
};
export const fuelerPassResetSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.ADMIN_FUELER_PASSWORD_RESET_SUCCESS,
  };
};

export const fuelerPassResetFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.ADMIN_FUELER_PASSWORD_RESET_FAIL,
  };
};

export const fuelerPassResetReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.ADMIN_FUELER_PASSWORD_RESET_RESET,
  };
};
