import React, { useEffect, useState } from "react";
import BroadcastMessage from "../../assets/broadcast_message.png";
import userAccounts from "../../assets/user_accounts.png";
import reportsIcon from "../../assets/reports.png";
import reportsHighlighted from "../../assets/reports_highlighted.png";
import accountsHighlighted from "../../assets/accounts_highlighted.png";
import broadcastHighlight from "../../assets/broadcast_msg_highlighted.png";
import RequestsHighlight from "../../assets/requests_highlighted.png";
import requestsIcon from "../../assets/requests.png";
import logout from "../../assets/logout.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "../../screens/approver/approver-dashboard.css";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import {
  displayReports,
  getApproverRequestList,
  initLogout,
  logoutReset,
  displayAccounts,
  actionApproverReqTab,
  actionReportTab,
  actionAccountsTab,
  actionBroadcastTab,
  getReportsPage,
  getBroadcastPage,
  notificationWallCountTimer,
  notificationWallStopTimer,
} from "../../store/actions";
const ApproverWizard = (props) => {
  const { tabState } = props.approverTabState;
  const { notificationBadgeCount } = props.notificationBadge;
  const [countBadge, setCountBadge] = useState(1);
  const handleApproverReq = () => {
    props.actionApproverReqTab();
    props.requestApproverReqPage();
  };
  const handleAccounts = () => {
    props.requestSwitchAccountTab();
    props.requestAccountsPage();
  };
  const handleReports = () => {
    props.requestSwitchReportTab();
    props.requestReportsPage();
  };
  const handleBroadcast = () => {
    props.actionBroadcastTab();
    props.requestBroadcastPage();
  };
  const { payload, error, loading: loadingLogout } = props.logout;
  const handleLogout = () => {
    props.requestLogout();
  };

  useEffect(() => {
    props.requestNotificationPullBadge();
    return () => {
      //This will execute every time the component unloads.
      props.requestNotificationPullBadgeStop();
    };
  }, []);
  useEffect(() => {
    if (payload === "success") {
      props.requestLogoutReset(); //Reset all state to initial state
      props.history.push("/");
    }
    if (error) {
      alert("Error in logout. Please try again");
    }
  }, [payload, error]);

  useEffect(() => {
    setCountBadge(notificationBadgeCount);
  }, [notificationBadgeCount]);
  return (
    <div>
      <div className="button-group-style">
        <div
          className="btn-group-vertical"
          role="group"
          aria-label="Vertical button group"
        >
          <button
            type="button"
            className={
              tabState.isRequestTab
                ? "btn btn-amber custom-style tab-click shadow-none"
                : "btn btn-amber custom shadow-none"
            }
            onClick={handleApproverReq}
          >
            <div className="req-style">
              <img
                className="req-img-style"
                src={tabState.isRequestTab ? requestsIcon : RequestsHighlight}
              />
              REQUESTS
            </div>
          </button>
          <button
            type="button"
            className={
              tabState.isReportsTab
                ? "btn btn-amber custom-style tab-click shadow-none"
                : "btn btn-amber custom shadow-none"
            }
            onClick={handleReports}
          >
            <div className="req-style">
              <img
                className="req-img-style"
                src={tabState.isReportsTab ? reportsHighlighted : reportsIcon}
              />
              REPORTS
            </div>
          </button>
          <button
            type="button"
            className={
              tabState.isAccountsTab
                ? "btn btn-amber custom-style tab-click shadow-none"
                : "btn btn-amber custom shadow-none"
            }
            onClick={handleAccounts}
          >
            <div className="req-style">
              <img
                className="req-img-style"
                src={
                  tabState.isAccountsTab ? accountsHighlighted : userAccounts
                }
              />
              ACCOUNTS
            </div>
          </button>
          <button
            type="button"
            className={
              tabState.isBroadCastTab
                ? "btn btn-amber custom-style tab-click shadow-none"
                : "btn btn-amber custom shadow-none"
            }
            onClick={handleBroadcast}
          >
            <div className="req-style">
              <div className="req-badge-style">
                <NotificationBadge
                  id="PopoverList"
                  count={countBadge}
                  effect={Effect.SCALE}
                />
              </div>
              <img
                className="req-img-style"
                src={
                  tabState.isBroadCastTab
                    ? broadcastHighlight
                    : BroadcastMessage
                }
              ></img>
              BROADCAST MESSAGE
            </div>
          </button>
        </div>
      </div>
      <div className="extra-space"></div>
      <button
        type="button"
        autoFocus
        className="btn btn-amber custom-logout logout-btn shadow-none"
        onClick={handleLogout}
        disabled={loadingLogout}
      >
        <div className="logout-image">
          <img className="logout-style" src={logout} />
          {loadingLogout && (
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          LOG OUT
        </div>
      </button>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestApproverReqPage: (approverReq) =>
      dispatch(displayReports(approverReq)),

    requestgetApproverRequestList: (req) =>
      dispatch(getApproverRequestList(req)),

    requestAccountsPage: (approverAccounts) =>
      dispatch(displayAccounts(approverAccounts)),
    requestLogout: () => dispatch(initLogout()),
    requestLogoutReset: () => dispatch(logoutReset()),

    actionApproverReqTab: () => dispatch(actionApproverReqTab()),
    requestSwitchReportTab: () => dispatch(actionReportTab()),
    requestSwitchAccountTab: () => dispatch(actionAccountsTab()),
    actionBroadcastTab: () => dispatch(actionBroadcastTab()),

    requestReportsPage: (approverReports) =>
      dispatch(getReportsPage(approverReports)),
    requestBroadcastPage: (approverBroadcast) =>
      dispatch(getBroadcastPage(approverBroadcast)),

    requestNotificationPullBadge: (notificationPullBadge) =>
      dispatch(notificationWallCountTimer(notificationPullBadge)),

    requestNotificationPullBadgeStop: (notificationPullBadgeStop) =>
      dispatch(notificationWallStopTimer(notificationPullBadgeStop)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverWizard));
