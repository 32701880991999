import * as actionTypes from "./action_types";

export const getApproverUserCountInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_COUNT_INIT,
  };
};

export const getApproverUserCountSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_COUNT_SUCCESS,
  };
};

export const getApproverUserCountFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_COUNT_FAIL,
  };
};
