import * as actionTypes from "../../action_types";

export const getApproverReportsReset = (data) => {
  return {
    payload: data,
    type: actionTypes.APPROVER_REPORTS_RESET,
  };
};
export const getApproverReportsInit = (data) => {
  return {
    payload: data,
    type: actionTypes.APPROVER_REPORTS_INIT,
  };
};

export const getApproverReportsSuccess = (data) => {
  return {
    payload: data,
    type: actionTypes.APPROVER_REPORTS_SUCCESS,
  };
};

export const getApproverReportsFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_REPORTS_FAIL,
  };
};
