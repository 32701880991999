import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  initFuelerPassReset,
  fuelerPassResetReset,
} from "../../../store/actions";
import "../../approver-dashboard/approver-accounts-user-details/popups/user-account-delete-dialog.css";
import { Modal, ModalBody } from "reactstrap";

const AdminResetPassword = (props) => {
  const { adminResetPassword, setAdminResetPassword, item } = props;
  const { success, error } = props.passwordReset || {};
  const toggle = () => {
    setAdminResetPassword(!adminResetPassword);
  };
  const handleReset = () => {
    var body = {
      username: item.username,
    };
    props.requestFuelerPassReset(body);
  };

  useEffect(() => {
    if (success || error) {
      props.requestFuelerPassResetReset();
      toggle();
    }
  }, [success, error]);

  return (
    <div>
      <Modal isOpen={adminResetPassword} toggle={toggle} id="my-delete-modal">
        <ModalBody>
          <br />
          <div className="delete-header">
            <label>Reset Password</label>
          </div>
          <div className="delete-que custom">
            <label>
              Are you sure you want to reset password for '
              {item && item.username}' ?
            </label>
          </div>
          <div id="modal-footer-custom">
            <button
              type="login"
              className="btn btn-primary cancel-btn custom style-custom yes-custom shadow"
              onClick={handleReset}
            >
              RESET
            </button>
            <button
              type="login"
              className="btn btn-primary delete-btn style-custom custom no-custom shadow"
              onClickCapture={toggle}
            >
              CANCEL
            </button>
          </div>
          <br />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { passwordReset: state.fuelerPassReset };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestFuelerPassReset: (data) => dispatch(initFuelerPassReset(data)),
    requestFuelerPassResetReset: (data) => dispatch(fuelerPassResetReset()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminResetPassword));
