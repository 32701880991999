import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { localizationRequest } from "../sagas/API";
import defaultLanguage from "../../../src/assets/langDefault/langEnglish.json";

export function* fetchLocalization(action) {
  try {
    const response = yield localizationRequest(action.payload.toLowerCase());

    switch (response.status) {
      case 200:
        const responseJson = yield response.json();

        // Put the object into storage
        localStorage.setItem("localization", JSON.stringify(responseJson[0]));
        yield put(actions.localizationSuccess(responseJson[0].language));

        break;

      case 400:
        yield put(actions.showErrorMessage("localization bad request"));
        // Put the object into storage
        localStorage.setItem(
          "localization",
          JSON.stringify(defaultLanguage[0])
        );
        yield put(actions.localizationSuccess(defaultLanguage[0].language));
        break;

      case 401:
        yield put(actions.showErrorMessage("localization Unauthorized access"));
        localStorage.setItem(
          "localization",
          JSON.stringify(defaultLanguage[0])
        );
        yield put(actions.localizationSuccess(defaultLanguage[0].language));

        break;

      case 500:
        yield put(
          actions.showErrorMessage("localization internal server error")
        );
        localStorage.setItem(
          "localization",
          JSON.stringify(defaultLanguage[0])
        );
        yield put(actions.localizationSuccess(defaultLanguage[0].language));

        break;
      case 404:
        yield put(
          actions.showErrorMessage(
            "localization Bad req - No language list found"
          )
        );
        localStorage.setItem(
          "localization",
          JSON.stringify(defaultLanguage[0])
        );
        yield put(actions.localizationSuccess(defaultLanguage[0].language));
        break;

      default:
        yield put(actions.showErrorMessage("localization Unknown error"));
        localStorage.setItem(
          "localization",
          JSON.stringify(defaultLanguage[0])
        );
        yield put(actions.localizationSuccess(defaultLanguage[0].language));
        break;
    }
  } catch (e) {
    localStorage.setItem("localization", JSON.stringify(defaultLanguage[0]));
    yield put(actions.localizationSuccess(defaultLanguage[0].language));
  }
}
