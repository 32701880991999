import * as actiontypes from "../../action_types";

export const initLogin = (data) => {
  return {
    type: actiontypes,
    ...data,
  };
};

export const displayAdminAccounts = (accounts_admin) => {
  return {
    payload: accounts_admin,
    type: actiontypes.ADMIN_ACCOUNTS_SCREEN,
  };
};
export const displayAdminApplication = (application_admin) => {
  return {
    payload: application_admin,
    type: actiontypes.ADMIN_APPLICATION_SCREEN,
  };
};

export const getReportsPage = (reportsPage) => {
  return {
    payload: reportsPage,
    type: actiontypes.APPROVER_REPORTS,
  };
};

export const displayAdminUserDetails = (detailsPage) => {
  return {
    payload: detailsPage,
    type: actiontypes.ADMIN_ACCOUNTS_SETUP_SCREEN,
  };
};

export const displayAdminApproved = (notification) => {
  return {
    payload: notification,
    type: actiontypes.ADMIN_APPLICATION_APPROVE,
  };
};

export const displayAdminRejectPending = (notification) => {
  return {
    payload: notification,
    type: actiontypes.ADMIN_APPLICATION_REJECT_PENDING,
  };
};
