import * as actionTypes from "../../../actions";
import * as constantTypes from "../../../../common/constants";

const initialState = {
  screenToDisplay: constantTypes.ADMIN_APPLICATION_DEFAULT_SCREEN,
  applicationitem: {},
};

export const reducerAdminAccounts = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_ACCOUNTS_SCREEN:
      return {
        ...state,
        payload: action.payload,
        screenToDisplay: constantTypes.ADMIN_ACCOUNTS_SCREEN,
        loading: true,
      };
    case actionTypes.ADMIN_APPLICATION_SCREEN:
      return {
        ...state,
        payload: action.payload,
        screenToDisplay: constantTypes.ADMIN_APPLICATION_SCREEN,
        loading: true,
      };
    case actionTypes.APPROVER_REPORTS:
      return {
        ...state,
        approverAction: action.payload,
        screenToDisplay: constantTypes.REPORTS_SCREEN,
      };
    case actionTypes.ADMIN_ACCOUNTS_SETUP_SCREEN:
      return {
        ...state,
        payload: action.payload,
        screenToDisplay: constantTypes.ADMIN_ACCOUNTS_SETUP_SCREEN,
      };

    case actionTypes.REPORTS_SUCCESS_SCREEN:
      return {
        ...state,
        payload: action.payload,
        screenToDisplay: constantTypes.REPORTS_SUCCESS_SCREEN,
      };
    case actionTypes.ADMIN_APPLICATION_REJECT_PENDING:
      return {
        ...state,
        payloadNotification: action.payload,
        screenToDisplay: actionTypes.ADMIN_APPLICATION_REJECT_PENDING,
      };

    case actionTypes.ADMIN_APPLICATION_APPROVE:
      return {
        ...state,
        payloadNotification: action.payload,
        screenToDisplay: actionTypes.ADMIN_APPLICATION_APPROVE,
      };
    default:
      return state;
  }
};
