import React, * as react from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import bgImg from "../../assets/Airplane.png";
import shell_logo from "../../assets/shell_pection.png";
import "./home.css";
import Dropdown from "../../components/language-dropdown/language-dropdown";
import FuelerReset from "../../components/passreset/fuelerreset";
import AdminReset from "../../components/passreset/adminreset";
import BannerAdmin from "../../components/passreset/admin-reset-banner";
import FuelerResetBanner from "../../components/passreset/fuelerresetbanner";
import LoginSignUpTAB from "../../components/login-signup-tab-switch/login-signup-tab-switch";
import { useTranslation } from "react-i18next";
import { localizationSet } from "../../store/actions/";
const Home = (props) => {
  const { t } = useTranslation();
  const { currentTab, userName } = props.switchLoginTabs;

  const { languageSet, language } = props.localization;

  useEffect(() => {
    props.requestSetLocalization();
  }, [language]);
  const getTab = () => {
    {
      switch (currentTab) {
        case "fuelerPasswordReset":
          return <FuelerReset userName={userName} />;
        case "approverPasswordReset":
          return <AdminReset userName={userName} />;
        case "bannerAdmin":
          return <BannerAdmin />;
        case "bannerfueler":
          return <BannerAdmin />;
        case "contactAdmin":
          return <FuelerResetBanner />;
        default:
          return <LoginSignUpTAB />;
      }
    }
  };

  return (
    <div className="outer_container">
      <div className="portion2-language">
        <div className="lang_drop_down">
          <Dropdown name="English" />
        </div>
      </div>
      <div className="portion1">
        <div className="bg_img">
          <img className="img_plane" src={bgImg} />
        </div>
      </div>

      <div
        className={
          currentTab === "approverPasswordReset"
            ? "content_box_admin"
            : "content_box"
        }
      >
        <img
          className={
            currentTab === "approverPasswordReset"
              ? "img_logo_admin"
              : "img_logo"
          }
          src={shell_logo}
        />
        <p
          className={
            currentTab === "approverPasswordReset"
              ? "logo_text_admin"
              : "logo_text"
          }
        >
          {languageSet.header_title}
        </p>
        {getTab()}
      </div>
      {/* <div className="bottom_line">
        <p className="bottom_line_text">{t("common.copyright")}</p>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestSetLocalization: () => dispatch(localizationSet()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
