import React from "react";
import "./user-account-delete-dialog.css";
import { Modal, ModalBody } from "reactstrap";
import SiteDeleteIcon from "../../../../assets/site_delete_icon.png";
import AlertsClose from "../../../../assets/alerts_close.png";

const SiteDeleteSuccessPopup = (props) => {
  const { siteModalDeleteSuccess, showSiteModalDeleteSuccess, item } = props;
  const toggle = () => showSiteModalDeleteSuccess(!siteModalDeleteSuccess);

  return (
    <div>
      <Modal
        isOpen={siteModalDeleteSuccess}
        toggle={toggle}
        id="my-delete-modal"
      >
        <ModalBody>
          <div className="right-close">
            <img className="alert-close" src={AlertsClose} onClick={toggle} />
          </div>
          <br />
          <div className="delete-confirmation custom-img">
            <img className="site-delete-icon" src={SiteDeleteIcon} />
            <label>‘{item && item.site_name}’ site successfully deleted.</label>
          </div>
          <br />
          <br />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SiteDeleteSuccessPopup;
