import React, { useState, useEffect } from "react";
import "./user-search-view-details.css";
import { Modal, ModalBody, ListGroupItemHeading } from "reactstrap";
import AlertsClose from "../../../../assets/alerts_close.png";
import UserProfileHighlighted from "../../../../assets/user_profile_highlighted.png";
import UserAccountEditDialog from "./user-account-edit-dialog";
import UserAccountDeleteDialog from "./user-account-delete-dialog";
import AdminResetPassword from "../../../admin-dashboard/admin-account-user-details/admin-reset-password-popup";
const UserSearchViewDetails = (props) => {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState();
  const {
    modalEdit,
    showModalEdit,
    modalDelete,
    showModalDelete,
    searchCall,
    userSearchString,
  } = props;

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);
  useEffect(() => {
    setModal(props.showUserDetails);
  }, [props.showUserDetails]);
  const toggle = () => {
    setModal(!modal);
    props.setShowUserDetails(false);
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} id="my-modal">
        <ModalBody id="modal-body-custom">
          <div className="cross-btn">
            <img className="cross-img" src={AlertsClose} onClick={toggle} />
          </div>
          <div id="modal-title">
            <img className="profile-img" src={UserProfileHighlighted} />
            <label className="user-label">{item && item.username}</label>
            <img className="reset-password" onClickCapture={toggle} />
          </div>
          <hr id="small-hr"></hr>
          <div id="modal-inner-body">
            <table>
              <tr>
                <td>Account Type</td>
                <td style={{ paddingLeft: "5%" }}>:</td>
                <td>
                  {item &&
                    (() => {
                      switch (item.userroleid) {
                        case 1:
                          return "Approver";
                          break;
                        case 2:
                          return "Admin";
                          break;
                        case 3:
                          return "Fueler";
                          break;
                        case 4:
                          return "ICSC";
                          break;
                        default:
                          break;
                      }
                    })()}
                </td>
              </tr>
              <br />
              <tr>
                <td>Name</td>
                <td style={{ paddingLeft: "5%" }}>:</td>
                <td>{item && item.username}</td>
              </tr>
              <br />
              <tr>
                <td>Email Id</td>
                <td style={{ paddingLeft: "5%" }}>:</td>
                <td>{item && item.useremail}</td>
              </tr>
            </table>
          </div>
          <div id="modal-footer">
            <button
              type="login"
              className="btn btn-primary edit-btn custom shadow-none"
              onClick={() => {
                showModalEdit(true);
              }}
              onClickCapture={toggle}
            >
              EDIT
            </button>
            <button
              type="login"
              className="btn btn-primary delete-btn custom shadow-none"
              onClick={() => {
                showModalDelete(true);
              }}
              onClickCapture={toggle}
            >
              DELETE
            </button>
          </div>
          <br />
        </ModalBody>
      </Modal>
      <UserAccountEditDialog
        modalEdit={modalEdit}
        showModalEdit={showModalEdit}
        searchCall={searchCall}
        userSearchString={userSearchString}
        item={item}
      />
      <UserAccountDeleteDialog
        modalDelete={modalDelete}
        showModalDelete={showModalDelete}
        searchCall={searchCall}
        userSearchString={userSearchString}
        item={item}
      />
    </div>
  );
};

export default UserSearchViewDetails;
