import React from "react";
import "./wizardicon.css";

const WizardIcon = props => {
  return (
    <div>
      <img className="wizard1" src={props.icon}></img>
      <div className = "textprops">{props.text}</div>
    </div>
  );
};

export default WizardIcon;
