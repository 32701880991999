import { put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionType from "../actions/action_types";
const putApproverResponse = async (data) => {
  const response = await fetch(process.env.REACT_APP_API_APPROVER_RESPONSE, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, OPTIONS",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + localStorage.getItem("auth_key"),
    },
    body: JSON.stringify(data),
  });

  return response;
};

export function* approverResponse(action) {
  try {
    const response = yield putApproverResponse(action.payload);
    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        yield put(actions.getApproverResponseSuccess(responseData));
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionType.APPRROVER_RESPONSE_INIT
              )
            );
          } else {
            yield put(actions.getApproverResponseFail("UnAuthorized access"));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;
      case 409:
        yield put(
          actions.getApproverResponseFail("Updated by other Approver already")
        );
        break;
      case 400:
        yield put(actions.getApproverResponseFail("Invalid Request body"));
        break;
      case 500:
        yield put(actions.getApproverResponseFail("Error in updations"));
        break;
      default:
        yield put(actions.getApproverResponseFail("unknown error"));
        break;
    }
  } catch (e) {
    yield put(actions.getApproverResponseFail(e));
  }
}
