import React from "react";
import "./user-account-delete-dialog.css";
import { Modal, ModalBody } from "reactstrap";
import SymbolCorrect from "../../../../assets/symbol_correct.png";
import AlertsClose from "../../../../assets/alerts_close.png";

const SiteEditSuccessPopup = (props) => {
  const { siteModalEditSuccess, setSiteModalEditSuccess, item } = props;
  const toggle = () => setSiteModalEditSuccess(!siteModalEditSuccess);

  return (
    <div>
      <Modal isOpen={siteModalEditSuccess} toggle={toggle} id="my-delete-modal">
        <ModalBody>
          <div className="right-close">
            <img className="alert-close" src={AlertsClose} onClick={toggle} />
          </div>
          <br />
          <div className="delete-confirmation custom-img">
            <img className="site-delete-icon" src={SymbolCorrect} />
            <label>
              Changes made to {item && item.site_name} site are successfully
              saved.
            </label>
          </div>
          <br />
          <br />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SiteEditSuccessPopup;
