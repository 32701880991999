import * as actiontypes from "../actions";

const initialState = {
  payload: {},
};

export const reducerSwitchLoginTabs = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.SWITCH_TAB:
      return {
        ...state,
        currentTab: action.payload.tab,
        userName: action.payload.userName,
      };
      case actiontypes.SWITCH_TAB:
      return {
        ...state,
        currentTab: "",
        userName: "",
      };
    default:
      return state;
  }
};

