import * as actionTypes from "../../../actions";

const initialState = {
  payload: {},
};

export const reducerApproverAccounts = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_INIT:
      return {
        ...state,
        payload: action.payload,
      };
    case actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_SUCCESS:
      return {
        ...state,
        airportList: action.payload.airportList,
        countryList: action.payload.countryList,
        errorAirportlist: false,
      };
    case actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_FAIL:
      return {
        ...state,
        errorAirportList: action.payload,
      };
    case actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_RESET:
      return {
        ...state,
        airportList: false,
        countryList: false,
        errorAirportList: false,
      };

    case actionTypes.APPROVER_ACCOUNTS_SITE_LIST_INIT:
      return {
        ...state,
        payload: action.payload,
      };
    case actionTypes.APPROVER_ACCOUNTS_SITE_LIST_SUCCESS:
      return {
        ...state,
        siteList: action.payload.siteList,
        icscSite: action.payload.icscSite,
        errorSiteList: false,
      };
    case actionTypes.APPROVER_ACCOUNTS_SITE_LIST_FAIL:
      return {
        ...state,
        errorSiteList: action.payload,
      };
    case actionTypes.APPROVER_ACCOUNTS_SITE_LIST_RESET:
      return {
        ...state,
        siteList: false,
        errorSiteList: false,
      };

    case actionTypes.APPROVER_SITE_CREATE_INIT:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };
    case actionTypes.APPROVER_SITE_CREATE_SUCCESS:
      return {
        ...state,
        siteCreateSuccess: action.payload,
        siteCreateError: false,
        loading: false,
      };
    case actionTypes.APPROVER_SITE_CREATE_FAIL:
      return {
        ...state,
        siteCreateError: action.payload,
        loading: false,
      };
    case actionTypes.APPROVER_SITE_CREATE_RESET:
      return {
        ...state,
        siteCreateSuccess: false,
        siteCreateError: false,
        loading: false,
      };

    case actionTypes.APPROVER_USER_CREATE_INIT:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };
    case actionTypes.APPROVER_USER_CREATE_SUCCESS:
      return {
        ...state,
        userCreateSuccess: action.payload,
        userCreateError: false,
        loading: false,
      };
    case actionTypes.APPROVER_USER_CREATE_FAIL:
      return {
        ...state,
        userCreateError: action.payload,
        loading: false,
      };
    case actionTypes.APPROVER_USER_CREATE_RESET:
      return {
        ...state,
        userCreateError: false,
        userCreateSuccess: false,
        loading: false,
      };

    case actionTypes.APPROVER_USER_SEARCH_INIT:
      return {
        ...state,
        payload: action.payload,
        loadingSearch: true,
      };
    case actionTypes.APPROVER_USER_SEARCH_SUCCESS:
      return {
        ...state,
        userSearchList: action.payload,
        userSearchError: false,
        loadingSearch: false,
      };
    case actionTypes.APPROVER_USER_SEARCH_FAIL:
      return {
        ...state,
        userSearchError: action.payload,
        loadingSearch: false,
      };
    case actionTypes.APPROVER_USER_SEARCH_RESET:
      return {
        ...state,
        userSearchError: false,
        userSearchList: false,
        loadingSearch: false,
      };
    case actionTypes.APPROVER_USER_EDIT_INIT:
      return {
        ...state,
        payload: action.payload,
        loadingEditUser: true,
      };
    case actionTypes.APPROVER_USER_EDIT_SUCCESS:
      return {
        ...state,
        editUserSuccess: true,
        editUserError: false,
        loadingEditUser: false,
      };
    case actionTypes.APPROVER_USER_EDIT_FAIL:
      return {
        ...state,
        editUserError: action.payload,
        loadingEditUser: false,
      };
    case actionTypes.APPROVER_USER_EDIT_RESET:
      return {
        ...state,
        editUserSuccess: false,
        editUserError: false,
        loadingEditUser: false,
      };
    case actionTypes.APPROVER_USER_DELETE_INIT:
      return {
        ...state,
        payload: action.payload,
        loadingDeleteUser: true,
      };
    case actionTypes.APPROVER_USER_DELETE_SUCCESS:
      return {
        ...state,
        deleteUserSuccess: true,
        deleteUserError: false,
        loadingDeleteUser: false,
      };
    case actionTypes.APPROVER_USER_DELETE_FAIL:
      return {
        ...state,
        deleteUserError: true,
        loadingDeleteUser: false,
      };
    case actionTypes.APPROVER_USER_DELETE_RESET:
      return {
        ...state,
        deleteUserSuccess: false,
        deleteUserError: false,
        loadingDeleteUser: false,
      };

    default:
      return state;
  }
};
