import * as actiontypes from "./action_types";

export const initHealthCheck = (query) => {
  return {
    type: actiontypes.HEALTHCHECK_INIT,
    query,
  };
};
export const healthCheckSuccessPassword = (payload) => {
  return {
    payload: payload,
    type: actiontypes.HEALTHCHECK_SUCCESS_PASSWORD,
  };
};

export const healthCheckSuccessSignUp = (payload) => {
  return {
    payload: payload,
    type: actiontypes.HEALTHCHECK_SUCCESS_SIGNUP,
  };
};

export const healthCheckSuccessLogin = (payload) => {
  return {
    payload: payload,
    type: actiontypes.HEALTHCHECK_SUCCESS_LOGIN,
  };
};

export const healthCheckFail = (data) => {
  return {
    payload: data,
    type: actiontypes.HEALTHCHECK_FAIL,
  };
};

export const healthCheckReset = (data) => {
  return {
    payload: data,
    type: actiontypes.HEALTHCHECK_RESET,
  };
};

export const healthCheckResetLoading = (data) => {
  return {
    payload: data,
    type: actiontypes.HEALTHCHECK_RESET_LOADING,
  };
};
