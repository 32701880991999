import * as actionTypes from "../../action_types";

export const deleteSiteInit = (data) => {
  return {
    payload: data,
    type: actionTypes.DELETE_SITE_INIT,
  };
};
export const deleteSiteSuccess = (data) => {
  return {
    payload: data,
    type: actionTypes.DELETE_SITE_SUCCESS,
  };
};

export const deleteSiteFail = (data) => {
  return {
    payload: data,
    type: actionTypes.DELETE_SITE_FAIL,
  };
};

export const deleteSiteReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.DELETE_SITE_RESET,
  };
};
