import React from "react";
import { Modal } from "react-bootstrap";
import "../../components/header/broadcast-message-alert.css";
import CrossBtn from "../../assets/Close_Icn.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";
const BroadcastMessageAlert = (props) => {
  const {
    showBroadcastAlert,
    setShowBroadcastAlert,
    notificationBroadcastDetails,
  } = props;
  const handleClose = () => setShowBroadcastAlert(false);
  const { languageSet } = props.localization;
  return (
    <React.Fragment>
      <Modal
        show={showBroadcastAlert}
        onHide={handleClose}
        animation={true}
        id="my-broadcast-modal"
      >
        <Modal.Body id="broadcast-body">
          <div className="broadcast-container">
            <div className="broadcast-heading">
              <label>BROADCAST MESSAGE</label>
            </div>
            <div className="right-close-container">
              <img src={CrossBtn} onClick={handleClose} />
            </div>
          </div>
          <div className="text custom-modal blue-text">
            By
            <label style={{ fontWeight: "bold", paddingLeft: "0.3vw" }}>
              {notificationBroadcastDetails.approver_name}
            </label>
          </div>
          <div>
            <label className="styling-label custom">
              {notificationBroadcastDetails.broadcast_message}
            </label>
          </div>
          <br />
          <br />
          <br />
          <br />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BroadcastMessageAlert));
