import React, { useState, useEffect } from "react";
import "./accounts-new-approver-details.css";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ICSC } from "../../../common/constants";
import {
  userCreateInit,
  getUserCreateReset,
  displayAccounts,
} from "../../../store/actions";
const AccountsNewICSCDetails = (props) => {
  const handleAccounts = () => {
    props.requestAccountsPage();
  };
  const { handleSubmit } = useForm();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [userNameExists, setUserNameExists] = useState(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [isAllFieldFilled, setIsAllFieldFilled] = useState(false);
  const { userCreateSuccess, userCreateError, icscSite, loading } =
    props.approverAccount || {};
  const { languageSet } = props.localization;

  const userNameValidation = () => {
    let reg = /^[a-zA-Z0-9._-]{3,30}$/;
    if (!reg.test(userName)) {
      setInvalidUsername(true);
      setUserNameExists(false);
      return true;
    }
    return false;
  };

  const isEveryFieldFilled = () => {
    if (!firstName || !lastName || userNameValidation()) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (isEveryFieldFilled()) {
      setIsAllFieldFilled(false);
    } else {
      setIsAllFieldFilled(true);
    }

    if (email && email.indexOf("@") === -1) {
      setInvalidEmail(true);
    }
  });
  useEffect(() => {
    if (userCreateSuccess) {
      props.successAction(true, false);
      props.requestUserCreateReset();
    }
    if (userCreateError) {
      if (userCreateError === "User name already  exists") {
        setUserNameExists(true);
        props.requestUserCreateReset();
      } else props.requestUserCreateReset();
    }
  }, [userCreateSuccess, userCreateError]);
  const handleSave = () => {
    if (invalidEmail) return;
    if (isAllFieldFilled) {
      var reqBody = {
        username: userName.toLowerCase(),
        firstname: firstName,
        lastname: lastName,
        siteid: icscSite,
        userroletype: ICSC,
        useremail: email.toLowerCase(),
      };
      props.requestUserCreate(reqBody);
    }
  };
  return (
    <div className="site-details-parent-container">
      <div className="details-holder">
        <div className="new-user">
          <label>New ICSC</label>
          <hr className="new-user-dash"></hr>
        </div>
      </div>
      <div style={{ marginLeft: "7%" }}>
        {!isAllFieldFilled && isSaveButtonClicked && (
          <p className="error_msg">
            Please complete all mandatory fields before saving.
          </p>
        )}
      </div>
      <div className="details-container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <div className="md-form mt-0">
                <input
                  type="text"
                  className={
                    "form-control input-custom custom-style shadow-none" +
                    " " +
                    (!firstName && isSaveButtonClicked ? "border-red" : "")
                  }
                  placeholder={languageSet.first_name}
                  name="firstname"
                  onChange={(evt) => {
                    setFirstName(evt.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="md-form mt-0">
                <input
                  type="text"
                  className={
                    "form-control input-custom custom-style shadow-none" +
                    " " +
                    (!lastName && isSaveButtonClicked ? "border-red" : "")
                  }
                  placeholder={languageSet.last_name}
                  name="lastname"
                  onChange={(evt) => {
                    setLastName(evt.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="details-container custom-style">
        <div className="row">
          <div className="col">
            <div className="md-form mt-0" style={{ paddingLeft: "2%" }}>
              <input
                type="text"
                className={
                  "form-control input-custom custom-style shadow-none" +
                  " " +
                  ((!userName || invalidUsername) && isSaveButtonClicked
                    ? "border-red"
                    : "")
                }
                placeholder={languageSet.username}
                name="username"
                onChange={(evt) => {
                  setUserName(evt.target.value);
                  setInvalidUsername(false);
                  setUserNameExists(false);
                }}
              />
            </div>
            {userNameExists && isSaveButtonClicked && userName && (
              <p className="error_msg">
                {userName} {languageSet.unique_username}
              </p>
            )}
            {invalidUsername && isSaveButtonClicked && userName && (
              <p className="error_msg">
                {languageSet.username_format_validation}
              </p>
            )}
          </div>
          <div className="col">
            <div className="md-form mt-0" style={{ paddingRight: "2%" }}>
              <input
                type="text"
                className={
                  "form-control input-custom custom-style shadow-none" +
                  " " +
                  (invalidEmail && isSaveButtonClicked ? "border-red" : "")
                }
                placeholder="Email ID (OPTIONAL)"
                name="emailid"
                onChange={(evt) => {
                  setEmail(evt.target.value);
                  setInvalidEmail(false);
                }}
              />
            </div>
            {invalidEmail && isSaveButtonClicked && (
              <p className="error_msg">Please enter a valid email address.</p>
            )}
          </div>
        </div>
      </div>
      <div className="save-cancel-button-style">
        <div className="save-cancel-pos">
          <button
            type="login"
            className="btn btn-primary cancel-btn custom style-custom button-style shadow"
            onClick={handleAccounts}
          >
            CANCEL
          </button>
          <button
            type="save"
            className="btn btn-primary login-button custom-style shadow"
            onClick={() => {
              setIsSaveButtonClicked(true);
              handleSave();
            }}
            disabled={loading}
          >
            {loading && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    approverAccount: state.approverAccount,
    localization: state.localization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestUserCreate: (req) => dispatch(userCreateInit(req)),
    requestUserCreateReset: (req) => dispatch(getUserCreateReset(req)),
    requestAccountsPage: (approverAccounts) =>
      dispatch(displayAccounts(approverAccounts)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsNewICSCDetails));
