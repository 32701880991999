import * as actionTypes from "./action_types";

export const initAdminApproverPassReset = (data) => {
  return {
    type: actionTypes.ADMIN_APPROVER_PASSWORD_RESET_INIT,
    ...data,
  };
};

export const adminApproverPassResetSuccess = (payload) => {
  return {
    payload,
    type: actionTypes.ADMIN_APPROVER_PASSWORD_RESET_SUCCESS,
  };
};

export const adminApproverPassResetFail = (data) => {
  return {
    payload: data,
    type: actionTypes.ADMIN_APPROVER_PASSWORD_RESET_FAIL,
  };
};

export const adminApproverPassResetStateReset = (data) => {
  return {
    payload: data,
    type: actionTypes.ADMIN_APPROVER_PASSWORD_RESET_STATE_RESET,
  };
};
