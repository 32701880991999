import React, { useEffect } from "react";
import first from "../../../assets/First.png";
import flightHighlight from "../../../assets/second_with_state.png";
import statusPending from "../../../assets/status_pending.png";
import statusRejected from "../../../assets/status_rejected.png";
import WizardIcon from "../../../components/fueler-dashboard/wizard-icon/wizardicon";
import dashIcon from "../../../assets/Dot.png";
import PendingIcon from "../../../assets/pending_icon.png";
import RejectingIcon from "../../../assets/rejected_icon.png";
import "./application-status-pending-rejected.css";
import fillApplication from "../../../assets/fill_application.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Header from "../../../components/header/header";
import { ADMIN_ID } from "../../../common/constants";
import { displayAdminApplication } from "../../../store/actions";

const ApplicationStatusPendingRejected = (props) => {
  var notification = props.history.location.state;
  if (!notification) notification = props.notification;

  const handleNewApplicationOnClick = () => {
    if (ADMIN_ID !== localStorage.getItem("rWDKTId"))
      props.history.push("/home");
    else {
      props.requestAdminApplicationPage();
    }
  };

  const { languageSet } = props.localization;
  return (
    <div className="outer-contain-main">
      {ADMIN_ID !== localStorage.getItem("rWDKTId") ? <Header /> : ""}
      <div className="imagecontainer">
        <WizardIcon icon={first} text={languageSet.flight_n_route} />
        <div className="dash-style">
          <img className="dash-img" src={dashIcon} />
        </div>
        <WizardIcon icon={flightHighlight} text={languageSet.flight_details} />
        <div className="dash-style">
          <img className="dash-img" src={dashIcon} />
        </div>
        {notification.application_response === "pending" ? (
          <WizardIcon icon={statusPending} text={languageSet.request_status} />
        ) : (
          <WizardIcon icon={statusRejected} text={languageSet.request_status} />
        )}
      </div>
      <div className="parent-outer-container">
        <div className="outer-border">
          <div
            className={
              localStorage.getItem("rWDKTId") === ADMIN_ID &&
              !notification.submitFlow
                ? ""
                : "border-box"
            }
          >
            {notification.application_response === "pending" ? (
              <img src="tick-img" src={PendingIcon} />
            ) : (
              <img src="tick-img" src={RejectingIcon} />
            )}
            <div>
              {notification.application_response === "pending" ? (
                <label className="status-style" style={{ marginLeft: "1vw" }}>
                  {languageSet.pending_title}
                </label>
              ) : (
                <label className="status-style" style={{ marginLeft: "1vw" }}>
                  {languageSet.rejected_title}
                </label>
              )}
            </div>
          </div>
        </div>

        {(localStorage.getItem("rWDKTId") === ADMIN_ID &&
          !notification.submitFlow) ||
          (notification.application_response === "pending" ? (
            <div>
              <div className="text-style">
                <label className="text-label"></label>SENT TO APPROVER FOR
                APPROVALs.
                <br></br>
                {languageSet.view_notification}
              </div>
              <div className="message-style">
                <label className="text-label"></label>
                {notification.application_response === "pending"
                  ? languageSet.pending_status
                  : languageSet.rejection_for_policy}
              </div>
            </div>
          ) : (
            <div className="message-style">
              <label className="text-label">
                {notification.application_response === "pending"
                  ? notification.rejectCuba
                    ? languageSet.rejection_for_cuba
                    : languageSet.pending_status
                  : notification.rejectCuba
                  ? languageSet.rejection_for_cuba
                  : languageSet.rejection_for_policy}
              </label>
            </div>
          ))}
        {(localStorage.getItem("rWDKTId") === ADMIN_ID &&
          !notification.submitFlow) || (
          <div className="filling-new">
            <img className="fill-img" src={fillApplication} />
            <a
              style={{
                textDecoration: "none",
                color: "#034C87",
                fontFamily: "Futura-Medium",
                fontSize: "15px",
                margin: "1vh",
                marginTop: "2vh",
                cursor: "pointer",
              }}
              onClick={handleNewApplicationOnClick}
            >
              {languageSet.fill_new_appliction}
            </a>
          </div>
        )}

        <div
          className={
            localStorage.getItem("rWDKTId") === ADMIN_ID &&
            !notification.submitFlow
              ? "outer-border-box"
              : "gone-view"
          }
        >
          <div className="border-box-custom">
            <div className="inside-data">
              <label className="para-style">
                {"Application ID" + ": " + notification.audit_number}
              </label>
              <label className="para-style">
                {languageSet.flight_reg_number + ": " + notification.flight_no}
              </label>
              <label className="para-style">
                {"Submitted username" + ": " + notification.created_by}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestAdminApplicationPage: (adminAccounts) =>
      dispatch(displayAdminApplication(adminAccounts)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApplicationStatusPendingRejected));
