import * as actiontypes from "./action_types";

export const initLogin = data => {
  return {
    type: actiontypes,
    ...data
  };
};

export const displayReports = reports => {
  return {
    payload: reports,
    type: actiontypes.APPROVER_REQUEST_LIST
  };
};

export const displayDetails = reportsDetails => {
  return {
    payload: reportsDetails,
    type: actiontypes.APPROVER_DETAILS
  };
};

export const displayActionTaken = action => {
  return {
    payload: action,
    type: actiontypes.APPROVER_ACTION_TAKEN
  };
};
export const displayAccounts = accounts => {
  return{
    payload: accounts,
    type: actiontypes.APPROVER_ACCOUNTS
  };
}
export const getApproverRequestList = payload => {
  return {
    payload: payload,
    type: actiontypes.APPROVER_REQUEST_LIST
  };
};

export const getApproverRequestListStart = payload => {
  return {
    payload: payload,
    type: actiontypes.APPROVER_REQUEST_GET_LIST_START
  };
};

export const getApproverRequestListSuccess = payload => {
  return {
    payload: payload,
    type: actiontypes.APPROVER_REQUEST_GET_LIST_SUCCESS
  };
};

export const getApproverRequestListFail = payload => {
  return {
    payload: payload,
    type: actiontypes.APPROVER_REQUEST_GET_LIST_FAIL
  };
};

export const getApproverRequestListStop = payload => {
  return {
    payload: payload,
    type: actiontypes.APPROVER_REQUEST_GET_LIST_STOP
  };
};

export const approverResponseInit = payload => {
  return {
    payload: payload,
    type: actiontypes.APPRROVER_RESPONSE_INIT
  };
};

export const getApproverResponseSuccess = payload => {
  return {
    payload: payload,
    type: actiontypes.APPRROVER_RESPONSE_SUCCESS
  };
};

export const getApproverResponseFail = payload => {
  return {
    payload: payload,
    type: actiontypes.APPRROVER_RESPONSE_FAIL
  };
};

export const getApproverResponseReset = payload => {
  return {
    payload: payload,
    type: actiontypes.APPRROVER_RESPONSE_RESET
  };
};

export const getReportsPage = reportsPage => {
  return {
    payload: reportsPage,
    type: actiontypes.APPROVER_REPORTS
  };
};

export const getBroadcastPage = broadcastPage => {
  return {
    payload: broadcastPage,
    type: actiontypes.APPROVER_BROADCAST_MESSAGE
  };
};