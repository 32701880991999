import defaultLanguage from "../../assets/langDefault/langEnglish.json";
import { AUTH_KEY, REFRESH_KEY } from "../../common/constants";
import CryptoJS from "crypto-js";

const headerDefault = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": process.env.API_DOMAIN,
  "Access-Control-Allow-Methods": "POST, OPTIONS",
  "Access-Control-Allow-Credentials": true,
};

const getVarObj = {
  get BearerAuthorization() {
    return "Bearer " + localStorage.getItem(AUTH_KEY);
  },
  get BearerRefresh() {
    return "Bearer " + localStorage.getItem(REFRESH_KEY);
  },
  get userId() {
    return localStorage.getItem("iIF7LEd");
  },
  get refreshToken() {
    return localStorage.getItem(REFRESH_KEY);
  },
  get refreshAccessToken() {
    return localStorage.getItem(AUTH_KEY);
  },
};

export const getLocalization = {
  get localizationJson() {
    var retrievedObject = localStorage.getItem("localization");
    if (retrievedObject) return JSON.parse(retrievedObject);
    else return defaultLanguage[0];
  },
};

//**********************localization Post req ***************/
export const localizationRequest = async (languageType) => {
  const response = await fetch(
    process.env.REACT_APP_API_LOCALIZATION + languageType,
    {
      method: "GET",
      headers: headerDefault,
    }
  );
  return response;
};

//**********************login Post req ***************/
export const loginRequest = async (data) => {
  const response = await fetch(process.env.REACT_APP_API_LOGIN, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });
  return response;
};
//**********************user details ***************/
export const getUserDetailsRequest = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_USERDETAILS + "userid=" + getVarObj.userId,

    {
      method: "GET",
      headers: headerDefault,
    }
  );

  return response;
};
//**********************airport details ***************/
export const getAirportRequest = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_AIRPORT_DETAILS,

    {
      method: "GET",
      headers: headerDefault,
    }
  );

  return response.json();
};
//**********************aircraft  details ***************/
export const getAircraftRequest = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_AIRCRAFT_DETAILS,

    {
      method: "GET",
      headers: headerDefault,
    }
  );

  return response.json();
};
//**********************customer name***************/
export const getCustomerName = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_CUSTOMERNAME,

    {
      method: "GET",
      headers: headerDefault,
    }
  );

  return response.json();
};

//**********************logout new Post req ***************/
export const logoutAPI = async () => {
  const data = {
    access_token: getVarObj.refreshAccessToken,
    refresh_token: getVarObj.refreshToken,
    userid: getVarObj.userId,
  };
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_LOGOUT, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });
  return response;
};

//**********************updateUser Post req ***************/
export const updateUser = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_UPDATE_USER_DETAILS, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });
  return response;
};

//**************Notification WALL GET*************/

export const getNotificationList = async (isCount) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    `${
      process.env.REACT_APP_API_NOTIFICATION_WALL
    }userid=${localStorage.getItem("iIF7LEd")}&count= ${isCount}`,
    {
      method: "GET",
      headers: headerDefault,
    }
  );

  return response;
};

//*************password reset********/

export const passwordResetRequest = async (data) => {
  const response = await fetch(
    process.env.REACT_APP_API_PASSWORD_RESET,

    {
      method: "POST",
      headers: {
        headerDefault,
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};

//*************Health checkup********/
export const healthCheckRequest = async (data) => {
  var cipherName = CryptoJS.AES.encrypt(
    data.username,
    process.env.REACT_APP_ENCRYPT_KEY
  ).toString();
  const response = await fetch(
    process.env.REACT_APP_API_HEALTH_CHECK,

    {
      method: "POST",
      headers: headerDefault,
      body: JSON.stringify({
        username: cipherName,
      }),
    }
  );

  return response;
};
//*************Approver Create User********/

export const createUser = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_CREATE_USER, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });

  return response;
};

//*************Airport list********/

export const getAirportList = async (data) => {
  const response = await fetch(process.env.REACT_APP_API_AIRPORT_DETAILS, {
    method: "GET",
    headers: headerDefault,
  });

  return response;
};

//*************Site list********/

export const getSiteList = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_SITELIST, {
    method: "GET",
    headers: headerDefault,
  });

  return response;
};

//*************Approver Request list********/

export const getApproverRequestList = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_APPROVER_APPLICATIONS_LIST,
    {
      method: "GET",
      headers: headerDefault,
    }
  );

  return response;
};
//*************Approver Create Site********/
export const createSite = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_CREATE_SITE, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });

  return response;
};

//*************Approver dash board count********/
export const getApproverDashboardCount = async () => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_APPROVER_COUNT + getVarObj.userId,
    {
      method: "GET",
      headers: headerDefault,
    }
  );
  return response;
};

//*************Approver user search********/
export const userSearch = async (searchItem) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_USER_SEARCH +
      searchItem +
      "&userid=" +
      getVarObj.userId,
    {
      method: "GET",
      headers: headerDefault,
    }
  );
  return response;
};

//*************Approver Delete user ********/
export const deleteUser = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_DELETE_USER, {
    method: "DELETE",
    headers: headerDefault,
    body: JSON.stringify(data),
  });
  return response;
};

/************************Approver Reports**************************/

export const getApproverReports = async (payload) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_APPROVER_REPORTS +
      "startdate=" +
      payload.startdate +
      "&enddate=" +
      payload.enddate +
      "&status=" +
      payload.status +
      "&userid=" +
      getVarObj.userId,
    {
      method: "GET",
      headers: headerDefault,
    }
  );
  return response;
};

/************************Approver Creation of Broadcast**************************/

export const createBroadcast = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_CREATE_BROADCAST, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });

  return response;
};

/************************ Get T&C countries**************************/
export const getTcCountries = async (payload) => {
  const response = await fetch(
    process.env.REACT_APP_API_TC_COUNTRIES +
      "type=" +
      payload.type +
      "&enabled=" +
      payload.enabled,
    {
      method: "GET",
    }
  );
  return response;
};

/************************ Get T&C pdf**************************/
export const getTcPdf = async (payload) => {
  const response = await fetch(
    process.env.REACT_APP_API_TC_COUNTRIES + payload,
    {
      method: "GET",
    }
  );
  return response;
};

/************************Approver Get countries**************************/
export const getCountries = async (searchItem) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_GET_COUNTRIES, {
    method: "GET",
    headers: headerDefault,
  });
  return response;
};

/************************Approver Update**************************/
export const updateSites = async (siteUpdateItem) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_SITE_UPDATE, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(siteUpdateItem),
  });
  return response;
};
/************************Fueler password reset**************************/
export const fuelerPasswordReset = async (siteUpdateItem) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(
    process.env.REACT_APP_API_FUELER_PASSWORD_RESET,
    {
      method: "POST",
      headers: headerDefault,
      body: JSON.stringify(siteUpdateItem),
    }
  );
  return response;
};

/************************Delete Site**************************/
export const deleteSiteApi = async (data) => {
  headerDefault.Authorization = getVarObj.BearerAuthorization;
  const response = await fetch(process.env.REACT_APP_API_DELETE_SITE, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });
  return response;
};

/************************Delete Site**************************/
export const refreshApi = async (data) => {
  headerDefault.Authorization = getVarObj.BearerRefresh;
  const response = await fetch(process.env.REACT_APP_REFRESH, {
    method: "POST",
    headers: headerDefault,
    body: JSON.stringify(data),
  });
  return response;
};
