import * as actiontypes from "../actions";

const initialState = {
  payload: {}
};

export const reducerQuestionnaireValidation = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actiontypes.QUSESTIONNAIRE_VALIDATION_RESET:
      return { ...state, questionnaireValidationSuccess: {}, error: {} };

    case actiontypes.QUSESTIONNAIRE_VALIDATON_INIT:
      return { ...state, payload: action.payload };

    case actiontypes.QUSESTIONNAIRE_VALIDATON_SUCCESS:
      return {
        ...state,
        questionnaireValidationSuccess: action.payload.questionnaire,
        payload: {}
      };

    case actiontypes.QUSESTIONNAIRE_VALIDATON_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
