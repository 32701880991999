import * as actiontypes from "../actions";

const initialState = {
  payload: {},
  successLoad: {},
  initialDataLoading: false,
};

export const reducerFuelerInitialData = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.FUELER_INIT:
      return { ...state, payload: action.payload, initialDataLoading: true };

    case actiontypes.FUELER_SUCCESS:
      return {
        ...state,
        successLoad: action.payload.success,
        airportList: action.payload.airportList,
        aircraftList: action.payload.aircraftList,
        customerList: action.payload.customerList,
        userId: action.payload.userDetails.userid,
        siteDetails: action.payload.userDetails.sitedetails,
        userRoleId: action.payload.userDetails.userroleid,
        iCSCSites: action.payload.iCSCSites,
        initialDataLoading: false,
      };

    case actiontypes.FUELER_FAIL:
      return { ...state, error: action.payload, initialDataLoading: false };

    default:
      return state;
  }
};
