import { put, delay } from "redux-saga/effects";
import * as actions from "../actions";
import { getNotificationList } from "../sagas/API";
import * as actionTypes from "../actions/action_types";

export function* notificationList(action) {
  try {
    const response = yield getNotificationList(action.count);
    switch (response.status) {
      case 200:
        const responseData = yield response.json();
        yield put(
          actions.notificationListSuccess(responseData.payload.notificationlist)
        );
        break;
      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.count,
                actionTypes.NOTIFICATIONLIST_INIT
              )
            );
          } else {
            yield put(actions.notificationListFail("UnAuthorized access"));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        } 
      

        break;
      case 400:
        yield put(actions.notificationListFail("Invalid userid"));
        break;
      case 500:
        yield put(actions.notificationListFail("Internal server error"));
        break;
      default:
        yield put(actions.notificationListFail("unknown error"));
        break;
    }
  } catch (e) {
    yield put(actions.notificationListFail("unknown error"));
  }
}

const fetchBadgeNo = async (data) => {
  const response = await fetch(
    process.env.REACT_APP_API_NOTIFICATION_COUNT +
      `userid=${localStorage.getItem("iIF7LEd")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, OPTIONS",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + localStorage.getItem("auth_key"),
      },
      body: JSON.stringify(data),
    }
  );

  return response;
};
export function* notificationWallBadge(action) {
  while (true) {
    try {
      const response = yield fetchBadgeNo();
      switch (response.status) {
        case 200:
          const responseData = yield response.json();
          yield put(
            actions.notificationWallCountTimer(
              responseData.payload.count.notificationCount
            )
          );
          break;
        case 401:
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(actions.initRefresh("",actionTypes.NOTIFICATION_WALL_START_TIMER));
          }

          break;
        case 400:
          yield put(actions.notificationWallTimerFail("Invalid userid"));
          break;
        case 500:
          yield put(actions.notificationWallTimerFail("Internal server error"));
          break;
        default:
          yield put(actions.notificationWallTimerFail("unknown error"));
          break;
      }
    } catch (e) {
      yield put(actions.notificationListFail(e));
      break;
    }

    yield delay(5000);
  }
}
