import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setInitialState } from "../../store/actions/";
const SnackBarView = (props) => {
  const { snackMsg, severity, isSnackBarActivated } = props.snackMessage;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isSnackBarActivated) return;
    setOpen(true);
    props.requestInitialState(); //reInitialize the state
  }, [isSnackBarActivated]);

  return (
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={1500}
    >
      <Alert onClose={() => setOpen(false)} severity={severity}>
        {snackMsg}
      </Alert>
    </Snackbar>
  );
};
const mapStateToProps = (state) => {
  return state;
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestInitialState: () => dispatch(setInitialState()),
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SnackBarView));
