import React from "react";
import { connect } from "react-redux";
import "./user-creation-successful.css";
import SymbolCorrect from "../../../assets/symbol_correct.png";
import { withRouter } from "react-router";
const UserCreationSuccessful = (props) => {
  const { languageSet } = props.localization;
  return (
    <div className="user-success-container">
      <div className="user-success-inside shadow">
        <div className="created-msg-holder">
          <img className="symbol_created" src={SymbolCorrect} alt="" />
          {props.siteMessage ? (
            <label>SITE SUCCESSFULLY CREATED</label>
          ) : (
            <label>USER ACCOUNT SUCCESSFULLY CREATED</label>
          )}
          <br />
          <div id="newaccount">
            <a
              style={{
                cursor: "pointer",
                color: "#003C88",
              }}
              onClick={() => {
                props.successAction(false);
              }}
            >
              {languageSet.create_new_account}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserCreationSuccessful));
