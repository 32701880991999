import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./approver-application-view-details.css";
import ApproveTick from "../../../../assets/approve_tick.png";
import RejectCross from "../../../../assets/reject_cross.png";
import emptyCheckIcon from "../../../../assets/empty_check.png";
import CheckIcon from "../../../../assets/check_tick.png";
import {
  getDateLocal,
  getTimeLocal,
} from "./approver-application-view-details-utils";
import {
  approverResponseInit,
  displayActionTaken,
} from "../../../../store/actions";
import { SHELL_CUSTOMER } from "../../../../common/constants";
const ApproverApplicationViewDetails = (props) => {
  const labelStyle = {
    color: "#7F7F7FEB",
    fontSize: "12px",
  };
  const labelSizeStyle = {
    fontSize: "12px",
    marginBottom: "3vh",
  };

  const labelSizeStyleColorRed = {
    fontSize: "12px",
    marginBottom: "3vh",
    color: "#FF0000",
  };
  const smallSizeLabelStyle = {
    fontSize: "13px",
  };

  const smallSizeLabelStyleColorRed = {
    fontSize: "13px",
    color: "#FF0000",
  };
  const smallLabelStyle = {
    fontSize: "11px",
  };
  const destinationStyle = {
    color: "#7F7F7FEB",
    fontSize: "12px",
    marginTop: "3vh",
  };
  const commentStyle = {
    color: "#7F7F7FEB",
    fontSize: "13px",
  };
  const FromToLabelStyle = {
    color: "#898989",
    fontSize: "12px",
    fontFamily: "Futura-Book",
  };

  const FromToLabel = {
    fontSize: "13px",
    paddingBottom: "2vh",
  };

  const FromToLabelColorRed = {
    fontSize: "13px",
    paddingBottom: "2vh",
    color: "#FF0000",
  };
  const [comment, setComment] = useState("");
  const [approved, setApproved] = useState(true);
  const { applicationItem, responseSuccess, responseError, responseLoading } =
    props.approverRequest || {};
  const { languageSet } = props.localization;
  const [isCargoRejectReason, setIsCargoRejectReason] = useState(false);
  const [isMilitary, setIsMilitary] = useState(false);
  const [whichButton, setWhichButton] = useState();
  const [
    isMiltaryCargoNotIntendedForUseAtDestination,
    setIsMiltaryCargoNotIntendedForUseAtDestination,
  ] = useState(false);
  const [
    isShellCustomerRejectReason,
    setIsShellCustomerRejectReason,
  ] = useState(false);
  const [isRegNoRejectReason, setIsRegNoRejectReason] = useState(false); //Maybe isAircraftRegNoOnSanctionedList or doesAircraftRegPrefixOnSanctionedList
  const [isEndUserSubjectToSanction, setIsEndUserSubjectToSanction] = useState(
    false
  );

  const [isPassengerSanctioned, setIsPassengerSanctioned] = useState(false);

  //Registration Number
  useEffect(() => {
    if (!applicationItem.status_rules) return;

    //check if isDestinationCountryCuba
    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isDestinationCountryCuba"
      ) ||
      applicationItem.status_rules.reject_reasons.includes(
        "isDestinationCountryGEC"
      ) ||
      applicationItem.status_rules.reject_reasons.includes(
        "isMilitaryCargoHeadingToAnyDestinationWithRussia"
      )
    ) {
      //GEC will in base on YES or NO
      //Destination is based on reject_countries
      if (applicationItem.question_data[0].cargo.isorginatefromgec) {
        setIsCargoRejectReason(true);
      }
    }

    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isMilitaryCargoHeadingToAnyDestinationWithRussia"
      )
    ) {
      setIsMilitary(true);
      //GEC will in base on YES or NO
      //Destination is based on reject_countries
      if (applicationItem.question_data[0].cargo.isorginatefromgec) {
        setIsCargoRejectReason(true);
      }
    }

    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isNatureOfFlightCargoAndNoLicense"
      )
    ) {
      //License will in base on YES or NO
      if (applicationItem.question_data[0].cargo.isorginatefromgec) {
        setIsCargoRejectReason(true);
      }
    }

    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isNatureOfFlightCargoAndOriginCountryIsGEC"
      )
    ) {
      if (applicationItem.question_data[0].cargo.isorginatefromgec) {
        setIsCargoRejectReason(true);
      }
    }

    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isResellerCustomerOnSanctionedList"
      )
    ) {
      setIsShellCustomerRejectReason(true);
      setIsEndUserSubjectToSanction(true);
    }

    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isAircraftRegNoOnSanctionedList"
      ) ||
      applicationItem.status_rules.reject_reasons.includes(
        "doesAircraftRegPrefixOnSanctionedList"
      )
    ) {
      setIsRegNoRejectReason(true);
    }
    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isMiltaryCargoNotIntendedForUseAtDestination"
      )
    ) {
      setIsMiltaryCargoNotIntendedForUseAtDestination(true);
      setIsMilitary(true);
      if (applicationItem.question_data[0].cargo.isorginatefromgec) {
        setIsCargoRejectReason(true);
      }
    }

    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isEndUserSubjectToSanction"
      )
    ) {
      setIsEndUserSubjectToSanction(true);
    }

    if (
      applicationItem.status_rules.reject_reasons.includes(
        "isCustomerEnteredManually"
      )
    ) {
      setIsEndUserSubjectToSanction(true);
      setIsShellCustomerRejectReason(true);
    }

    if (applicationItem.question_data[0].passenger.ispassengersanctioned) {
      setIsPassengerSanctioned(true);
    }
  }, []);

  const handleResponseClick = (value) => {
    setWhichButton(value);
    var response = applicationItem;
    response.modified_on = new Date();
    response.modified_by = localStorage.getItem("nWDF18M");
    response.comment = comment;
    if (value === "approved") {
      response.final_status = "approved";
    } else {
      response.final_status = "rejected";
      setApproved(false);
    }
    props.requestApproverResponse(response);
  };
  useEffect(() => {
    if (responseSuccess) {
      if (approved) {
        props.requestActionTaken("approved");
      } else {
        props.requestActionTaken("rejected");
      }
    }
  }, [responseSuccess]);
  useEffect(() => {
    if (responseError) {
      switch (responseError) {
        case "Updated by other Approver already":
          props.requestActionTaken("conflict");
          break;
        case "UnAuthorized access":
          break;
        case "UInvalid Request body":
          break;
        case "Error in updations":
          break;

        default:
          return;
      }
    }
  }, [responseError]);
  const populateDestinations = () => {
    var elements = [];
    applicationItem.question_data[0].desitnation.map((item, index) => {
      elements.push(
        <div className="destination-details" key={index}>
          <label style={destinationStyle}>Destination ({index + 1})</label>
          <label style={smallSizeLabelStyle}>{item}</label>
          <label
            style={
              applicationItem.status_rules &&
              applicationItem.status_rules.reject_countries &&
              applicationItem.status_rules.reject_countries.includes(
                applicationItem.question_data[0].destinationCountry[index]
              )
                ? smallSizeLabelStyleColorRed
                : smallSizeLabelStyle
            }
          >
            {applicationItem.question_data[0].destinationCountry[index]}
          </label>
        </div>
      );
    });
    return <div>{elements}</div>;
  };
  const populateNatureOfFlight = () => {
    if (applicationItem.question_data[0].natureofflight) {
      switch (applicationItem.question_data[0].natureofflight.toLowerCase()) {
        case "cargo":
          return (
            <div className="que-details">
              <label style={labelStyle}>Nature Of The Flight</label>
              <label
                style={
                  isCargoRejectReason ? labelSizeStyleColorRed : labelSizeStyle
                }
              >
                CARGO
              </label>
              <label style={labelStyle}>
                Does the cargo originate from a GEC country ?
              </label>
              <label
                style={
                  applicationItem.question_data[0].cargo.isorginatefromgec
                    ? labelSizeStyleColorRed
                    : labelSizeStyle
                }
              >
                {applicationItem.question_data[0].cargo.isorginatefromgec
                  ? "YES"
                  : "NO"}
              </label>
              <label style={labelStyle}>License to carry the goods ?</label>
              <label
                style={
                  applicationItem.question_data[0].cargo.islicensetocarrygoods
                    ? labelSizeStyle
                    : labelSizeStyleColorRed
                }
              >
                {applicationItem.question_data[0].cargo.islicensetocarrygoods
                  ? "YES"
                  : "NO"}
              </label>
              <label style={labelStyle}>Nature of the cargo</label>
              <label
                style={isMilitary ? labelSizeStyleColorRed : labelSizeStyle}
              >
                {applicationItem.question_data[0].cargo.natureofcargo}
              </label>

              {applicationItem.question_data[0].cargo.natureofcargo.toLowerCase() ===
              "military" ? (
                <div>
                  <label style={labelStyle}>
                    Is the cargo for use in end destination ?
                  </label>
                  <label
                    style={
                      isMiltaryCargoNotIntendedForUseAtDestination
                        ? labelSizeStyleColorRed
                        : labelSizeStyle
                    }
                  >
                    {applicationItem.question_data[0].cargo
                      .cargotouseinenddestination
                      ? " YES"
                      : " NO"}
                  </label>
                </div>
              ) : null}
              <div className="check_message">
                <img className="empty-img" src={emptyCheckIcon} />
                <img className="check-img" src={CheckIcon} />
                <label style={{ margin: "1vh" }}>
                  Confirm the information entered is checked and correct.
                </label>
              </div>
            </div>
          );
          break;
        case "passenger":
          return (
            <div className="que-details">
              <label style={labelStyle}>Nature Of The Flight</label>
              <label style={labelSizeStyle}>PASSENGER</label>
              <label style={labelStyle}>
                Are the passengers subject to any list of sanctioned parties ,
                including but not limited to those maintained by the United
                Nations, the European Union, The United Kingdom or the U.S
                Office of Foreign assets Controls Specially Designated Nations
                List?
              </label>
              <label
                style={
                  isPassengerSanctioned
                    ? labelSizeStyleColorRed
                    : labelSizeStyle
                }
              >
                {
                  applicationItem.question_data[0].passenger
                    .ispassengersanctioned
                }
              </label>

              <div className="check_message">
                <img className="empty-img" src={emptyCheckIcon} />
                <img className="check-img" src={CheckIcon} />
                <label style={{ margin: "1vh" }}>
                  Confirm the information entered is checked and correct.
                </label>
              </div>
            </div>
          );
          break;
        case "ferry":
          return (
            <div className="que-details">
              <label style={labelStyle}>Nature Of The Flight</label>
              <label style={labelSizeStyle}>FERRY</label>
              <div className="check_message">
                <img className="empty-img" src={emptyCheckIcon} />
                <img className="check-img" src={CheckIcon} />
                <label style={{ margin: "1vh" }}>
                  Confirm the information entered is checked and correct.
                </label>
              </div>
            </div>
          );
          break;
        default:
          return;
      }
    } else
      return (
        <div className="que-details">
          <label style={labelStyle}>Nature Of The Flight</label>
          <div className="check_message">
            <img className="empty-img" src={emptyCheckIcon} />
            <img className="check-img" src={CheckIcon} />
            <label style={{ margin: "1vh" }}>
              Confirm the information entered is checked and correct.
            </label>
          </div>
        </div>
      );
  };
  return (
    <div>
      <div className="requests-header">
        <label>REQUESTS</label>
        <label style={{ paddingLeft: "1vw" }}> {">"} </label>
        <div id="app">
          <a>Application</a>
        </div>
      </div>
      <div className="app-details-container shadow">
        <div className="questionnaire-data">
          <div className="app-details">
            <label style={{ fontSize: "18px" }}>
              Application id: {applicationItem.auditnumber}
            </label>
            <label style={{ fontSize: "15px" }}>
              {" "}
              From: {applicationItem.question_data[0].created_by_name}
            </label>
            <label style={{ color: "#595959", fontSize: "14px" }}>
              {getTimeLocal(applicationItem.created_on)}
            </label>
            <label style={{ marginTop: "3vh", fontSize: "13px" }}>
              Status:{" "}
              <label style={{ color: "#003C88", fontSize: "13px" }}>
                {applicationItem.status_while_requesting}
              </label>
            </label>
          </div>
          <hr className="line-custom style"></hr>
          <div className="comment-box">
            <div className="comment-msg">
              <label style={commentStyle}>Comment By Fueler</label>
              <label style={{ fontSize: "12px" }}>
                {applicationItem.question_data[0].comment
                  ? applicationItem.question_data[0].comment
                  : null}
              </label>
            </div>
          </div>
          <div className="site-details">
            <label style={smallSizeLabelStyle}>
              {applicationItem.question_data[0].site}
            </label>
            <div className="headings">
              <table>
                <tr>
                  <td>{languageSet.flight_no}</td>
                  <td>Aircraft Type</td>
                  <td>Shell Customer</td>
                </tr>
                <tr style={{ color: "#404040" }}>
                  <td
                    style={{
                      color: isRegNoRejectReason ? "#FF0000" : "#404040",
                    }}
                  >
                    {applicationItem.question_data[0].registernumber}
                  </td>
                  <td>{applicationItem.question_data[0].aircrafttype}</td>
                  <td
                    style={{
                      color: isShellCustomerRejectReason
                        ? "#FF0000"
                        : "#404040",
                    }}
                  >
                    {applicationItem.question_data[0].shellcustomername === ""
                      ? applicationItem.question_data[0].othername
                      : applicationItem.question_data[0].shellcustomername}
                  </td>
                </tr>
              </table>
            </div>
            {applicationItem.question_data[0].type !== SHELL_CUSTOMER && (
              <div className="ques">
                <label>
                  Is end user subject to or designated under sanctions including
                  but not limited to those maintained by the United Nations the
                  European Union The United Kingdom or the US counting those
                  specified by the US Office of foreign Assets Controls such as
                  the specially designated nations list?
                </label>
              </div>
            )}
            {applicationItem.question_data[0].type !== SHELL_CUSTOMER && (
              <label
                style={
                  isEndUserSubjectToSanction ? FromToLabelColorRed : FromToLabel
                }
              >
                {applicationItem.question_data[0].isenduserundersanction
                  ? "YES"
                  : "NO"}
              </label>
            )}
            <label style={{ color: "#898989", fontSize: "11px" }}>
              In case of multiple destinations.{" "}
            </label>
            <div className="total-dest">
              <label style={{ color: "#898989" }}>
                Select total number of destinations.
              </label>
              <label>
                {applicationItem.question_data[0].desitnation.length}
              </label>
            </div>
            <div className="other-details">
              <label>Origin</label>
              <label>Departure</label>
            </div>
            <div className="detail-date-values">
              <label style={FromToLabelStyle}>From</label>
              <label>
                {getDateLocal(applicationItem.question_data[0].departure)}
              </label>
            </div>
            <div className="destination-details">
              <label style={smallSizeLabelStyle}>
                {" "}
                {applicationItem.question_data[0].origin}
              </label>
              <label style={smallLabelStyle}>
                {applicationItem.question_data[0].origin_Country}
              </label>
              {populateDestinations()}
            </div>
          </div>
        </div>
      </div>
      <div className="app-details-container-custom shadow">
        {populateNatureOfFlight()}
      </div>
      <div className="input-style">
        <div className="input-group input-group-addon custom-input shadow">
          <input
            placeholder="Write a comment. (Optional)"
            type="text"
            className="form-control input-group-addon custom-input-comment"
            aria-label="Text input with checkbox"
            onChange={(evt) => {
              setComment(evt.target.value);
            }}
          />
        </div>
      </div>
      <div className="button-styling approv-reject">
        <div className="button-pos">
          <button
            type="approve"
            disabled={responseLoading}
            className="btn btn-primary approve-btn custom shadow-none"
            onClick={() => handleResponseClick("approved")}
          >
            <div className="tick_style">
              <img className="tick-img-style" src={ApproveTick} />
            </div>
            {responseLoading && whichButton === "approved" && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Approve
          </button>
          <button
            type="reject"
            className="btn btn-primary reject-btn custom shadow-none"
            disabled={responseLoading}
            onClick={() => handleResponseClick("rejected")}
          >
            <div className="tick_style">
              <img className="tick-img-style" src={RejectCross} />
            </div>
            {responseLoading && whichButton === "rejected" && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Reject
          </button>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestApproverResponse: (req) => dispatch(approverResponseInit(req)),
    requestActionTaken: (req) => dispatch(displayActionTaken(req)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverApplicationViewDetails));
