import * as actionTypes from "../actions";

const initialState = {
  payload: "",
  passwordReset: "",
  passwordResetError: "",
  passResetLoading: false,
};

export const reducerAdminApproverpassReset = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_APPROVER_PASSWORD_RESET_INIT:
      return { ...state, payload: action.payload, passResetLoading: true };

    case actionTypes.ADMIN_APPROVER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordReset: action.payload,
        error: "",
        passResetLoading: false,
      };

    case actionTypes.ADMIN_APPROVER_PASSWORD_RESET_FAIL:
      return {
        ...state,
        passwordResetError: action.payload,
        passResetLoading: false,
      };

    case actionTypes.ADMIN_APPROVER_PASSWORD_RESET_STATE_RESET:
      return {
        initialState,
      };

    default:
      return state;
  }
};
