import * as actionTypes from "../../../actions";

const initialState = {
  payload: "",
  loading: false,
};

export const reducerBroadcastCountries = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_BROADCAST_GET_COUNTRY_INIT:
      return {
        payload: action.payload,
        loading: true,
      };
    case actionTypes.APPROVER_BROADCAST_GET_COUNTRY_SUCCESS:
      return {
        ...state,
        countryList: action.payload,
        broadcastCountriesError: false,
        loading: false,
      };
    case actionTypes.APPROVER_BROADCAST_GET_COUNTRY_FAIL:
      return {
        ...state,
        countryList: false,
        broadcastCountriesError: true,
        loading: false,
      };
    case actionTypes.APPROVER_BROADCAST_GET_COUNTRY_RESET:
      return {
        countryList: false,
        broadcastCountriesError: false,
        loading: false,
      };

    default:
      return state;
  }
};
