import * as actiontypes from "../actions";

const initialState = {
  payload: {},
  error: {},
  loginLoading: false,
};

export const reducerLogin = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.LOGIN_INIT:
      return { ...state, payload: action.payload, loginLoading: true };

    case actiontypes.LOGIN_SUCCESS:
      return { ...state, success: true, error: "", loginLoading: false };

    case actiontypes.LOGIN_FAIL:
      return { ...state, error: action.payload, loginLoading: false };

    case actiontypes.LOGIN_RESET:
      return { ...state, error: action.payload, loginLoading: false };

    default:
      return state;
  }
};
