import * as actiontypes from "./action_types";

export const initNotificationList = (data) => {
  return {
    type: actiontypes.NOTIFICATIONLIST_INIT,
    ...data,
  };
};

export const notificationListSuccess = (notificationList) => {
  return {
    payload: notificationList,
    type: actiontypes.NOTIFICATIONLIST_SUCCESS,
  };
};

export const notificationListFail = (data) => {
  return {
    payload: data,
    type: actiontypes.NOTIFICATIONLIST_FAILURE,
  };
};

export const notificationWallCountTimer = (notificationWallCountTimer) => {
  return {
    payload: notificationWallCountTimer,
    type: actiontypes.NOTIFICATION_WALL_START_TIMER,
  };
};

export const notificationWallStopTimer = (notificationWallStopTimer) => {
  return {
    payload: notificationWallStopTimer,
    type: actiontypes.NOTIFICATION_WALL_STOP_TIMER,
  };
};

export const notificationWallTimerFail = (notificationWallErrorTimer) => {
  return {
    payload: notificationWallErrorTimer,
    type: actiontypes.NOTIFICATION_WALL_ERROR_TIMER,
  };
};

export const openNotificationPopOver = (data) => {
  return {
    payload: data,
    type: actiontypes.NOTIFICATIONLIST_OPEN,
  };
};
