import * as actionTypes from "../../../actions";
import * as constantTypes from "../../../../common/constants";

const initialState = {
  payload: {},
};

export const reducerFuelerPassReset = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_FUELER_PASSWORD_RESET_INIT:
      return {
        ...state,
        payload: action.payload,
        loading: true,
      };
    case actionTypes.ADMIN_FUELER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
      };
    case actionTypes.ADMIN_FUELER_PASSWORD_RESET_FAIL:
      return {
        ...state,
        error: true,
        success: false,
      };
    case actionTypes.ADMIN_FUELER_PASSWORD_RESET_RESET:
      return {
        ...state,
        error: false,
        success: false,
      };
    default:
      return state;
  }
};
