import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { initLogout, logoutReset } from "../../store/actions/";
import "./logout.css";

const Logout = (props) => {
  const { setLogout, setShowLogout } = props;
  const handleClose = () => setShowLogout(false);
  const { payload, error, loading } = props.logout;
  const { languageSet } = props.localization;
  const [showYesStyle, setShowYesStyle] = useState(false);
  const handleLogout = () => {
    props.requestLogout();
  };
  const handleShowYesStyle = () => {
    if (showYesStyle) {
      setShowYesStyle(false);
    }
  };
  const handleShowNoStyle = () => {
    if (!showYesStyle) {
      setShowYesStyle(true);
    }
  };
  useEffect(() => {
    if (payload === "success") {
      props.requestLogoutReset(); //Reset all state to inital state
      props.history.push("/");
    }
    if (error) {
      handleClose();
    }
  }, [payload, error]);
  return (
    <div className="outer-main">
      <Modal
        show={setLogout}
        onHide={handleClose}
        animation={false}
        style={{ maxHeight: "65%" }}
      >
        <Modal.Body>
          LOG OUT
          <div className="text custom-modal">{languageSet.logout}</div>
          <div></div>
          <div className="button-styling custom">
            <button
              type="login"
              className={
                !showYesStyle
                  ? "btn btn-primary yes-btn custom on-focus focus shadow-none"
                  : "btn btn-primary yes-btn custom non-focus no-focus shadow-none"
              }
              onClick={handleLogout}
              onClickCapture={handleShowYesStyle}
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {languageSet.yes}
            </button>
            <button
              type="login"
              className={
                showYesStyle
                  ? "btn btn-primary no-btn custom on-focus focus shadow-none"
                  : "btn btn-primary no-btn custom non-focus no-focus shadow-none"
              }
              onClick={handleClose}
              onClickCapture={handleShowNoStyle}
            >
              {languageSet.no}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestLogout: () => dispatch(initLogout()),
    requestLogoutReset: () => dispatch(logoutReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));
