import { takeEvery, take, call, race } from "redux-saga/effects";
import * as actionType from "../actions/action_types";
import { login } from "./saga_login";
import { healthCheck } from "./saga_healthcheck";
import { passwordResetAdminApprover } from "./saga_password_reset_adminApprover";
import { fuelerInitialData } from "./saga_fueler_intial_data";
import { questionnaireValidation } from "./saga_questionnaire_validation";
import { questionnaireSubmit } from "./saga_questionnaire_submit";
import {
  notificationList,
  notificationWallBadge,
} from "./saga_notification_list";
import { approverRequest } from "./saga_approver_request";
import { approverResponse } from "./saga_approver_response";
import { logout } from "./saga_logout";
import {
  approverAccountAirportList,
  approverAccountSiteList,
  approverSiteCreate,
  approverUserCreate,
  approverUserSearch,
  approverUserEdit,
  approverUserDelete,
  approverEditSite,
} from "./saga_approver_account";
import { fetchLocalization } from "./saga_localization";

import { getCount } from "./saga_approver_dashboard_count";

import { getReports } from "./reports/approver/saga_reports";

import { broadcastRequest } from "./broadcast/approver/saga_broadcast";

import { broadcastGetCountries } from "./broadcast/approver/saga_get_countries";
import { userDetails } from "./saga_user_details";

import { fuelerPassReset } from "./saga_fueler_password_reset";

import { deleteSite } from "./saga_approver_account";
import { tcGetCountries } from "./tc/saga_get_countries";
import { tcGetPdf } from "./tc/saga_get_tc_pdf";

import {signUp} from "./saga_signup"

import {refreshToken} from "./saga_refresh"

export function* loginSaga() {
  yield takeEvery(actionType.LOGIN_INIT, login);
}

export function* healthCheckSaga() {
  yield takeEvery(actionType.HEALTHCHECK_INIT, healthCheck);
}

export function* passwordResetAdminApproverSaga() {
  yield takeEvery(
    actionType.ADMIN_APPROVER_PASSWORD_RESET_INIT,
    passwordResetAdminApprover
  );
}

export function* fuelerInitialDataSaga() {
  yield takeEvery(actionType.FUELER_INIT, fuelerInitialData);
}

export function* questionnaireValidationSaga() {
  yield takeEvery(
    actionType.QUSESTIONNAIRE_VALIDATON_INIT,
    questionnaireValidation
  );
}

export function* questionnaireSubmitSaga() {
  yield takeEvery(actionType.QUSESTIONNAIRE_SUBMIT_INIT, questionnaireSubmit);
}

export function* notficationListSaga() {
  yield takeEvery(actionType.NOTIFICATIONLIST_INIT, notificationList);
}
export function* approverequestListSaga() {
  //Racing to see if the Stop action has been triggered
  while (true) {
    yield take(actionType.APPROVER_REQUEST_GET_LIST_START);
    yield race([
      call(approverRequest),
      take(actionType.APPROVER_REQUEST_GET_LIST_STOP),
    ]);
    //Adding the break will break the functionality
  }
}

export function* notficationListBadgeSaga() {
  //Racing to see if the Stop action has been triggered
  while (true) {
    yield take(actionType.NOTIFICATION_WALL_START_TIMER);
    yield race([
      call(notificationWallBadge),
      take(actionType.NOTIFICATION_WALL_STOP_TIMER),
    ]);
  }
}

export function* apprroverRResponseSaga() {
  yield takeEvery(actionType.APPRROVER_RESPONSE_INIT, approverResponse);
}

export function* logoutSaga() {
  yield takeEvery(actionType.LOGOUT_INIT, logout);
}

export function* approverAccountAirportListSaga() {
  yield takeEvery(
    actionType.APPROVER_ACCOUNTS_AIRPORT_LIST_INIT,
    approverAccountAirportList
  );
}

export function* approverAccountSiteListSaga() {
  yield takeEvery(
    actionType.APPROVER_ACCOUNTS_SITE_LIST_INIT,
    approverAccountSiteList
  );
}

export function* approverSiteCreateSaga() {
  yield takeEvery(actionType.APPROVER_SITE_CREATE_INIT, approverSiteCreate);
}

export function* approverUserCreateSaga() {
  yield takeEvery(actionType.APPROVER_USER_CREATE_INIT, approverUserCreate);
}

export function* localizationSaga() {
  yield takeEvery(actionType.LOCALIZATION_INIT, fetchLocalization);
}

export function* dashBoardCountSaga() {
  yield takeEvery(actionType.APPROVER_USER_COUNT_INIT, getCount);
}

export function* approverUserSearchSaga() {
  yield takeEvery(actionType.APPROVER_USER_SEARCH_INIT, approverUserSearch);
}
export function* approverUserEditSaga() {
  yield takeEvery(actionType.APPROVER_USER_EDIT_INIT, approverUserEdit);
}

export function* approverUserDeleteSaga() {
  yield takeEvery(actionType.APPROVER_USER_DELETE_INIT, approverUserDelete);
}

export function* reportsSaga() {
  yield takeEvery(actionType.APPROVER_REPORTS_INIT, getReports);
}
export function* broadcastRequestSaga() {
  yield takeEvery(actionType.APPROVER_BROADCAST_INIT, broadcastRequest);
}

export function* broadcastCountriesSaga() {
  yield takeEvery(
    actionType.APPROVER_BROADCAST_GET_COUNTRY_INIT,
    broadcastGetCountries
  );
}

export function* approverSiteEditSaga() {
  yield takeEvery(actionType.APPROVER_SITE_UPDATE_INIT, approverEditSite);
}

export function* userDetailsSaga() {
  yield takeEvery(actionType.GET_USER_DETAILS_INIT, userDetails);
}

export function* fuelerPassResetSaga() {
  yield takeEvery(actionType.ADMIN_FUELER_PASSWORD_RESET_INIT, fuelerPassReset);
}

export function* deleteSiteSaga() {
  yield takeEvery(actionType.DELETE_SITE_INIT, deleteSite);
}
export function* tcGetCountriesSaga() {
  yield takeEvery(actionType.TC_COUNTRIES_INIT, tcGetCountries);
}

export function* tcGetPDFSaga() {
  yield takeEvery(actionType.TC_PDF_INIT, tcGetPdf);
}

export function* tcSignUpSaga() {
  yield takeEvery(actionType.SIGNUP_INIT, signUp);
}

export function* refreshTokenSaga() {
  yield takeEvery(actionType.REFRESH_INIT, refreshToken);
}
