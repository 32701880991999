import * as actionTypes from '../actions';

const initialState = {
    payload: {}
}

export const reducerSignup = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SIGNUP_INIT:
            return {...state, payload: action.payload };

        case actionTypes.SIGNUP_SUCCESS:
            return {...state, error: action.payload };

        case actionTypes.SIGNUP_FAIL:
            return {...state, error: action.payload };

        default:
            return state;

    }

}