import { put } from "redux-saga/effects";
import * as actions from "../../../actions";
import { getCountries } from "../../API";
import * as actionTypes from "../../../actions/action_types";

const formatCountryList = (data) => {
  var formattedList = [];
  for (var key in data) {
    formattedList.push({ value: data[key], label: key });
  }
  return formattedList;
};

export function* broadcastGetCountries(action) {
  try {
    const response = yield getCountries(action.payload);
    switch (response.status) {
      case 200:
        var responseData = yield response.json();
        var formattedData = formatCountryList(
          responseData.payload.sitebycountries
        );
        yield put(actions.broadcastCountrySuccess(formattedData));
        break;
      case 401:
       
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_BROADCAST_GET_COUNTRY_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
            yield put(actions.broadcastCountryFail(true));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;

      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
        yield put(actions.broadcastCountryFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
        yield put(actions.broadcastCountryFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.broadcastSendFail(e));
  }
}
