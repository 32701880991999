import React from "react";
import { useState } from "react";
import "./terms.css";
import ShellPecten from "../../assets/shell_pection.png";
import tick from "../../assets/terms_tick.png";
import cross from "../../assets/terms_cross.png";
import PrivacyPolicyPopup from "./Privacypolicy";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { tcAccepted,resetTC } from "../../store/actions/";


const RenderPdf = (props) => {

  const [showPopup, setShowPopup] = useState(false);  // State to update privacy policy
  const { languageSet } = props.localization;
  const { pdf_link } = props.history.location.state;
  
  const handleDecline = () => {
    props.history.replace("/");
  };


  const handleTCAccept = () => {
    // props.history.replace("/", {
    //   isTermsAccepted: true,
    // })
    setShowPopup(true);
  };
  return (
    <div className="container-flex">
      <div className="main-parent">
        <div className="flex-container" style={{ width: "100%" }}>
          <div className="leftcontainer">
            <div className="ShellPecten">
              <img className="logo" src={ShellPecten} />
            </div>
            <div className="logotext">
              <label className="flight-title">{languageSet.header_title}</label>
            </div>
          </div>
        </div>
      </div>
      <iframe src={pdf_link} />
      <div className="layout-end-pdf">
        <div className="save-cancel-button-style">
          <div className="save-cancel-pos">
            <button
              type="login"
              className="btn btn-primary cancel-btn custom style-custom button-style cancel-terms  shadow"
              onClick={handleDecline}
            >
              <img className="cross-tick" src={cross} />
              {languageSet.decline}
            </button>
            <button
              type="save"
              className="btn btn-primary login-button custom-style submit-terms shadow"
              onClick={handleTCAccept}
             
            >
              {false && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              <img className="right-tick" src={tick} />
              {languageSet.accept}
            </button>
          </div>
        </div>
      </div>
      {showPopup && <PrivacyPolicyPopup />}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestTCAccept: () => dispatch(tcAccepted()),
    requestResetTC: () => dispatch(resetTC()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RenderPdf));
