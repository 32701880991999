import * as actionTypes from "../actions";

const initialState = {
  isSnackBarActivated: false,
};

export const reducerSnackBar = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SNACK_INIT:
      return {
        ...state,
        isSnackBarActivated: false,
      };

    case actionTypes.SNACK_ERROR_MESSAGE:
      return {
        ...state,
        snackMsg: action.payload,
        severity: "error",
        isSnackBarActivated: true,
      };

    case actionTypes.SNACK_SUCCESS_MESSAGE:
      return {
        ...state,
        snackMsg: action.payload,
        severity: "success",
        isSnackBarActivated: true,
      };

    case actionTypes.SNACK_WARNING_MESSAGE:
      return {
        ...state,
        snackMsg: action.payload,
        severity: "warning",
        isSnackBarActivated: true,
      };

    case actionTypes.SNACK_INFO_MESSAGE:
      return {
        ...state,
        snackMsg: action.payload,
        severity: "info",
        isSnackBarActivated: true,
      };

    default:
      return state;
  }
};
