import { put } from "redux-saga/effects";
import * as actions from "../../../actions";
import { getApproverReports } from "../../API";
import * as actionTypes from "../../../actions/action_types";

export function* getReports(action) {
  try {
    const response = yield getApproverReports(action.payload);
    const responseText = yield response.text();

    switch (response.status) {
      case 200:
        yield put(actions.getApproverReportsSuccess(responseText));
        return;

      case 400:
        yield put(actions.showErrorMessage("bad request"));
        break;

      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionTypes.APPROVER_REPORTS_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;

      case 500:
        yield put(actions.showErrorMessage("internal server error"));
        break;
      case 404:
        if (responseText) yield put(actions.showWarningMessage(responseText));
        else yield put(actions.showErrorMessage("Bad req"));
        break;

      default:
        yield put(actions.showErrorMessage("Unknown error"));

        break;
    }
    yield put(actions.getApproverReportsFail());
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
  }
}
