import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { initFuelerInitialData } from "../../store/actions";
import { withRouter } from "react-router";
import { Header, FuelerWizard } from "../../components/";
const Dashboard = (props) => {
  const { successLoad } = props.fuelerInitialData;

  useEffect(() => {}, [successLoad]);
  return (
    <>
      <div style={{ flex: 1, display: "flex" }}>
        <Header />
      </div>
      <FuelerWizard />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
 
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
