import React from "react";
import flightHighlight from "../../../assets/second_with_state.png";
import first from "../../../assets/First.png";
import statusApproved from "../../../assets/status_approved.png";
import WizardIcon from "../../../components/fueler-dashboard/wizard-icon/wizardicon";
import dashIcon from "../../../assets/Dot.png";
import tickSymbol from "../../../assets/symbol_correct.png";
import rejectingIcon from "../../../assets/rejected_icon.png";
import { connect } from "react-redux";
import fillApplication from "../../../assets/fill_application.png";
import { withRouter } from "react-router";
import "./application-status-approved.css";
import Header from "../../../components/header/header";
import {
  APPROVER_ID,
  ADMIN_ID,
  FUELER_ID,
  ICSC_ID,
} from "../../../common/constants";
import { displayAdminApplication } from "../../../store/actions";
const ApplicationStatusApproved = (props) => {
  const { languageSet } = props.localization;
  if (props.history.location.state)
    var {
      notification = {},
      approvedByApprover,
    } = props.history.location.state;
  else var { notification = {}, approvedByApprover } = props.notification;

  const { questionnaireSubmitSuccess } = props.questionnaireSubmit;
  const handleNewApplicationOnClick = () => {
    switch (localStorage.getItem("rWDKTId")) {
      case APPROVER_ID:
        break;
      case FUELER_ID:
        props.history.push("/home");
        break;
      case ADMIN_ID:
        props.requestApplicationPage();
        break;
      case ICSC_ID:
        props.history.push("/home");
        break;
      default:
        break;
    }
  };
  const onCopy = () => {
    document.getElementById("myInput").select();
    document.execCommand("copy");
  };

  return (
    <div className="outer-contain-main">
      {ADMIN_ID !== localStorage.getItem("rWDKTId") ? <Header /> : ""}
      <div className="imagecontainer">
        <WizardIcon icon={first} text={languageSet.flight_n_route} />
        <div className="dash-style">
          <img className="dash-img" src={dashIcon} />
        </div>
        <WizardIcon icon={flightHighlight} text={languageSet.flight_details} />
        <div className="dash-style">
          <img className="dash-img" src={dashIcon} />
        </div>
        <WizardIcon icon={statusApproved} text={languageSet.request_status} />
      </div>
      <div className="outer-parent">
        <div className="outer-border">
          <div className="border-box">
            <img
              src="tick-img"
              src={
                notification.application_response.toLowerCase() === "approved"
                  ? tickSymbol
                  : rejectingIcon
              }
            />
            <div>
              <label
                className="status-style"
                style={
                  notification.application_response.toLowerCase() === "approved"
                    ? { marginLeft: "0vw" }
                    : { marginLeft: "1vw" }
                }
              >
                {notification.application_response.toLowerCase() === "approved"
                  ? languageSet.approved_title
                  : languageSet.rejected_title}
              </label>
            </div>
          </div>
        </div>
        <div
          className={
            !notification.approver_name ? "gone-view" : "approver-approved"
          }
        >
          {notification.approver_name && (
            <div className="para-style">
              <label>
                {notification.application_response.toLowerCase() === "approved"
                  ? languageSet.app_approved_by
                  : languageSet.app_rejected_by}{" "}
              </label>
              <div className="approver-name">
                {"- " + notification.approver_name}
              </div>
            </div>
          )}
        </div>
        <div
          className={
            !notification.approver_name ? "gone-view" : "outer-border-box"
          }
        >
          <div className="border-box-custom">
            <div className="inside-data">
              <label className="para-style">
                {notification.approver_comment &&
                  languageSet.comment + "- " + notification.approver_comment}
              </label>
              <label className="para-style">
                {languageSet.flight_reg_number + "- " + notification.flight_no}
              </label>
              <label className="para-style">
                {languageSet.aircraft_type + "- " + notification.aircraft_type}
              </label>
            </div>
          </div>
        </div>
        <div className="outer-row-style">
          <div className="text-custom">
            <label className="audit-text">{languageSet.application_id}</label>
          </div>
          <div className="input-group input-group-addon">
            <input
              id="myInput"
              type="text"
              value={
                !notification
                  ? props.history.location.state.audit_number
                  : notification.audit_number
              }
              readOnly="true"
              className="form-control input-group-addon"
              aria-label="Text input with checkbox"
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              id="myButton"
              type="submit"
              className="btn btn-primary shadow-none copy"
              onClick={onCopy}
            >
              {languageSet.copy}
            </button>
          </div>
        </div>
        <div className="filling-new-custom">
          <img className="fill-img" src={fillApplication} />
          <a
            style={{
              textDecoration: "none",
              color: "#034C87",
              fontFamily: "Futura-Medium",
              fontSize: "15px",
              margin: "1vh",
              marginTop: "2vh",
              cursor: "pointer",
            }}
            onClick={handleNewApplicationOnClick}
          >
            {languageSet.fill_new_appliction}
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestApplicationPage: () => dispatch(displayAdminApplication()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApplicationStatusApproved));
