import * as actionTypes from "../../action_types";

export const displayUserDetails = (userDetails) => {
  return {
    payload: userDetails,
    type: actionTypes.APPROVER_ACCOUNTS_SETUP_SCREEN,
  };
};
//Airport
export const approverAccountsAirportInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_INIT,
  };
};

export const getApproverAccountsAirportSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_SUCCESS,
  };
};

export const getApproverAccountsAirportFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_FAIL,
  };
};

export const getApproverAccountsAirportReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_ACCOUNTS_AIRPORT_LIST_RESET,
  };
};
//Site
export const approverAccountsSiteInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_ACCOUNTS_SITE_LIST_INIT,
  };
};

export const getApproverAccountsSiteSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_ACCOUNTS_SITE_LIST_SUCCESS,
  };
};

export const getApproverAccountsSiteFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_ACCOUNTS_SITE_LIST_FAIL,
  };
};

export const ResetApproverAccountsSite = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_UPDATE_RESET,
  };
};
export const siteUpdateInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_UPDATE_INIT,
  };
};

export const siteUpdateSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_UPDATE_SUCCESS,
  };
};

export const siteUpdateFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_UPDATE_FAIL,
  };
};

export const siteCreateInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_CREATE_INIT,
  };
};

export const getSiteCreateSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_CREATE_SUCCESS,
  };
};

export const getSiteCreateFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_CREATE_FAIL,
  };
};

export const getSiteCreateReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_SITE_CREATE_RESET,
  };
};

export const userCreateInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_CREATE_INIT,
  };
};

export const getUserCreateSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_CREATE_SUCCESS,
  };
};

export const getUserCreateFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_CREATE_FAIL,
  };
};

export const getUserCreateReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_CREATE_RESET,
  };
};

export const userSearchInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_SEARCH_INIT,
  };
};

export const userSearchSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_SEARCH_SUCCESS,
  };
};

export const userSearchFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_SEARCH_FAIL,
  };
};

export const userSearchReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_SEARCH_RESET,
  };
};

export const approverUserEditInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_EDIT_INIT,
  };
};

export const approverUserEditSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_EDIT_SUCCESS,
  };
};

export const approverUserEditFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_EDIT_FAIL,
  };
};

export const approverUserEditReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_EDIT_RESET,
  };
};

export const approverUserDeleteInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_DELETE_INIT,
  };
};

export const approverUserDeleteSuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_DELETE_SUCCESS,
  };
};

export const approverUserDeleteFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_DELETE_FAIL,
  };
};

export const approverUserDeleteReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_USER_DELETE_RESET,
  };
};
//report success screen
export const displayRequestResultScreen = (reqReport) => {
  return {
    payload: reqReport,
    type: actionTypes.REPORTS_SUCCESS_SCREEN,
  };
};

export const actionRestValues = (payload) => {
  return {
    payload: payload,
    type: actionTypes.RESET_ALL_VALUES,
  };
};
//actionRestValues
