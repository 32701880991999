import { put } from "redux-saga/effects";
import * as actions from "../../actions";
import { getTcCountries } from "../API";

export function* tcGetCountries(action) {

  try {
    const response = yield getTcCountries(action.payload);
    switch (response.status) {
      case 200:
        var responseData = yield response.json();

        yield put(actions.countriesTcSuccess(responseData.payload.countries,action.switchTab));
        break;
      case 401:
        yield put(actions.showErrorMessage("Unauthorized access"));
       // yield put(actions.broadcastCountryFail(true));
        break;

      case 500:
        yield put(actions.showErrorMessage("Internal Server Error"));
       // yield put(actions.broadcastCountryFail(true));
        break;
      default:
        yield put(actions.showErrorMessage("unknown error"));
       // yield put(actions.broadcastCountryFail(true));
        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage(e));
   
  }
}
