import React, { useState, useEffect } from "react";
import AddHighlighted from "../../../assets/highlighted_add.png";
import MinusHighlighted from "../../../assets/highlighted_minus.png";
import "./approver-create-new-broadcast.css";
import Select from "react-select";
import SearchIcon from "../../../assets/Search_Icn.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const MultipleCountryComponent = (props) => {
  const { languageSet } = props.localization;
  const setAddViewWithPlus = () => {
    settoggleUrl(AddHighlighted);
    setIsPlusOperation(true);
  };

  const [toggleUrl, settoggleUrl] = useState(AddHighlighted);
  const [isPlusOperation, setIsPlusOperation] = useState(true);
  const [countryEmpty, setCountryIsEmpty] = useState(true);
  const { countryLength, index, countryList, setCountries } = props;
  const handleCountry = (opt) => {
    if (!opt) {
      setCountryIsEmpty(true);
    } else {
      setCountryIsEmpty(false);
    }
    let curCountry = props.countries;
    curCountry[index] = opt;
    props.setCountries(curCountry);
  };

  useEffect(() => {
    var temp = props.isCountryEmpty;
    temp[index] = countryEmpty;
    props.setIsCountryEmpty(temp);
  }, [countryEmpty]);
  useEffect(() => {
    var temp = props.isCountryEmpty;
    temp[index] = true;
    props.setIsCountryEmpty(temp);

    return () => {
      var temp = props.isCountryEmpty;
      temp[index] = false;
      props.setIsCountryEmpty(temp);
    };
  }, []);
  useEffect(() => {
    if (countryLength === 1) {
      switch (index) {
        case 0:
          settoggleUrl(AddHighlighted);
          setIsPlusOperation(true);
          break;
      }
    } else if (countryLength === 2) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          settoggleUrl(AddHighlighted);
          setIsPlusOperation(true);
          break;
      }
    } else if (countryLength === 3) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
      }
    } else if (countryLength === 4) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
      }
    } else if (countryLength === 5) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 4:
          setAddViewWithPlus();
          break;
      }
    } else if (countryLength === 6) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 4:
          setAddViewWithPlus();
          break;
        case 5:
          setAddViewWithPlus();
          break;
      }
    } else if (countryLength === 7) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 4:
          setAddViewWithPlus();
          break;
        case 5:
          setAddViewWithPlus();
          break;
        case 6:
          setAddViewWithPlus();
          break;
      }
    } else if (countryLength === 8) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 4:
          setAddViewWithPlus();
          break;
        case 5:
          setAddViewWithPlus();
          break;
        case 6:
          setAddViewWithPlus();
          break;
        case 7:
          setAddViewWithPlus();
          break;
      }
    } else if (countryLength === 9) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 4:
          setAddViewWithPlus();
          break;
        case 5:
          setAddViewWithPlus();
          break;
        case 6:
          setAddViewWithPlus();
          break;
        case 7:
          setAddViewWithPlus();
          break;
        case 8:
          setAddViewWithPlus();
          break;
      }
    } else if (countryLength === 10) {
      switch (index) {
        case 0:
          settoggleUrl(MinusHighlighted);
          setIsPlusOperation(false);
          break;
        case 1:
          setAddViewWithPlus();
          break;
        case 2:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 3:
          setAddViewWithPlus();
          break;
        case 4:
          setAddViewWithPlus();
          break;
        case 5:
          setAddViewWithPlus();
          break;
        case 6:
          setAddViewWithPlus();
          break;
        case 7:
          setAddViewWithPlus();
          break;
        case 8:
          setAddViewWithPlus();
          break;
        case 9:
          setAddViewWithPlus();
          break;
      }
    }
  }, [props.countryLength]);

  return (
    <div>
      <div className="add-country">
        <div className="row">
          <div className="col">
            <div className="md-form mt-0">
              <Select
                type="text"
                ClearIndicator="true"
                classNamePrefix={
                  "react-select__control " +
                  (countryEmpty && props.isSubmitBtnClicked
                    ? "border-red"
                    : "") +
                  " "
                }
                placeholder={languageSet.pick_one}
                isClearable="true"
                isLoading={true}
                name="countryname"
                options={countryList}
                onChange={(value) => {
                  props.setEmptyCountry(false);
                  setCountries(value, false, index);
                }}
                components={{
                  DropdownIndicator: () => {
                    return (
                      <img
                        className="icon-search-size no-outline"
                        src={SearchIcon}
                      />
                    );
                  },
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                }}
              />
            </div>
          </div>
          <div className="md-form mt-0">
            <img
              className="add_img custom-img"
              src={toggleUrl}
              onClick={
                isPlusOperation === true
                  ? props.onClickPlus
                  : props.onClickMinus
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MultipleCountryComponent));
