import * as actionTypes from "../action_types";

export const initTcCountries = (data,switchTab) => {
  return {
    payload: data,
    switchTab:switchTab,
    type: actionTypes.TC_COUNTRIES_INIT,
  };
};

export const countriesTcSuccess = (data,switchTab) => {
  return {
    payload: data,
    switchTab:switchTab,
    type: actionTypes.TC_COUNTRIES_SUCCESS,
  };
};

export const resetTC = (data) => {
  return {
    payload: data,
    type: actionTypes.TC_RESET,
  };
};

export const InitTcPdf = (data) => {
  return {
    payload: data,
    type: actionTypes.TC_PDF_INIT,
  };
};
export const tcPdfSuccess = (data) => {
  return {
    payload: data,
    type: actionTypes.TC_PDF_SUCCESS,
  };
};

export const tcPdfReset = (data) => {
  return {
    payload: data,
    type: actionTypes.TC_PDF_RESET,
  };
};

export const tcAccepted = (data) => {
  return {
    payload: data,
    type: actionTypes.TC_ACCEPTED,
  };
};

