import React, { useState, useEffect } from "react";
import "./user-account-edit-dialog.css";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import AlertsClose from "../../../../assets/alerts_close.png";
import DropdownDisabled from "../../../../assets/dropdown_disabled.png";
import { useForm } from "react-hook-form";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  APPROVER_ID,
  ADMIN_ID,
  FUELER_ID,
  ICSC_ID,
} from "../../../../common/constants";
import {
  actionAccountEditUserName,
  actionAccountEditFirstName,
  actionAccountEditLastName,
  actionAccountEditEmail,
  actionAccountEditSite,
  actionAccountEditInvalidName,
  approverUserEditInit,
  approverUserEditReset,
  actionRestValues,
} from "../../../../store/actions";
import SearchIcon from "../../../../assets/Search_Icn.png";
const UserAccountEditDialog = (props) => {
  const { modalEdit, showModalEdit, item, searchCall, userSearchString } =
    props || {};
  const { siteList, editUserSuccess, editUserError, loadingEditUser } =
    props.approverAccount || {};
  const {
    firstName,
    lastName,
    userName,
    email,
    site,
    editInvalidName,
  } = props.approverAccountEdit;
  const toggle = () => showModalEdit(!modalEdit);
  const [isAllFieldFilled, setIsAllFieldFilled] = useState(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [dropDownValue, setDropDownValue] = useState("SITE");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [optionalEmail, setOptionalEmail] = useState(false);
  const { handleSubmit, setValue } = useForm();
  const userRoleType = localStorage.getItem("rWDKTId");
  const { languageSet } = props.localization;
  const [items] = useState([
    { label: "APPROVER", value: "APPROVER" },
    { label: "ADMIN", value: "ADMIN" },
    { label: "FUELER", value: "FUELER" },
    { label: "ICSC", value: "ICSC" },
  ]);

  useEffect(() => {
    if (modalEdit) {
      props.resetAllValue();
    }
  }, [modalEdit]);

  useEffect(() => {
    if (editUserSuccess) {
      props.editInvalidName(false);
      props.approverUserEditReset();
      searchCall(userSearchString);
      showModalEdit(false);
    }

    if (editUserError === "User name already  exists") {
      props.editInvalidName(true);
    }
  }, [editUserSuccess, editUserError]);
  const onSubmit = (data) => {};
  const editUser = (roleType) => {
    if (invalidEmail) return;

    var req = {
      username: userName.toLowerCase().trim(),
      firstname: firstName,
      lastname: lastName,
      siteid: site,
      userroletype: roleType,
      useremail: email.toLowerCase().trim(),
      userid: item.userid,
    };
    props.approverUserEdit(req);
  };

  const validateEmail = () => {
    //Check for email only in case of approver and admin
    if (
      item &&
      (item.userroleid === APPROVER_ID || item.userroleid === ADMIN_ID) &&
      !email
    )
      return true;
    return false;
  };
  const isEveryFieldFilled = () => {
    if (!firstName) return true;
    if (!lastName) return true;
    if (!userName) return true;
    if (!optionalEmail && !email) return true;
    if (validateEmail()) return true;
  };
  useEffect(() => {
    if (isEveryFieldFilled()) {
      setIsAllFieldFilled(false);
    } else {
      setIsAllFieldFilled(true);
    }
    if (optionalEmail && !email) {
      setInvalidEmail(false);
    } else if (email && email.indexOf("@") === -1) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }
  });
  useEffect(() => {
    if (item) {
      switch (item.userroleid) {
        case 1:
          setDropDownValue("APPROVER");
          setOptionalEmail(false);
          break;
        case 2:
          setDropDownValue("ADMIN");
          setOptionalEmail(false);
          break;
        case 3:
          setDropDownValue("FUELER");
          setOptionalEmail(true);
          break;
        case 4:
          setDropDownValue("ICSC");
          setOptionalEmail(true);
          break;
        default:
          break;
      }
      props.editFirstName(item.firstname);
      props.editLastName(item.lastname);
      props.editEmail(item.useremail);
      props.editUserName(item.username);
      props.editSite(item.siteid);
    }
  }, [item]);
  const handleSave = () => {
    var roleType;
    switch (item.userroleid) {
      case APPROVER_ID:
        roleType = "approver";
        break;
      case ADMIN_ID:
        roleType = "admin";
        break;
      case FUELER_ID:
        roleType = "fueler";
        break;
      case ICSC_ID:
        roleType = "icsc";
        break;

      default:
        break;
    }
    if (isAllFieldFilled) {
      editUser(roleType);
    } else {
      if (optionalEmail) {
        editUser(roleType);
      }
    }
  };
  return (
    <div>
      <Modal isOpen={modalEdit} toggle={toggle} size="xl" id="modal-edit-style">
        <ModalBody id="my-body-style">
          <div className="account-edit-holder">
            <div className="edit-account-container">
              <label>Edit Account</label>
            </div>
            <div className="right-img-container">
              <img className="close-img" src={AlertsClose} onClick={toggle} />
            </div>
          </div>
          <br />
          <div className="edit-save">
            <label>Edit and save changes in the following account:</label>
          </div>
          <div className="choose-account">
            <label className="account-label">Choose Account Type</label>
            <Dropdown disabled id="dropdown">
              <DropdownToggle id="my-dropdown-style">
                {dropDownValue}
              </DropdownToggle>
              <img id="dropdown_img" src={DropdownDisabled}></img>
              <DropdownMenu id="menu">
                {items.map(({ value, index }) => (
                  <DropdownItem
                    className="special"
                    key={index}
                    value={value}
                    id="dropdown-item"
                  >
                    {value}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="parent-field-container">
            <div className="edit-holder">
              <hr className="new-edit-approver-dash"></hr>
            </div>
            <div>
              {!isAllFieldFilled && isSaveButtonClicked && (
                <p className="error_msg">
                  Please complete all mandatory fields before saving.
                </p>
              )}
            </div>
            <div className="details-edit-container">
              <form onSubmit={handleSubmit(onSubmit)}>
                {item &&
                userRoleType === APPROVER_ID &&
                (item.userroleid === ADMIN_ID ||
                  item.userroleid === FUELER_ID) && ( //Testing if Approver
                    <div className="row">
                      <div className="site-container-style edit-site">
                        <div className="md-form mt-0">
                          <Select
                            type="text"
                            ClearIndicator="true"
                            placeholder="Enter Site Name"
                            classNamePrefix="react-select__control "
                            isClearable="true"
                            isLoading={true}
                            name="sitename"
                            value={siteList.filter(
                              (item) => item.value === site
                            )}
                            options={siteList}
                            onChange={(evt) => props.editSite(evt.value)}
                            components={{
                              DropdownIndicator: () => {
                                return (
                                  <img
                                    className="icon-search-size no-outline"
                                    src={SearchIcon}
                                  />
                                );
                              },
                              IndicatorSeparator: () => null,
                              LoadingIndicator: () => null,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                <div className="row">
                  <div className="col">
                    <div className="md-form mt-0">
                      <input
                        value={item && firstName}
                        type="text"
                        className={
                          "form-control input-custom custom-style shadow-none" +
                          " " +
                          (!firstName && isSaveButtonClicked
                            ? "border-red"
                            : "")
                        }
                        name="firstname"
                        onChange={(evt) => {
                          props.editFirstName(evt.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="md-form mt-0">
                      <input
                        value={item && lastName}
                        type="text"
                        className={
                          "form-control input-custom custom-style shadow-none" +
                          " " +
                          (!lastName && isSaveButtonClicked ? "border-red" : "")
                        }
                        placeholder={languageSet.last_name}
                        name="lastname"
                        onChange={(evt) => {
                          props.editLastName(evt.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="details-edit-container custom-style">
              <form>
                <div className="row">
                  <div className="col">
                    <div className="md-form mt-0">
                      <input
                        value={item && email}
                        type="text"
                        className={
                          "form-control input-custom custom-style shadow-none" +
                          " " +
                          ((!optionalEmail && !email && isSaveButtonClicked) ||
                          (invalidEmail && isSaveButtonClicked)
                            ? "border-red"
                            : "")
                        }
                        placeholder={
                          optionalEmail ? "Optional email Id " : "Email Id"
                        }
                        name="emailid"
                        onChange={(evt) => {
                          props.editEmail(evt.target.value);
                          setInvalidEmail(false);
                        }}
                      />
                    </div>
                    {invalidEmail && isSaveButtonClicked && (
                      <p className="error_msg">
                        Please enter a valid email address.
                      </p>
                    )}
                  </div>
                  <div className="col">
                    <div className="md-form mt-0">
                      <input
                        value={item && userName}
                        type="text"
                        className="form-control input-custom custom-style shadow-none"
                        placeholder={languageSet.username}
                        name="username"
                        onChange={(evt) => {
                          props.editInvalidName(false);
                          props.editUserName(evt.target.value);
                        }}
                      />
                    </div>
                    {editInvalidName && (
                      <p className="error_msg">
                        {userName} {languageSet.unique_username}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="save-cancel-style">
            <div className="save-cancel-space">
              <div id="editaccount" onClick={toggle}>
                <a
                  style={{
                    cursor: "pointer",
                  }}
                >
                  CANCEL
                </a>
              </div>
              <button
                type="save"
                className="btn btn-primary login-button custom-style save shadow"
                onClick={() => {
                  setIsSaveButtonClicked(true);
                  handleSave();
                }}
                disabled={loadingEditUser}
              >
                {loadingEditUser && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                SAVE
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    approverAccount: state.approverAccount,
    approverAccountEdit: state.approverAccountEdit,
    localization: state.localization,
  };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    editUserName: (req) => dispatch(actionAccountEditUserName(req)),
    editFirstName: (req) => dispatch(actionAccountEditFirstName(req)),
    editLastName: (req) => dispatch(actionAccountEditLastName(req)),
    editEmail: (req) => dispatch(actionAccountEditEmail(req)),
    editSite: (req) => dispatch(actionAccountEditSite(req)),
    approverUserEdit: (req) => dispatch(approverUserEditInit(req)),
    editInvalidName: (req) => dispatch(actionAccountEditInvalidName(req)),
    approverUserEditReset: (req) => dispatch(approverUserEditReset(req)),
    resetAllValue: () => dispatch(actionRestValues()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountEditDialog);
