import React, { useEffect, useState } from "react";
import userAccounts from "../../assets/user_accounts.png";
import reportsIcon from "../../assets/reports.png";
import reportsHighlighted from "../../assets/reports_highlighted.png";
import accountsHighlighted from "../../assets/accounts_highlighted.png";
import RequestsHighlight from "../../assets/requests_highlighted.png";
import requestsIcon from "../../assets/requests.png";
import logout from "../../assets/logout.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "../../screens/approver/approver-dashboard.css";

import {
  initLogout,
  logoutReset,
  displayAdminAccounts,
  actionApproverReqTab,
  actionReportTab,
  actionAccountsTab,
  getReportsPage,
  notificationWallCountTimer,
  notificationWallStopTimer,
  displayAdminApplication,
  openNotificationPopOver,
} from "../../store/actions";
const AdminWizard = (props) => {
  const { tabState } = props.approverTabState;
  const handleApplication = () => {
    props.actionApproverReqTab();
    props.requestApplicationPage();
    props.requestOpenPopOver(false);
  };
  const handleAccountsAdmin = () => {
    props.requestSwitchAccountTab();
    props.requestAdminAccountsPage();
    props.requestOpenPopOver(false);
  };
  const handleReports = () => {
    props.requestSwitchReportTab();
    props.requestReportsPage();
    props.requestOpenPopOver(false);
  };
  const { payload, error, loading: loadingLogout } = props.logout;
  const handleLogout = () => {
    props.requestLogout();
    props.requestOpenPopOver(false);
  };
  const { languageSet } = props.localization;

  useEffect(() => {
    props.requestNotificationPullBadge();
    return () => {
      //This will execute every time the component unloads.
      props.requestNotificationPullBadgeStop();
    };
  }, []);
  useEffect(() => {
    if (payload === "success") {
      props.requestLogoutReset(); //Reset all state to initial state
      props.history.push("/");
    }
    if (error) {
      alert("Error in logout. Please try again");
    }
  }, [payload, error]);

  return (
    <div>
      <div className="button-group-style">
        <div
          className="btn-group-vertical"
          role="group"
          aria-label="Vertical button group"
        >
          <button
            type="button"
            className={
              tabState.isRequestTab
                ? "btn btn-amber custom-style tab-click shadow-none"
                : "btn btn-amber custom shadow-none"
            }
            onClick={handleApplication}
          >
            <div className="req-style">
              <img
                className="req-img-style"
                src={tabState.isRequestTab ? requestsIcon : RequestsHighlight}
              />
              {languageSet.applications.toUpperCase()}
            </div>
          </button>
          <button
            type="button"
            className={
              tabState.isReportsTab
                ? "btn btn-amber custom-style tab-click shadow-none"
                : "btn btn-amber custom shadow-none"
            }
            onClick={handleReports}
          >
            <div className="req-style">
              <img
                className="req-img-style"
                src={tabState.isReportsTab ? reportsHighlighted : reportsIcon}
              />
              {languageSet.report.toUpperCase()}
            </div>
          </button>
          <button
            type="button"
            className={
              tabState.isAccountsTab
                ? "btn btn-amber custom-style tab-click shadow-none"
                : "btn btn-amber custom shadow-none"
            }
            onClick={handleAccountsAdmin}
          >
            <div className="req-style">
              <img
                className="req-img-style"
                src={
                  tabState.isAccountsTab ? accountsHighlighted : userAccounts
                }
              />
              {languageSet.accounts.toUpperCase()}
            </div>
          </button>
        </div>
      </div>
      <div className="extra-space custom-space"></div>
      <button
        type="button"
        autoFocus
        className="btn btn-amber custom-logout logout-btn shadow-none"
        onClick={handleLogout}
        disabled={loadingLogout}
      >
        <div className="logout-image">
          <img className="logout-style" src={logout} />
          {loadingLogout && (
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {languageSet.log_out.toUpperCase()}
        </div>
      </button>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestAdminAccountsPage: (adminAccounts) =>
      dispatch(displayAdminAccounts(adminAccounts)),
    requestLogout: () => dispatch(initLogout()),
    requestLogoutReset: () => dispatch(logoutReset()),

    actionApproverReqTab: () => dispatch(actionApproverReqTab()),
    requestSwitchReportTab: () => dispatch(actionReportTab()),
    requestSwitchAccountTab: () => dispatch(actionAccountsTab()),

    requestReportsPage: (adminAccounts) =>
      dispatch(getReportsPage(adminAccounts)),

    requestNotificationPullBadge: (notificationPullBadge) =>
      dispatch(notificationWallCountTimer(notificationPullBadge)),

    requestNotificationPullBadgeStop: (notificationPullBadgeStop) =>
      dispatch(notificationWallStopTimer(notificationPullBadgeStop)),

    requestApplicationPage: (adminAccounts) =>
      dispatch(displayAdminApplication(adminAccounts)),
    requestOpenPopOver: (data) => dispatch(openNotificationPopOver(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminWizard));
