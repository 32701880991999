import React, { useState, useEffect } from "react";
import "./user-account-delete-dialog.css";
import { Modal, ModalBody } from "reactstrap";
import SiteDeleteSuccessPopup from "./site-delete-success-popup";
import { deleteSiteInit, deleteSiteReset } from "../../../../store/actions";
import { connect } from "react-redux";
import Select from "react-select";
const SiteDeleteDialog = (props) => {
  const {
    siteModalDelete,
    setSiteModalDelete,
    item,
    searchCallSite,
    siteSearchString,
  } = props;
  const toggle = () => setSiteModalDelete(!siteModalDelete);
  const [siteModalDeleteSuccess, showSiteModalDeleteSuccess] = useState(false);
  const { success, error, loading } = props.deleteSite || {};

  useEffect(() => {
    if (success) {
      searchCallSite(siteSearchString);
      toggle();
      showSiteModalDeleteSuccess(true);
      props.requestDeleteSiteReset();
    }
    if (error) {
      props.requestDeleteSiteReset();
      toggle();
    }
  }, [success, error]);

  const handleDelete = () => {
    var body = {
      siteid: item.value,
    };
    props.requestDeleteSiteInit(body);
  };
  return (
    <div>
      <Modal isOpen={siteModalDelete} toggle={toggle} id="my-delete-modal">
        <ModalBody>
          <br />
          <div className="delete-header">
            <label>Delete Site</label>
          </div>
          <div className="delete-que custom">
            <label>Are you sure you want to delete this site?</label>
          </div>
          <div className="delete-confirmation">
            <label>
              This site and all users mapped to this site will be deleted.
              <br />
              It is not a reversible process.
            </label>
          </div>
          <div id="modal-footer-custom">
            <button
              type="login"
              className="btn btn-primary cancel-btn custom style-custom yes-custom shadow"
              onClick={handleDelete}
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              YES
            </button>
            <button
              type="login"
              className="btn btn-primary delete-btn style-custom custom no-custom shadow"
              onClickCapture={toggle}
            >
              NO
            </button>
          </div>
          <br />
        </ModalBody>
      </Modal>
      <SiteDeleteSuccessPopup
        siteModalDeleteSuccess={siteModalDeleteSuccess}
        showSiteModalDeleteSuccess={showSiteModalDeleteSuccess}
        item={item}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    deleteSite: state.deleteSite,
  };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestDeleteSiteInit: (data) => dispatch(deleteSiteInit(data)),
    requestDeleteSiteReset: (data) => dispatch(deleteSiteReset(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteDeleteDialog);
