import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { getApproverDashboardCount } from "../sagas/API";
import * as actionType from "../actions/action_types";

export function* getCount(action) {
  try {
    const response = yield getApproverDashboardCount();
    switch (response.status) {
      case 200:
        const responseJson = yield response.json();
        yield put(actions.getApproverUserCountSuccess(responseJson));
        break;

      case 400:
        yield put(actions.showErrorMessage("bad request"));
        break;

      case 401:
        try {
          const responseJson = yield response.json();
          if (
            responseJson.error.detail == "Token is either invalid or expired"
          ) {
            yield put(
              actions.initRefresh(
                action.payload,
                actionType.APPROVER_USER_COUNT_INIT
              )
            );
          } else {
            yield put(actions.showErrorMessage("Unauthorized access"));
          }
        } catch (e) {
          actions.showErrorMessage(
            "error code - " + response.status + "-" + e.stringify
          );
        }

        break;

      case 500:
        yield put(actions.showErrorMessage("internal server error"));
        break;
      case 404:
        yield put(actions.showErrorMessage("Bad req"));
        break;

      default:
        yield put(actions.showErrorMessage("Unknown error"));

        break;
    }
  } catch (e) {
    yield put(actions.showErrorMessage("Unknown error"));
  }
}
