import React from "react";
import "./user-creation-successful.css";
import SymbolCorrect from "../../../assets/symbol_correct.png";
const UserCreationSuccessful = (props) => {
  return (
    <div className="user-success-container">
      <div className="user-success-inside shadow">
        <div className="created-msg-holder">
          <img className="symbol_created" src={SymbolCorrect} />
          {props.siteMessage ? (
            <label>SITE SUCCESSFULLY CREATED</label>
          ) : (
            <label>USER ACCOUNT SUCCESSFULLY CREATED</label>
          )}
          <br />
          <div id="newaccount">
            <a
              style={{
                cursor: "pointer",
                color: "#003C88",
              }}
              onClick={() => {
                props.successAction(false);
              }}
            >
              New Account
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreationSuccessful;
