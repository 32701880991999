var props;

const updateState = (siteState, userState, commonState) => {
  props.requestAccountsDataInit({
    site: siteState,
    user: userState,
    common: commonState,
  });
};

const getSiteValues = (searchValue) => {
  var temp = [];
  var result = props.approverAccount.siteList.filter((item) => {
    if (item.site_name.toLowerCase().includes(searchValue.toLowerCase())) {
      return item;
    }
    if (item.site_country.toLowerCase().includes(searchValue.toLowerCase())) {
      temp.push(item);
    }
  });
  result = temp.concat(result);
  return result;
};
export const labelStyle = {
  fontSize: "x-large",
  margin: "10%",
  color: "#7F7F7FCC",
};
export const currentDate = new Date();
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const getNth = function(d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const searchCall = (data) => {
  props.requestUserSearch(data);
};
const searchCallSite = (data) => {
  props.requestApproverAccountSite(data);
};

export const initProps = (_props) => {
  props = _props;
};

/*************set single states ***********/

export const changeNoUser = (data) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  userState = { ...userState, noUser: data };
  updateState(siteState, userState, commonState);
};

export const changeSearchResultShow = (data) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  commonState = { ...commonState, showSearchResult: data };
  updateState(siteState, userState, commonState);
};

export const setShowUserDetails = (data, item) => {
  var { siteState, userState, commonState } = props.approverAccountsData;

  userState = { ...userState, showUserDetails: data };
  commonState = { ...commonState, selectedItem: item };
  updateState(siteState, userState, commonState);
};

export const setShowSiteDetails = (data) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  siteState = { ...siteState, showSiteDetails: data };
  updateState(siteState, userState, commonState);
};

export const setShowModalEdit = (data, item) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  var selectedItem = commonState.selectedItem;
  var showUserDetails = userState.showUserDetails;

  if (item) {
    selectedItem = item;
  }

  if (!data) {
    showUserDetails = false;
  }
  commonState = { ...commonState, selectedItem: selectedItem };
  userState = {
    ...userState,
    modalEdit: data,
    showUserDetails: showUserDetails,
  };
  updateState(siteState, userState, commonState);
};

export const setShowModalDelete = (data, item) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  var selectedItem = commonState.selectedItem;
  var showUserDetails = userState.showUserDetails;
  if (item) {
    selectedItem = item;
  }
  if (!data) {
    showUserDetails = false;
  }

  commonState = { ...commonState, selectedItem: selectedItem };
  userState = {
    ...userState,
    modalDelete: data,
    showUserDetails: showUserDetails,
  };
  updateState(siteState, userState, commonState);
};

export const setSiteModalEdit = (data, item) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  var selectedItem = commonState.selectedItem;
  var showSiteDetails = siteState.showSiteDetails;
  if (item) {
    selectedItem = item;
  }
  if (!data) {
    showSiteDetails = false;
  }
  commonState = { ...commonState, selectedItem: selectedItem };
  siteState = {
    ...siteState,
    showSiteDetails: showSiteDetails,
    siteModalEdit: data,
  };
  updateState(siteState, userState, commonState);
};

export const setSiteModalDelete = (data, item) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  var showSiteDetails = siteState.showSiteDetails;
  var selectedItem = commonState.selectedItem;
  if (item) {
    selectedItem = item;
  }
  if (!data) {
    showSiteDetails = false;
  }
  commonState = { ...commonState, selectedItem: selectedItem };
  siteState = {
    ...siteState,
    showSiteDetails: showSiteDetails,
    siteModalDelete: data,
  };
  updateState(siteState, userState, commonState);
};

export const setIsSearch = (data) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  commonState = { ...commonState, isSearch: data };
  updateState(siteState, userState, commonState);
};

/********************************************* */

export const handleSiteDetails = (item) => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  siteState = { ...siteState, showSiteDetails: true };
  commonState = { ...commonState, selectedItem: item };
  updateState(siteState, userState, commonState);
};

export const handleUserSearch = () => {
  var { siteState, userState, commonState } = props.approverAccountsData;

  siteState = {
    ...siteState,
    searchResultsSite: false,
    showSite: false,
    showSiteStyle: false,
  };
  userState = { ...userState, noUser: false };
  commonState = {
    ...commonState,
    isSearch: false,
    showMessage: false,
    searchStringLength: 0,
  };

  updateState(siteState, userState, commonState);
};

export const handleSiteSearch = () => {
  var { siteState, userState, commonState } = props.approverAccountsData;
  siteState = {
    ...siteState,
    searchResultsSite: false,
    showSite: true,
    showSiteStyle: true,
  };
  userState = { ...userState, noUser: false };
  commonState = {
    ...commonState,
    isSearch: false,
    showMessage: false,
    searchStringLength: 0,
    showSearchResult: false,
  };

  updateState(siteState, userState, commonState);
};

export const handleShowSiteStyle = () => {
  var { siteState, userState, commonState } = props.approverAccountsData;

  if (!siteState.showSiteStyle) {
    siteState = { ...siteState, showSiteStyle: true };
  }

  updateState(siteState, userState, commonState);
};
export const handleShowUserStyle = () => {
  var { siteState, userState, commonState } = props.approverAccountsData;

  if (!siteState.showSiteStyle) {
    siteState = { ...siteState, showSiteStyle: false };
  }

  updateState(siteState, userState, commonState);
};

export const handleNewAccount = () => {
  props.requestUserSearchReset();
  props.requestNewAccountSetup();
};

export const searchUser = (data) => {
  var { siteState, userState, commonState } = props.approverAccountsData;

  if (data.target.value.length === 0) {
    userState = { ...userState, noUser: false };
    commonState = {
      ...commonState,
      isSearch: false,
      showMessage: false,
      showSearchResult: false,
    };
  } else if (data.target.value.length < 3) {
    userState = { ...userState };
    commonState = {
      ...commonState,
      isSearch: true,
      showMessage: true,
      showSearchResult: false,
    };
  } else {
    userState = {
      ...userState,
      noUser: false,
      userSearchString: data.target.value.toLowerCase(),
    };
    commonState = {
      ...commonState,
      showMessage: false,
    };
    searchCall(data.target.value.toLowerCase());
  }
  commonState = {
    ...commonState,
    searchStringLength: data.target.value.length,
  };

  updateState(siteState, userState, commonState);
};

export const searchSite = (data) => {
  var searchText = "";
  if (data.target) {
    searchText = data.target.value;
  } else searchText = data;

  var { siteState, userState, commonState } = props.approverAccountsData;

  if (searchText.length === 0) {
    siteState = { ...siteState, searchResultsSite: false };
    userState = { ...userState, noUser: false };
    commonState = {
      ...commonState,
      isSearch: false,
      showMessage: false,
      showSearchResult: false,
    };
  } else if (searchText.length < 3) {
    siteState = { ...siteState, searchResultsSite: false };
    commonState = {
      ...commonState,
      isSearch: true,
      showMessage: true,
      showSearchResult: false,
    };
  } else {
    siteState = {
      ...siteState,
      searchResultsSite: false,
      siteSearchString: searchText,
    };
    commonState = {
      ...commonState,
      showMessage: false,
    };
    var siteResult = getSiteValues(searchText);
    if (siteResult.length !== 0) {
      userState = { ...userState, noUser: false };
      siteState = {
        ...siteState,
        searchResultsSite: siteResult,
        siteSearchString: searchText,
      };
    } else userState = { ...userState, noUser: true };
  }
  commonState = {
    ...commonState,
    searchStringLength: searchText.length,
  };

  updateState(siteState, userState, commonState);
};
