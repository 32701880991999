import * as actionTypes from "../../../actions";

const initialState = {
  payload: "",
  loading: false,
};

export const reducerApproverBroadcast = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_BROADCAST_INIT:
      return {
        payload: action.payload,
        loading: true,
      };
    case actionTypes.APPROVER_BROADCAST_SUCCESS:
      return {
        ...state,
        broadCastSentSuccess: true,
        broadCastSentFail: false,
        loading: false,
      };
    case actionTypes.APPROVER_BROADCAST_FAIL:
      return {
        ...state,
        broadCastSentSuccess: false,
        broadCastSentFail: true,
        loading: false,
      };
    case actionTypes.APPROVER_BROADCAST_RESET:
      return {
        broadCastSentSuccess: false,
        broadCastSentFail: false,
        loading: false,
      };

    default:
      return state;
  }
};
