import * as actionTypes from "../../../actions";

const initialState = {
  loading: false,
};

export const reducerReports = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVER_REPORTS_RESET:
      return {
        ...state,
        reportSuccess: false,
        loading: false,
      };

    case actionTypes.APPROVER_REPORTS_INIT:
      return {
        ...state,

        reportSuccess: false,
        loading: true,
      };
    case actionTypes.APPROVER_REPORTS_SUCCESS:
      return {
        ...state,
        reportSuccess: true,
        payload: {},
        loading: false,
      };
    case actionTypes.APPROVER_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
        reportSuccess: false,
        loading: false,
      };
    default:
      return state;
  }
};
