import React, { useEffect } from "react";
import "./approver-broadcast-screen.css";
import AddAccount from "../../../assets/add_account.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getTimeLocal } from "../approver-requests/requests/approver-list-util";
import { getDateStandardFormat } from "../../../common/components/date-component";
import {
  initNotificationList,
  displayCreateBroadcast,
} from "../../../store/actions";
const ApproverBroadcastScreen = (props) => {
  const { notificationListSuccess, error, loading } = props.notificationList;

  const handleNewBroadcast = () => {
    props.requestNewBroadcast();
  };

  useEffect(() => {
    props.requestNotificationList({ isCount: false });
  }, []);

  return (
    <div className="parent-accounts-container">
      <div className="accounts-header">
        <div className="left-header">
          <label>BROADCAST MESSAGE</label>
        </div>
        <div className="button-container">
          <button
            type="button"
            className="btn btn-amber custom-style new-broadcast-style shadow-none"
            onClick={handleNewBroadcast}
          >
            <div className="new-acnt-style">
              <img className="broadcast-img-style" src={AddAccount} />
              Send broadcast message
            </div>
          </button>
        </div>
      </div>
      <div className="broadcast-list">
        <table className="table shadow">
          <thead>
            <tr>
              <th className="table-header-blue">Date</th>
              <th className="table-header-blue">Time</th>
              <th className="table-header-blue">Sender</th>
              <th className="table-header-blue">Message</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              notificationListSuccess.length !== 0 &&
              notificationListSuccess.map((notificationListSuccess, index) => (
                <tr key={index}>
                  <td>{getDateStandardFormat(notificationListSuccess.date)}</td>
                  <td>{getTimeLocal(notificationListSuccess.date)}</td>
                  <td>{notificationListSuccess.approver_name}</td>
                  <td>{notificationListSuccess.broadcast_message}</td>
                </tr>
              ))}
          </tbody>
        </table>

        {!loading && notificationListSuccess.length === 0 && (
          <div className="no-requests">
            <label>
              It’s a light day! :) <br />
              <br />
              No Broadcast message.
            </label>
          </div>
        )}

        {error && !loading && <label>{error}</label>}
        {loading && (
          <div
            className="spinner-border text-primary loader-position"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestNewBroadcast: () => dispatch(displayCreateBroadcast()),
    requestNotificationList: (notificationList) =>
      dispatch(initNotificationList(notificationList)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverBroadcastScreen));
