import React, { useState, useEffect } from "react";
import "./approver-reports-screen.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import dateIcon from "../../../assets/datepicker.png";
import {
  displayRequestResultScreen,
  getApproverReportsInit,
  reportsDataInit,
} from "../../../store/actions";
import {
  handleActiveStatus,
  getStatus,
  handleReqReport,
  handleReportStartDate,
  handleReportEndDate,
  initProps,
} from "./approver-reports-util";

const ApproverReportsScreen = (props) => {
  initProps(props);

  var { reportDetails, statusReport } = props.reportsData;

  const { reportSuccess, loading } = props.approverReports;
  const { languageSet } = props.localization;
  useEffect(() => {
    if (reportSuccess) {
      props.reqReportResult();
    }
  }, [reportSuccess]);
  const DatePickerCustomInput = ({ value, onClick }) => (
    <div className={"date-range-style"}>
      <button
        className="form-control input-custom date-style  shadow-none"
        onClick={onClick}
      >
        {value}
      </button>

      <div className="input-group-text custom-date-style">
        <input
          className="icon-eye-size  date-size no-outline"
          type="image"
          src={dateIcon}
          onClick={onClick}
        />
      </div>
    </div>
  ); 
  return (
    <div className="parent-reports-container">
      <div className="reports-header">
        <label>{languageSet.report.toUpperCase()}</label>
      </div>
      <div className="outer-report-container">
        <div className="report-inner-container">
          <label>{languageSet.fill_report}</label>
          <div className="report-container">
            <div className="inner-report-style">
              <label>{languageSet.date_range.toUpperCase()}</label>
              <div className="date-range-style">
                <DatePicker
                  placeholder="Start Date"
                  dateFormat="MMMM d, yyyy"
                  selected={reportDetails.startdate}
                  popperPlacement="top-start"
                  onChange={(date) => handleReportStartDate(date)}
                  customInput={<DatePickerCustomInput />}
                />

                <div style={{ marginLeft: "3%" }}></div>
                <DatePicker
                  placeholder="End Date"
                  dateFormat="MMMM d, yyyy"
                  selected={reportDetails.enddate}
                  onChange={(date) => handleReportEndDate(date)}
                  customInput={<DatePickerCustomInput />}
                />
              </div>
              <div className="reports-status">
                <label>{languageSet.status_report.toUpperCase()}</label>
                <div className="report-radio-style">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="defaultInline35"
                      name="addcomment"
                      value="commentforapprover"
                      className="custom-control-input radio-style"
                      onChange={() => handleActiveStatus(getStatus.selectAll)}
                      checked={statusReport.isAll}
                    />
                    <label
                      className="custom-control-label label-custom"
                      for="defaultInline35"
                    >
                      {languageSet.all_lower.toUpperCase()}
                    </label>
                  </div>

                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="defaultInline36"
                      name="addcomment"
                      value="commentforapprover"
                      className="custom-control-input"
                      onChange={() =>
                        handleActiveStatus(getStatus.selectApproved)
                      }
                      checked={statusReport.isApproved}
                    />
                    <label
                      className="custom-control-label label-custom"
                      for="defaultInline36"
                    >
                      {languageSet.approved_lowercase.toUpperCase()}
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="defaultInline37"
                      name="addcomment"
                      value="commentforapprover"
                      className="custom-control-input"
                      onChange={() =>
                        handleActiveStatus(getStatus.selectRejected)
                      }
                      checked={statusReport.isRejected}
                    />
                    <label
                      className="custom-control-label label-custom"
                      for="defaultInline37"
                    >
                      {languageSet.rejected_lowercase.toUpperCase()}
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="defaultInline38"
                      name="addcomment"
                      value="commentforapprover"
                      className="custom-control-input"
                      onChange={() =>
                        handleActiveStatus(getStatus.selectPending)
                      }
                      checked={statusReport.isPending}
                    />
                    <label
                      className="custom-control-label label-custom"
                      for="defaultInline38"
                    >
                      {languageSet.pending_title.toUpperCase()}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="request-report-style">
            <button
              type="reqreport"
              className="btn btn-primary login-button custom-style req-report shadow"
              onClick={() => handleReqReport()}
            >
              {loading && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {languageSet.report_button_label}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    approverReports: state.approverReports,
    reportsData: state.reportsData,
    localization: state.localization,
  };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    reqReportResult: () => dispatch(displayRequestResultScreen()),
    reportsRequest: (data) => dispatch(getApproverReportsInit(data)),
    requestReportsData: (data) => dispatch(reportsDataInit(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverReportsScreen));
