import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const CargoQuestions = props => {
  const { register, handleSubmit } = props;
  const { natureofcargo = false } = props.natureOfFlightJson.cargo || {};
  const [extnJson, setExtJson] = useState({});
  const [militaryCargoForUse, showMilitaryCargoForUse] = useState(false);
  const { languageSet } = props.localization;
  const handleIsLisenseCheck = value => {
    var tempJson = extnJson;
    tempJson.islicensetocarrygoods = value;
    setExtJson(tempJson);
  };

  const handleIsCheckIsMilitary = value => {
    var tempJson = extnJson;
    tempJson.natureofcargo = value;
    setExtJson(tempJson);
    if (value === "military") {
      showMilitaryCargoForUse(true);
    } else {
      var removeCargoUseinEndDestination = extnJson;
      delete removeCargoUseinEndDestination["cargotouseinenddestination"];
      setExtJson(removeCargoUseinEndDestination);
      showMilitaryCargoForUse(false);
    }
  };
  const handleCheckIsCargoEndDest = value => {
    var tempJson = extnJson;
    tempJson.cargotouseinenddestination = value;
    setExtJson(tempJson);
  };

  const handleCheckIsGEC = value => {
    extnJson.isorginatefromgec = value;
    props.natureOfFlightJson.cargo = extnJson;
    props.setNatureOfFlightJson(props.natureOfFlightJson);
  };

  return (
    <div>
      <div className="radio-que">
        <label>{languageSet.gec}</label>
      </div>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          className="custom-control-input"
          value="YES"
          ref={register({
            required: true
          })}
          id="defaultInline14"
          name="ispassengersanctioned"
          ref={register}
          onChange={() => handleCheckIsGEC(true)}
        />
        <label className="custom-control-label" for="defaultInline14">
        {languageSet.yes}
        </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          className="custom-control-input"
          id="defaultInline15"
          value="NO"
          ref={register({
            required: true
          })}
          name="ispassengersanctioned"
          onChange={() => handleCheckIsGEC(false)}
        />
        <label className="custom-control-label" for="defaultInline15">
         {languageSet.no}
        </label>
      </div>

      <div className="radio-que">
        <label>{languageSet.licence_goods}</label>
      </div>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          className="custom-control-input"
          value="YES"
          ref={register({
            required:
              props.showNatureOfFlight && props.natureOfFlightJson.isCargo
                ? true
                : false
          })}
          id="defaultInline16"
          name="islicensetocarrygoods"
          onChange={() => handleIsLisenseCheck(true)}
          ref={register({
            required:
              props.showNatureOfFlight && props.natureOfFlightJson.isCargo
                ? true
                : false
          })}
        />
        <label className="custom-control-label" for="defaultInline16">
          {languageSet.yes}
        </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          className="custom-control-input"
          id="defaultInline17"
          value="NO"
          ref={register({
            required:
              props.showNatureOfFlight && props.natureOfFlightJson.isCargo
                ? true
                : false
          })}
          name="islicensetocarrygoods"
          onChange={() => handleIsLisenseCheck(false)}
        />
        <label className="custom-control-label" for="defaultInline17">
        {languageSet.no}
        </label>
      </div>
      <div className="radio-que">
        <label>{languageSet.nature_of_cargo}</label>
      </div>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="defaultInline9"
          name="natureofcargo"
          value="MILITARY"
          ref={register({
            required:
              props.showNatureOfFlight && props.natureOfFlightJson.isCargo
                ? true
                : false
          })}
          className="custom-control-input"
          onChange={() => handleIsCheckIsMilitary("military")}
        />
        <label className="custom-control-label" for="defaultInline9">
        {languageSet.military}
        </label>
      </div>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="defaultInline11"
          value="COMMERCIAL"
          ref={register({
            required:
              props.showNatureOfFlight && props.natureOfFlightJson.isCargo
                ? true
                : false
          })}
          name="natureofcargo"
          className="custom-control-input"
          onChange={() => handleIsCheckIsMilitary("commercial")}
        />
        <label className="custom-control-label" for="defaultInline11">
         {languageSet.aircraft_type_commercial}
        </label>
      </div>
      <div
        className={militaryCargoForUse === true ? "outer-radio" : "gone-view"}
      >
        <div className="radio-que">
          <label>{languageSet.use_in_end_destination}</label>
        </div>
        <div className="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            className="custom-control-input"
            value="YES"
            ref={register({
              required:
                props.showNatureOfFlight &&
                props.natureOfFlightJson.isCargo &&
                natureofcargo === "military"
                  ? true
                  : false
            })}
            id="defaultInline12"
            name="cargotouseinenddestination"
            ref={register}
            onChange={() => handleCheckIsCargoEndDest(true)}
          />
          <label className="custom-control-label" for="defaultInline12">
           {languageSet.yes}
          </label>
        </div>
        <div className="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            className="custom-control-input"
            id="defaultInline13"
            value="NO"
            ref={register({
              required:
                props.showNatureOfFlight &&
                props.natureOfFlightJson.isCargo &&
                natureofcargo === "military"
                  ? true
                  : false
            })}
            name="cargotouseinenddestination"
            onChange={() => handleCheckIsCargoEndDest(false)}
          />
          <label className="custom-control-label" for="defaultInline13">
           {languageSet.no}
          </label>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return state;
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CargoQuestions));
