import * as actionTypes from "../../../actions";

const initialState = {
  siteState: {
    showSite: false,
    searchResultsSite: false,
    showSiteStyle: false,
    showSiteDetails: false,
    siteModalEdit: false,
    siteModalDelete: false,
  },
  userState: {
    noUser: false,
    showUserDetails: false,
    userSearchString: "",
    modalEdit: false,
    modalDelete: false,
    adminResetPassword: false,
  },
  commonState: {
    showSearchResult: false,
    isSearch: false,
    showMessage: false,
    searchStringLength: false,
    selectedItem: false,
  },
};

export const reducerAccountsData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIONS_DATA_INIT:
      return {
        siteState: {
          ...state.siteState,
          showSite: action.payload.site.showSite,
          searchResultsSite: action.payload.site.searchResultsSite,
          showSiteStyle: action.payload.site.showSiteStyle,
          showSiteDetails: action.payload.site.showSiteDetails,
          siteModalEdit: action.payload.site.siteModalEdit,
          siteModalDelete: action.payload.site.siteModalDelete,
          siteSearchString: action.payload.site.siteSearchString,
        },
        userState: {
          ...state.userState,
          noUser: action.payload.user.noUser,
          modalEdit: action.payload.user.modalEdit,
          modalDelete: action.payload.user.modalDelete,
          showUserDetails: action.payload.user.showUserDetails,
          userSearchString: action.payload.user.userSearchString,
          adminResetPassword: action.payload.user.adminResetPassword,
        },
        commonState: {
          ...state.commonState,
          isSearch: action.payload.common.isSearch,
          showMessage: action.payload.common.showMessage,
          searchStringLength: action.payload.common.searchStringLength,
          selectedItem: action.payload.common.selectedItem,
          showSearchResult: action.payload.common.showSearchResult,
        },
      };

    case actionTypes.ACTIONS_DATA_RESET:
      return {
        siteState: initialState.siteState,
        userState: initialState.userState,
        commonState: initialState.commonState,
      };

    default:
      return state;
  }
};
