import * as actionTypes from "../../actions";

const initialState = {
  tcLoading: false,
  tcCountries: [],
  displayCountries: false,
  switchTab: "",
};

export const reducerTermsConditions = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TC_COUNTRIES_INIT:
      return {
        ...state,
        tcLoading: true,
      };
    case actionTypes.TC_COUNTRIES_SUCCESS: 
    return {
        ...state,
        switchTab: action.switchTab,
        tcCountries: action.payload,
        displayCountries: true,
        tcLoading: false,
      };
    case actionTypes.TC_RESET:
      return {
        ...state,
        tcCountries: [],
        displayCountries: false,
        tcLoading: false
      };

    default:
      return state;
  }
};
