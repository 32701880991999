import * as actionTypes from "./action_types";

export const initLogout = data => {
  return {
    payload: data,
    type: actionTypes.LOGOUT_INIT
  };
};

export const logoutSuccess = data => {
  return {
    payload: data,
    type: actionTypes.LOGOUT_SUCCESS
  };
};

export const logoutFail = data => {
  return {
    payload: data,
    type: actionTypes.LOGOUT_FAIL
  };
};

export const logoutReset = data => {
  return {
    payload: data,
    type: actionTypes.LOGOUT_RESET
  };
};
