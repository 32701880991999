import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./approver-create-new-broadcast.css";
import BackIcon from "../../../assets/back_icon.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getBroadcastPage, showSuccessMessage } from "../../../store/actions";
import DropdownArrow from "../../../assets/dropdown-arrow.png";
import DropdownUp from "../../../assets/dropdown_up.png";
import Select from "react-select";
import SearchIcon from "../../../assets/Search_Icn.png";
import { ListVirtualization } from "../../../common/components/list-virtualization.js";
import MultipleCountryComponent from "./multiple-countries-component";
import BroadcastSuccessScreen from "./broadcast-success-screen";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  handleActiveStatus,
  getLocation,
  initProps,
  setSite,
  setCountries,
  handleSend,
} from "./approver-broadcast-util";
import {
  broadcastDataInit,
  approverAccountsSiteInit,
  broadcastCountryInit,
  broadcastSendInit,
  broadcastSendReset,
  broadcastDataReset,
} from "../../../store/actions";
const ApproverCreateNewBroadcast = (props) => {
  const { languageSet } = props.localization;
  initProps(props);
  const [dropDownValue, setDropDownValue] = useState("ALL");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showApprover, setShowApprover] = useState(false);
  const [message, setMessage] = useState();
  const [countryCounts, setCountryCounts] = useState([1]);
  const [showInfoLocationWise, setShowInfoLocationWise] = useState(0);
  const [country, setCountry] = useState([]);
  const [successScreen, setSuccessScreen] = useState(false);
  const [emptyCountry, setEmptyCountry] = useState(false);
  const [emptyBroadcastMessage, setEmptyBroadcastMessage] = useState(false);
  const [emptySiteValue, setEmptySiteValue] = useState(false);
  const [isCountryEmpty, setIsCountryEmpty] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const items = [
    { label: "ALL USERS", value: "ALL" },
    { label: "APPROVERS", value: "APPROVER" },
    { label: "ADMIN", value: "ADMIN" },
    { label: "FUELERS/ICSC", value: "FUELER/ICSC" },
  ];
  const globalView = "1";
  const CountryView = "2";
  const siteView = "3";
  var { statusBroadcast } = props.broadcastData;
  const { siteList } = props.siteList || {};
  const { countryList } = props.broadcastCountries || {};
  const { broadCastSentSuccess, broadCastSentFail, loading } =
    props.approverBroadcast || {};

  const onSubmit = (data) => {};
  useEffect(() => {
    handleActiveStatus(getLocation.selectGlobal);
    props.requestApproverAccountsSiteInit();
    props.requestBroadcastCountryInit();
  }, []);
  //sending broadcast
  useEffect(() => {
    if (broadCastSentSuccess) {
      setSuccessScreen(true);
      props.requestBroadcastSendReset();
    }
    if (broadCastSentFail) props.requestBroadcastSendReset();
  }, [broadCastSentSuccess, broadCastSentFail]);

  const handleSwitchView = (value) => {
    switch (value) {
      case globalView:
        setShowInfoLocationWise(value);
        break;
      case CountryView:
        setShowInfoLocationWise(value);
        break;
      case siteView:
        setShowInfoLocationWise(value);
        break;
    }
  };
  const toggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSelect = (event) => {
    setDropDownValue(event.target.innerText);
    setIsDropdownOpen(!isDropdownOpen);

    if (event.target.innerText === "APPROVER") {
      setShowApprover(true);
    } else {
      setShowApprover(false);
    }
  };
  const handleBackBroadcast = () => {
    props.requestBroadcastPage();
  };

  const handleMinus = (count) => {
    const _destCounts = countryCounts.filter((m) => m !== count);
    setCountryCounts(_destCounts);
    let temp = country.slice(0, -1);
    setCountry(temp);
    setCountries(null, true, null);
  };
  const handlePlus = (count, index) => {
    const countryCount = [...countryCounts];
    countryCount.push(count);
    if (countryCount.length <= 10) {
      setCountryCounts(countryCount);
      let temp = country;
      temp.push(null);
      setCountry(temp);
    }
  };
  const { handleSubmit, setValue } = useForm();
  const handleSendValidation = () => {
    if (!message) {
      setEmptyBroadcastMessage(true);
    } else {
      setEmptyBroadcastMessage(false);
    }
    if (statusBroadcast.isSite && statusBroadcast.site.length === 0) {
      setEmptySiteValue(true);
    } else setEmptySiteValue(false);
  };

  const isEmptyField = () => {
    if (!message) return true;
    if (
      !showApprover &&
      statusBroadcast.isSite &&
      statusBroadcast.site.length === 0
    ) {
      return true;
    }

    if (
      !showApprover &&
      statusBroadcast.isCountry &&
      (countryCounts.length !== statusBroadcast.indexList.length ||
        statusBroadcast.indexList.length === 0)
    ) {
      setEmptyCountry(true);
      return true;
    }
    if (statusBroadcast.duplicateCountry) return true;
    return false;
  };

  const sendBroadcast = () => {
    if (!isEmptyField())
      handleSend(dropDownValue, countryList, message, showApprover);
  };

  const resetOnNew = () => {
    var { broadcastDetails, statusBroadcast } = props.broadcastData;
    setMessage();
    setEmptySiteValue(false);
    setEmptyBroadcastMessage(false);
    setEmptyCountry(false);
    statusBroadcast = {
      ...statusBroadcast,
      countryList: [],
      indexList: [],
      duplicateCountry: false,
      site: [],
    };
    props.requestBroadcastData({
      ...broadcastDetails,
      activeStatus: statusBroadcast,
    });
  };
  return (
    <div className="parent-user-details-container">
      <div className="new-account-header">
        <div className="left-header">
          <label>BROADCAST MESSAGE</label>
          <label style={{ paddingLeft: "1vw" }}> > </label>
          <div id="newaccount">
            <a
              style={{
                cursor: "pointer",
              }}
            >
              Send broadcast message
            </a>
          </div>
        </div>
      </div>
      <div className="user-details-container custom-broadcast shadow">
        <div className="new-broadcast-holder">
          <img
            className="back-img custom-img"
            src={BackIcon}
            onClick={handleBackBroadcast}
          />
          <label>
            Fill in required fields to send out broadcast message across app
            users :
          </label>
        </div>

        {successScreen ? (
          <BroadcastSuccessScreen
            setSuccessScreen={setSuccessScreen}
            resetOnNew={resetOnNew}
          />
        ) : (
          <div>
            <div className="account-type-container">
              <label>Select users: </label>
              <Dropdown isOpen={isDropdownOpen} toggle={toggle} id="dropdown">
                <DropdownToggle id="dropdown-style">
                  {dropDownValue}
                </DropdownToggle>
                <img
                  id="dropdown_img"
                  src={!isDropdownOpen ? DropdownArrow : DropdownUp}
                  onClick={toggle}
                ></img>
                <DropdownMenu id="menu">
                  {items.map(({ value, index }) => (
                    <DropdownItem
                      onClick={handleSelect}
                      className="special"
                      key={index}
                      value={value}
                      id="dropdown-item"
                    >
                      {value}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            {!showApprover && (
              <div className="reports-status custom-broadcast">
                <label>Select location: </label>
                <div className="report-radio-style">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="defaultInline40"
                      name="global"
                      value="globalValue"
                      className="custom-control-input radio-style"
                      onChange={() =>
                        handleActiveStatus(getLocation.selectGlobal)
                      }
                      onChangeCapture={() => {
                        handleSwitchView(globalView);
                      }}
                      checked={statusBroadcast.isGlobal}
                    />
                    <label
                      className="custom-control-label label-custom"
                      for="defaultInline40"
                    >
                      GLOBAL
                    </label>
                  </div>

                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="defaultInline41"
                      name="global"
                      value="globalValue"
                      className="custom-control-input"
                      onChange={() =>
                        handleActiveStatus(getLocation.selectCountry)
                      }
                      onChangeCapture={() => {
                        handleSwitchView(CountryView);
                      }}
                      checked={statusBroadcast.isCountry}
                    />
                    <label
                      className="custom-control-label label-custom"
                      for="defaultInline41"
                    >
                      COUNTRY
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="defaultInline42"
                      name="global"
                      value="globalValue"
                      className="custom-control-input"
                      onChange={() =>
                        handleActiveStatus(getLocation.selectSite)
                      }
                      onChangeCapture={() => {
                        handleSwitchView(siteView);
                      }}
                      checked={statusBroadcast.isSite}
                    />
                    <label
                      className="custom-control-label label-custom"
                      for="defaultInline42"
                    >
                      SITE
                    </label>
                  </div>
                </div>
              </div>
            )}
            {showInfoLocationWise === CountryView && !showApprover && (
              <div className="add-country">
                <label>Add countries (Max limit 10 countries) :</label>
              </div>
            )}
            {showInfoLocationWise === CountryView &&
              !showApprover &&
              countryCounts.map((countryCount, index) => (
                <MultipleCountryComponent
                  index={index}
                  countryLength={countryCounts.length}
                  key={index}
                  onClickPlus={() =>
                    handlePlus(countryCounts.length + 1, index)
                  }
                  onClickMinus={() => handleMinus(countryCounts.length)}
                  country={country}
                  setCountry={setCountry}
                  isCountryEmpty={isCountryEmpty}
                  setIsCountryEmpty={setIsCountryEmpty}
                  setCountries={setCountries}
                  countryList={countryList}
                  setEmptyCountry={setEmptyCountry}
                />
              ))}
            {statusBroadcast.duplicateCountry && (
              <div className="country_error">
                <p className="error_msg ">
                  Country already selected. Please select a different country.
                </p>
              </div>
            )}
            {!statusBroadcast.duplicateCountry && emptyCountry && (
              <div className="country_error">
                <p className="error_msg ">Please select Country.</p>
              </div>
            )}

            {showInfoLocationWise === siteView && !showApprover && (
              <div className="add-country customs-style">
                <label>Add site :</label>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col">
                      <div className="md-form mt-0">
                        <Select
                          type="text"
                          ClearIndicator="true"
                          classNamePrefix={
                            "react-select__control " +
                            (emptySiteValue ? "border-red" : "") +
                            " "
                          }
                          placeholder={languageSet.pick_one}
                          isClearable="true"
                          isLoading={true}
                          options={siteList}
                          onChange={(item) => {
                            setEmptySiteValue(false);
                            setSite(item);
                          }}
                          components={{
                            DropdownIndicator: () => {
                              return (
                                <img
                                  className="icon-search-size no-outline"
                                  src={SearchIcon}
                                />
                              );
                            },
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null,
                            MenuList: ListVirtualization,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {emptySiteValue && (
                    <p className="error_msg">Please select site.</p>
                  )}
                </form>
              </div>
            )}
            <div>
              <hr className="new-edit-approver-dash custom-broadcast"></hr>
              <div className="create-broadcast-msg">
                <label>Broadcast message</label>
                <div className="input-style-custom custom-style broadcast-style">
                  <div className="input-group input-group-addon">
                    <input
                      placeholder="Type your message here | 1500 characters limit."
                      type="text"
                      name="message"
                      className={
                        "form-control input-group-addon broadcast broadcast-custom" +
                        " " +
                        (emptyBroadcastMessage ? "border-red" : "")
                      }
                      aria-label="Text input with checkbox"
                      onChange={(evt) => {
                        setMessage(evt.target.value);
                        setEmptyBroadcastMessage(false);
                      }}
                    />
                  </div>
                </div>
                {emptyBroadcastMessage && (
                  <p className="error_msg">Please enter a broadcast message.</p>
                )}
              </div>
            </div>
            <div className="request-broadcast-style custom-broadcast">
              <button
                type="reqreport"
                className="btn btn-primary login-button custom-broadcast shadow"
                onClick={sendBroadcast}
                onClickCapture={handleSendValidation}
                disabled={loading}
              >
                {loading && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                SEND
              </button>
            </div>
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    broadcastData: state.broadcastData,
    siteList: state.approverAccount,
    broadcastCountries: state.broadcastCountries,
    approverBroadcast: state.approverBroadcast,
    localization: state.localization,
  };
};

// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestBroadcastPage: (approverBroadcast) =>
      dispatch(getBroadcastPage(approverBroadcast)),
    requestBroadcastData: (data) => dispatch(broadcastDataInit(data)),
    requestApproverAccountsSiteInit: (data) =>
      dispatch(approverAccountsSiteInit(data)),
    requestBroadcastDataReset: (data) => dispatch(broadcastDataReset()),
    requestBroadcastCountryInit: (data) => {
      dispatch(broadcastCountryInit());
    },
    requestBroadcastSendInit: (data) => dispatch(broadcastSendInit(data)),
    requestBroadcastSendReset: () => {
      dispatch(broadcastSendReset());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApproverCreateNewBroadcast));
