import React from "react";
import { Switch, Route } from "react-router-dom";

import Dashboard from "./screens/dashboard/";
import Approved from "./screens/fueler/application-status-approved/application-status-approved";
import PendingRejected from "./screens/fueler/application-status-pending-rejected/application-status-pending-rejected";
import Approver from "./screens/approver/approver-dashboard";
import App from "./screens/App";
import Admin from "./screens/admin/admin-dashboard";
import AuthenticatedRoute from "./authenticator";
import TermsAndConditions from "./screens/terms-condition/terms";

function Navigation() {
  return (
    
    <Switch>
      <Route exact path="/" component={App}></Route>
      <AuthenticatedRoute exact path="/home/" component={Dashboard} />
      <AuthenticatedRoute exact path="/home/approved" component={Approved} />
      <AuthenticatedRoute exact path="/admin/approved" component={Approved} />
      <AuthenticatedRoute
        exact
        path="/home/pendingRejected"
        component={PendingRejected}
      />
      <AuthenticatedRoute
        exact
        path="/admin/pendingRejected"
        component={PendingRejected}
      />
      <AuthenticatedRoute exact path="/approver" component={Approver} />
      <AuthenticatedRoute exact path="/admin" component={Admin} />
      <Route exact path="/termsAndConditions" component={TermsAndConditions} />
    </Switch>
  );
}

export default Navigation;
