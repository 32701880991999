import React from "react";
import "../../approver-dashboard/approver-accounts-user-details/user-creation-successful.css";
import SymbolCorrect from "../../../assets/symbol_correct.png";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { displayCreateBroadcast } from "../../../store/actions";
const BroadcastSuccessScreen = (props) => {
  const handleSuccessBroadcastScreen = () => {
    props.resetOnNew();
    props.setSuccessScreen(false);
  };
  return (
    <div className="user-success-container">
      <div className="user-success-inside custom-broadcast shadow">
        <div className="created-msg-holder custom-broadcast">
          <img className="symbol_created" src={SymbolCorrect} />
          <label>BROADCAST MESSAGE SUCCESSFULLY SENT</label>
          <br />
          <div id="newaccount">
            <a
              style={{
                cursor: "pointer",
                color: "#003C88",
              }}
              onClick={handleSuccessBroadcastScreen}
            >
              New Broadcast Message
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return state;
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestNewBroadcast: () => dispatch(displayCreateBroadcast()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BroadcastSuccessScreen));
