import * as actionTypes from "./action_types";

export const resetQuestionnaireValidation = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_VALIDATION_RESET
  };
};

export const initQuestionnaireValidations = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_VALIDATON_INIT
  };
};

export const questionnaireValidationsSuccess = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_VALIDATON_SUCCESS
  };
};

export const questionnaireValidationsFail = data => {
  return {
    payload: data,
    type: actionTypes.QUSESTIONNAIRE_VALIDATON_FAILURE
  };
};
