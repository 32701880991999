import * as actionTypes from "../../action_types";

//Send broadcast
export const broadcastCountryInit = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_GET_COUNTRY_INIT,
  };
};

export const broadcastCountrySuccess = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_GET_COUNTRY_SUCCESS,
  };
};

export const broadcastCountryFail = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_GET_COUNTRY_FAIL,
  };
};

export const broadcastCountryReset = (payload) => {
  return {
    payload: payload,
    type: actionTypes.APPROVER_BROADCAST_GET_COUNTRY_RESET,
  };
};
