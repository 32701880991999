import React, { useState, useEffect } from "react";
import "./user-account-edit-dialog.css";
import { Modal, ModalBody, Dropdown, DropdownToggle } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import AlertsClose from "../../../../assets/alerts_close.png";
import DropdownDisabled from "../../../../assets/dropdown_disabled.png";
import SiteEditSuccessPopup from "./site-edit-success-popup";
import { useForm } from "react-hook-form";
import SearchIcon from "../../../../assets/Search_Icn.png";
import { ListVirtualization } from "../../../../common/components/list-virtualization";
import { SITE_NAME_CONFLICT_MSG } from "../../../../common/constants";
import {
  approverAccountsAirportInit,
  ResetApproverAccountsSite,
  siteUpdateInit,
} from "../../../../store/actions";
const SiteEditDialog = (props) => {
  const { item } = props || {};

  const { airportList, countryList } = props.approverAccount || {};
  const { updateSiteSuccess, updateSiteError } = props.approverSiteUpdateData;
  const { siteModalEdit, setSiteModalEdit, siteSearchString, searchCallSite } =
    props || {};
  const [siteModalEditSuccess, setSiteModalEditSuccess] = useState(false);
  const toggle = () => setSiteModalEdit(!siteModalEdit);
  const [isAllFieldFilled, setIsAllFieldFilled] = useState(false);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const { handleSubmit, setValue } = useForm();
  const [countryValue, setCountryValue] = useState();
  const [airportValue, setAirportValue] = useState();
  const [siteName, setSiteName] = useState("");
  const [itpOperateCompany, setItpOperateCompany] = useState("");
  const [invalidSiteName, setInvalidSiteName] = useState(false);

  useEffect(() => {
    if (!airportList) {
      props.requestAccountsAirport();
    }
  }, []);

  useEffect(() => {
    if (updateSiteSuccess) {
      searchCallSite(siteSearchString);
      setSiteModalEditSuccess(true);
      props.requestSiteEditReset();
      toggle();
    }
  }, [updateSiteSuccess]);

  useEffect(() => {
    if (updateSiteError === SITE_NAME_CONFLICT_MSG) {
      setInvalidSiteName(true);
      props.requestSiteEditReset();
    }
  }, [updateSiteError]);

  useEffect(() => {
    if (item) {
      setCountryValue(item.site_country);
      setAirportValue(item.site_airport);
      setSiteName(item.site_name);
      setItpOperateCompany(item.itp_operating_company);
    }
  }, [item]);

  const onSubmit = (data) => {};
  const isEveryFieldFilled = () => {
    if (!countryValue) return true;
    if (!airportValue) return true;
    if (!siteName) return true;
    if (!itpOperateCompany) return true;
  };

  const handleSave = () => {
    setIsSaveButtonClicked(true);

    if (isAllFieldFilled) {
      var reqBody = {
        siteid: item.value,
        site_name: siteName.toLowerCase(),
        site_country: countryValue,
        itp_operating_company: itpOperateCompany,
        site_airport: airportValue,
      };
      props.requestSiteUpdateInit(reqBody);
    }
  };

  useEffect(() => {
    if (isEveryFieldFilled()) {
      setIsAllFieldFilled(false);
    } else {
      setIsAllFieldFilled(true);
    }
  });
  const handleCountry = (selectedOption) => {
    if (selectedOption) {
      setValue("countryname", selectedOption.value);
      setCountryValue(selectedOption.value);
    }
  };
  const handleAirport = (selectedOption) => {
    if (selectedOption) {
      setValue("airportvalue", selectedOption.value);
      setAirportValue(selectedOption.value);
    }
  };

  return (
    <div>
      <Modal
        isOpen={siteModalEdit}
        toggle={toggle}
        size="xl"
        id="modal-edit-style"
      >
        <ModalBody id="my-body-style">
          <div className="account-edit-holder">
            <div className="edit-account-container">
              <label>Edit Site Account</label>
            </div>
            <div className="right-img-container">
              <img className="close-img" src={AlertsClose} onClick={toggle} />
            </div>
          </div>
          <br />
          <div className="edit-save">
            <label>Edit and save changes in the following site:</label>
          </div>
          <div className="choose-account">
            <label className="account-label">Choose Account Type</label>
            <Dropdown disabled id="dropdown">
              <DropdownToggle id="my-dropdown-style">SITE</DropdownToggle>
              <img id="dropdown_img" src={DropdownDisabled}></img>
            </Dropdown>
          </div>
          <div className="parent-field-container">
            <div className="edit-holder">
              <hr className="new-edit-approver-dash"></hr>
            </div>
            <div>
              {!isAllFieldFilled && isSaveButtonClicked && (
                <p className="error_msg">
                  Please complete all mandatory fields before saving.
                </p>
              )}
            </div>
            <div className="details-edit-container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col">
                    <div className="md-form mt-0">
                      <Select
                        type="text"
                        ClearIndicator="true"
                        classNamePrefix={
                          "react-select__control " +
                          (!countryValue && isSaveButtonClicked
                            ? "border-red"
                            : "") +
                          " "
                        }
                        placeholder="Enter Country Name"
                        isClearable="true"
                        isLoading={true}
                        value={
                          countryList &&
                          item &&
                          item.site_airport &&
                          countryList.filter((i) => i.value === countryValue)
                        }
                        options={countryList}
                        onChange={setCountryValue}
                        name="countryname"
                        components={{
                          DropdownIndicator: () => {
                            return (
                              <img
                                className="icon-search-size no-outline"
                                src={SearchIcon}
                              />
                            );
                          },
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null,
                          MenuList: ListVirtualization,
                        }}
                        onChange={handleCountry}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="md-form mt-0">
                      <Select
                        type="text"
                        ClearIndicator="true"
                        classNamePrefix={
                          "react-select__control " +
                          (!airportValue && isSaveButtonClicked
                            ? "border-red"
                            : "") +
                          " "
                        }
                        placeholder="Search Airport"
                        isClearable="true"
                        isLoading={true}
                        value={
                          airportList &&
                          item &&
                          item.site_airport &&
                          airportList.filter((i) => i.value === airportValue)
                        }
                        options={airportList}
                        name="airportname"
                        onChange={setAirportValue}
                        components={{
                          DropdownIndicator: () => {
                            return (
                              <img
                                className="icon-search-size no-outline"
                                src={SearchIcon}
                              />
                            );
                          },
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null,
                          MenuList: ListVirtualization,
                        }}
                        onChange={handleAirport}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="details-edit-container custom-style">
              <form>
                <div className="row">
                  <div className="col">
                    <div className="md-form mt-0">
                      <input
                        type="text"
                        className={
                          "form-control input-custom custom-style shadow-none" +
                          " " +
                          ((!siteName && isSaveButtonClicked) ||
                          (invalidSiteName && isSaveButtonClicked)
                            ? "border-red"
                            : "")
                        }
                        placeholder="Site Name"
                        value={item && siteName}
                        name="sitename"
                        onChange={(evt) => {
                          setSiteName(evt.target.value);
                          setInvalidSiteName(false);
                        }}
                      />
                      {invalidSiteName && isSaveButtonClicked && (
                        <p className="error_msg">Site name should be unique.</p>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="md-form mt-0">
                      <input
                        type="text"
                        className={
                          "form-control input-custom custom-style shadow-none" +
                          " " +
                          (!itpOperateCompany && isSaveButtonClicked
                            ? "border-red"
                            : "")
                        }
                        placeholder="ITP Operating Company"
                        value={item && itpOperateCompany}
                        name="itpoperatingcompany"
                        maxLength={30}
                        onChange={(evt) => {
                          setItpOperateCompany(evt.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="save-cancel-style">
              <div className="save-cancel-space">
                <div id="editaccount" onClick={toggle}>
                  <a
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    CANCEL
                  </a>
                </div>
                <button
                  type="save"
                  className="btn btn-primary login-button custom-style save shadow"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <SiteEditSuccessPopup
        siteModalEditSuccess={siteModalEditSuccess}
        setSiteModalEditSuccess={setSiteModalEditSuccess}
        item={item}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    approverAccount: state.approverAccount,
    approverAccountEdit: state.approverAccountEdit,
    approverSiteUpdateData: state.approverSiteUpdateData,
  };
};
// This triggers an action
const mapDispatchToProps = (dispatch) => {
  return {
    requestAccountsAirport: (req) => dispatch(approverAccountsAirportInit(req)),

    requestSiteUpdateInit: (req) => dispatch(siteUpdateInit(req)),

    requestSiteEditReset: () => dispatch(ResetApproverAccountsSite()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteEditDialog);
